import { Check, Newspaper } from "@mui/icons-material";
import { Option, TaskItemProps } from "./types";
import React from "react";
import "./select-task.scss";

function TaskItem({ selected, title, id, onClick }: TaskItemProps) {
  return (
    <div
      className={`task-item ${selected && "selected"}`}
      onClick={() => {
        onClick && onClick(id);
      }}
    >
      {selected && <Check id="check-icon" />}
      <Newspaper
        sx={{ fontSize: "64px" }}
        htmlColor={selected ? "#ffffff" : "#365BDC"}
      />
      <span>{title}</span>
    </div>
  );
}

interface SelectTaskProps {
  tasks: Option[];
  onChange?: (selecteds: number[]) => void;
}

export function SelectTask({ tasks, onChange }: SelectTaskProps) {
  const [selected, setSelected] = React.useState<number[]>([]);
  function onClick(id: number) {
    let selectedCopy = selected;
    const index = selectedCopy.findIndex((item) => item === id);

    if (index === -1) {
      selectedCopy = [id];
    } else {
      selectedCopy = [];
    }
    const newData = [...selectedCopy];
    setSelected(newData);
    onChange && onChange(newData);
  }
  return (
    <div className="select-task-list">
      {tasks.map(({ name, id }) => (
        <TaskItem
          id={id}
          title={name}
          selected={selected.includes(id)}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
