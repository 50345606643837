import { Knowledge } from "services/admin/Interfaces/Types";
import { FETCH_KNOWLEDGES } from "../Actions";
import KnowledgeService from "./knowledge.service";

export const fetchKnowledge = (
  size: number,
  offset: number,
  knowledge: number | undefined
) => {
  return (dispatch: any) => {
    KnowledgeService.fetchKnowledge(size, offset, knowledge).then(
      (response) => {
        dispatch(FETCH_KNOWLEDGES(response.data));
      }
    );
  };
};

export const fetchAllKnowledge = () => {
  return KnowledgeService.fetchKnowledge(1000, 0, undefined);
};

export const removeKnowledge = (knowledge: Knowledge) => {
  return (dispatch: any) => {
    KnowledgeService.removeKnowledge(knowledge).then((response) => {
      dispatch(fetchKnowledge(5, 0, undefined));
    });
  };
};
