import { configureStore } from "@reduxjs/toolkit";

import authentication from "./reducers/Authentication";
import admin from "./reducers/Admin";
import usuarioAplicacaoProva from "./reducers/UsuarioAplicacaoProva";
import abilities from "./reducers/abilities";
import UsuarioTranscricaoItem from "./reducers/UsuarioTranscricaoItem";
import knowledges from "./reducers/knowledges";
import Ability_Edge from "./reducers/Ability_Edge";
import AbilitySerie from "./reducers/Serie_Ability";

export default configureStore({
  reducer: {
    authentication: authentication,
    admin: admin,
    teacherAssessment: usuarioAplicacaoProva,
    abilities: abilities,
    transcriberItem: UsuarioTranscricaoItem,
    knowledges: knowledges,
    abilityEdge: Ability_Edge,
    abilitySeries: AbilitySerie,
  },
});
