import { createReducer } from "@reduxjs/toolkit";
import { IRegisterProps } from "../Authentication/AsyncActions/types";
import {
  bindUserAssessment,
  fetchUserAssessment,
  getUsers,
  removeUserAssessment,
  resetBinds,
  resetUsers,
  setSearchData,
  updateUserAssessment,
} from "./Actions";
import { TeacherItemAssignment } from "./AsyncActions/types";

const empty_bind: TeacherItemAssignment = {
  id: 0,
  assessment: {
    code: "",
    description: "",
    ends_at: "",
    exam_time: 0,
    exams: [],
    starts_at: "",
    title: "",
  },
  exam: { tasks: [], code: "", description: "", published: false },
  created_at: undefined,
  item: 0,
  teacher: 0,
  updated_at: undefined,
};

export interface IsearchData {
  idExam: number;
  idTask: number;
  idItem: number;
  idTeache: number;
  idAssessment: number;
}

export interface IStateTeacherCorretion {
  teachers: IRegisterProps[];
  teacherItemAssesments: TeacherItemAssignment[];
  dataForm: TeacherItemAssignment;
  filters: IsearchData;
}

const INITIAL_STATE: IStateTeacherCorretion = {
  teachers: [],
  teacherItemAssesments: [],
  dataForm: empty_bind,
  filters: { idExam: 0, idItem: 0, idTask: 0, idTeache: 0, idAssessment: 0 },
};

export default createReducer(INITIAL_STATE, {
  [setSearchData.type]: (state, action) => {
    return { ...state, filters: action.payload };
  },
  [resetUsers.type]: (state) => {
    return { ...state, teachers: [] };
  },
  [getUsers.type]: (state, action) => {
    let usuarios: IRegisterProps[] = [...state.teachers].concat(action.payload);
    return { ...state, teachers: usuarios };
  },
  [resetBinds.type]: (state) => {
    return { ...state, teacherItemAssesments: [] };
  },
  [fetchUserAssessment.type]: (state, action) => {
    let binds = [...state.teacherItemAssesments].concat(action.payload);
    return { ...state, teacherItemAssesments: binds };
  },
  [bindUserAssessment.type]: (state, action) => {
    let listUserAssessments: TeacherItemAssignment[] = [
      ...state.teacherItemAssesments,
    ];
    listUserAssessments.push(action.payload);
    return { ...state, teacherItemAssesments: listUserAssessments };
  },
  [removeUserAssessment.type]: (state, action) => {
    let listUserAssessments: TeacherItemAssignment[] = [
      ...state.teacherItemAssesments,
    ];
    listUserAssessments = listUserAssessments.filter(
      (a: TeacherItemAssignment) => {
        return a.id !== action.payload;
      }
    );
    return { ...state, teacherItemAssesments: listUserAssessments };
  },
  [updateUserAssessment.type]: (state, action) => {
    let listUserAssessments: TeacherItemAssignment[] = [
      ...state.teacherItemAssesments,
    ];

    const index: number = listUserAssessments.indexOf(
      listUserAssessments.find(
        (a: TeacherItemAssignment) => a.id === action.payload.id
      ) as TeacherItemAssignment
    );

    listUserAssessments[index] = action.payload;
    return { ...state, teacherItemAssesments: listUserAssessments };
  },
});
