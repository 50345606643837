import { MenuItemProps } from "@mui/material";
import { Menu, Option, SelectOptionsProps } from "./types";

export enum Ids {
  EXAM = "exam-title",
  KNOWLEDGE_AREA = "knowledge-area",
  KNOWLEDGE = "knowledge",
  ABILITY = "ability",
}

export const selectFilters: SelectOptionsProps[] = [
  {
    id: Ids.EXAM,
    label: "Título do Exame",
    options: [],
    placeholder: "Pesquisar pelo Título...",
  },
  {
    id: Ids.KNOWLEDGE_AREA,
    label: "Área de Conhecimento",
    options: [],
    placeholder: "Pesquisar por Área de Saber...",
  },
  {
    id: Ids.KNOWLEDGE,
    label: "Conhecimento",
    options: [],
    placeholder: "Pesquisar por Conhecimento...",
  },
  {
    id: Ids.ABILITY,
    label: "Habilidades",
    options: [],
    placeholder: "Pesquisar por Habilidade...",
  },
];

export function getSelectConfig(key: Ids){
  return selectFilters.find((filter)=>filter.id === key)!
}