interface IProps {
  evaluations: {
    total: number;
    total_percentage: number;
    rubrics: {
      id: number;
      name: string;
      percentage: number;
      total: number;
    }[];
  };

  answers_stats?: {
    source_student: number;
    source_transcriber: number;
    source_transcriber_ocr: number;
    total: number;
  };

  item_stats?: {
    answers_from_student: number;
    answers_from_transcriber: number;
    answers_from_transcriber_ocr: number[];
    total_answers: number;
  };
}

export function quantityAndPercentage({
  evaluations,
  answers_stats = undefined,
  item_stats = undefined,
}: IProps) {
  let estatisticasDeRespostas = {
    respostasEstudantes: 0,
    porcentagemRespostasEstudantes: 0,
    respostasTranscritor: 0,
    porcentagemRespostasTranscritor: 0,
    respostasOCR: 0,
    porcentagemRespostasOCR: 0,
    respostasNaoContabilizadas: 0,
    porcentagemRespostasNaoContabilizadas: 0,
  };

  let estatisticasDoItem = {
    respostasEstudantesItem: 0,
    porcentagemRespostasEstudantesItem: 0,
    respostasTranscritorItem: 0,
    porcentagemRespostasTranscritorItem: 0,
    respostasOCRItem: 0,
    porcentagemRespostasOCRItem: 0,
    respostasNaoContabilizadasItem: 0,
    porcentagemRespostasNaoContabilizadasItem: 0,
  };

  // First Column
  const porcentagemInsuficiente = evaluations.rubrics[0].percentage;
  const porcentagemParcialmenteInsuficiente = evaluations.rubrics[1].percentage;
  const porcentagemSuficiente = evaluations.rubrics[2].percentage;
  const porcentagemSemRubrica =
    100 -
    (porcentagemInsuficiente +
      porcentagemParcialmenteInsuficiente +
      porcentagemSuficiente);

  if (answers_stats) {
    const respostasEstudantes = answers_stats.source_student ?? 0;
    const porcentagemRespostasEstudantes =
      (respostasEstudantes * 100) / answers_stats.total;

    const respostasTranscritor = answers_stats.source_transcriber ?? 0;
    const porcentagemRespostasTranscritor =
      (respostasTranscritor * 100) / answers_stats.total;

    const respostasOCR = answers_stats.source_transcriber_ocr ?? 0;
    const porcentagemRespostasOCR = (respostasOCR * 100) / answers_stats.total;

    const respostasNaoContabilizadas =
      answers_stats.total -
        (respostasEstudantes + respostasTranscritor + respostasOCR) ?? 0;
    const porcentagemRespostasNaoContabilizadas =
      (respostasNaoContabilizadas * 100) / answers_stats.total ?? 0;

    estatisticasDeRespostas = {
      respostasEstudantes,
      porcentagemRespostasEstudantes,
      respostasTranscritor,
      porcentagemRespostasTranscritor,
      respostasOCR,
      porcentagemRespostasOCR,
      respostasNaoContabilizadas,
      porcentagemRespostasNaoContabilizadas,
    };
  }

  if (item_stats) {
    const respostasEstudantesItem = item_stats.answers_from_student;
    const porcentagemRespostasEstudantesItem =
      (respostasEstudantesItem * 100) / item_stats.total_answers;

    const respostasTranscritorItem = item_stats.answers_from_transcriber;
    const porcentagemRespostasTranscritorItem =
      (respostasTranscritorItem * 100) / item_stats.total_answers;

    const respostasOCRItem = item_stats.answers_from_transcriber_ocr[0];
    const porcentagemRespostasOCRItem =
      (respostasOCRItem * 100) / item_stats.total_answers;

    const respostasNaoContabilizadasItem =
      item_stats.total_answers -
      (respostasEstudantesItem + respostasTranscritorItem + respostasOCRItem);
    const porcentagemRespostasNaoContabilizadasItem =
      (respostasNaoContabilizadasItem * 100) / item_stats.total_answers;

    estatisticasDoItem = {
      respostasEstudantesItem,
      porcentagemRespostasEstudantesItem,
      respostasTranscritorItem,
      porcentagemRespostasTranscritorItem,
      respostasOCRItem,
      porcentagemRespostasOCRItem,
      respostasNaoContabilizadasItem,
      porcentagemRespostasNaoContabilizadasItem,
    };
  }

  return {
    porcentagemInsuficiente,
    porcentagemParcialmenteInsuficiente,
    porcentagemSuficiente,
    porcentagemSemRubrica,

    ...estatisticasDeRespostas,

    ...estatisticasDoItem,
  };
}
