import { Knowledge } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class KnowledgeService {
  public static async createKnowledge(knowledge: Knowledge) {
    return api.post("/matrices/knowledges/", knowledge);
  }

  public static async updateKnowledge(knowledge: Knowledge) {
    return api.put(`/matrices/knowledges/${knowledge.id}/`, knowledge);
  }

  public static async removeKnowledge(knowledge: Knowledge) {
    return api.delete(`/matrices/knowledges/${knowledge.id}`);
  }

  public static async fetchKnowledge(
    limit: number,
    offset: number,
    knowledge: number | undefined
  ) {
    let URL = `/matrices/knowledges?limit=${limit}&offset=${offset}`;
    if (knowledge) {
      URL = URL + `&knowledge=${knowledge}`;
    }
    return api.get(URL);
  }
}
