import api from "services/api";
import { IRegisterTeacherItemAssignment } from "./types";

export class ServiceBindUserItemAssessment {
  public static async createBindUserAssesments(
    data: IRegisterTeacherItemAssignment
  ) {
    return api.post("/assessments/teacheritemassignments/", data);
  }

  public static async deleteBindUserAssesments(id: number) {
    return api.delete("/assessments/teacheritemassignments/" + id);
  }

  public static async getUserAssesments() {
    return api.get("/assessments/teacheritemassignments/");
  }

  public static async getUserAssesmentsByParams(
    idTeacher: number,
    idAssessment: number,
    idExam: number
  ) {
    return api.get(
      "/assessments/teacheritemassignments/?teacher=" +
        idTeacher +
        (idAssessment ? "&assessment=" + idAssessment : "") +
        (idExam ? "&exam=" + idExam : "")
    );
  }
}
