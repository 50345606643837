import { AbilityEdge } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class AbilityEdgeService {
  public static async registerAbilitEdge(abe: {
    from_ability: number;
    to_ability: number;
  }) {
    return api.post("/matrices/abilities_edge/", abe);
  }

  public static async updateAbilitEdge(abe: AbilityEdge) {
    return api.post(`/matrices/abilities_edge/${abe.id as number}`, abe);
  }

  public static async removeAbilitEdge(id: number) {
    return api.delete(`/matrices/abilities_edge/${id}`);
  }

  public static async fetchAbilitEdge(
    params:
      | {
          limit: number;
          offset: number;
        }
      | undefined
  ) {
    return api.get("/matrices/abilities_edge/", { params });
  }
}
