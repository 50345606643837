import { KnowledgeArea } from "services/admin/Interfaces/Types";
import { getKnowledgesArea } from "../../Actions/KnowledgeArea/action";
import KnowledegesServices from "./knowledges.services";

export const findKnowledges = () => {
  return (dispatch: any) => {
    KnowledegesServices.getAllKnowledges(1000, 0).then(({ data }: any) => {
      const knowledge: KnowledgeArea[] = data;
      dispatch(getKnowledgesArea(knowledge));
    });
  };
};
