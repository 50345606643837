import { ArrowDropUp } from "@mui/icons-material";
import { Loading } from "components/Loading";
import { MessageEmpty } from "components/MessageEmpty";
import { useState } from "react";
import api from "services/api";

import { quantityAndPercentage } from "../utils/quantityAndPercentage";
import { LineGraph } from "./LineGraph";

export interface IClass {
  id: number;
  name: string;
  answers_stats: {
    source_student: number;
    source_transcriber: number;
    source_transcriber_ocr: number;
    total: number;
  };
  evaluations: {
    total: number;
    total_percentage: number;
    rubrics: {
      id: number;
      name: string;
      percentage: number;
      total: number;
    }[];
  };
}

interface IItem extends Omit<IClass, "name"> {
  title: string;
  number: number;
}

interface TItemTurma {
  idAvaliacao: string;
  itemClass: IClass;
}

export function ItemTurma({ idAvaliacao, itemClass }: TItemTurma) {
  const {
    porcentagemInsuficiente: porcentagemInsuficienteTurma,
    porcentagemParcialmenteInsuficiente:
      porcentagemParcialmenteInsuficienteTurma,
    porcentagemSuficiente: porcentagemSuficienteTurma,
    porcentagemSemRubrica: porcentagemSemRubricaTurma,

    respostasEstudantes: respostasEstudantesTurma,
    porcentagemRespostasEstudantes: porcentagemRespostasEstudantesTurma,

    respostasTranscritor: respostasTranscritorTurma,
    porcentagemRespostasTranscritor: porcentagemRespostasTranscritorTurma,

    respostasOCR: respostasOCRTurma,
    porcentagemRespostasOCR: porcentagemRespostasOCRTurma,

    respostasNaoContabilizadas: respostasNaoContabilizadasTurma,
    porcentagemRespostasNaoContabilizadas:
      porcentagemRespostasNaoContabilizadasTurma,
  } = quantityAndPercentage({
    answers_stats: itemClass.answers_stats,
    evaluations: itemClass.evaluations,
  });

  const [wasClicked, setWasClicked] = useState(false);

  const [isLoadingItems, setIsLoadingItems] = useState(false);

  const [items, setItems] = useState([]);

  async function getItems() {
    if (wasClicked) return;

    setIsLoadingItems(true);

    await api
      .get(
        `assessments/assessments/${idAvaliacao}/report/evaluations_class/?class_id=${itemClass.id}`
      )
      .then(({ data }) => {
        setItems(data);
        setWasClicked(true);
      })
      .catch(console.log)
      .finally(() => setIsLoadingItems(false));
  }

  return (
    <details key={itemClass.id} style={{ paddingLeft: 20, marginBottom: 20 }}>
      <summary className="first_level" onClick={getItems}>
        <span className="identify_row">
          <ArrowDropUp htmlColor="#00A0EA" fontSize="large" />
          {itemClass.name}
        </span>

        <LineGraph
          titlePrimary={String(itemClass?.evaluations?.total)}
          answersTotal={itemClass?.answers_stats?.total}
          itemsLeft={[
            {
              legend: `"Insuficiente": ${porcentagemInsuficienteTurma.toFixed(
                2
              )}%`,
              percentageSlice: `${porcentagemInsuficienteTurma}%`,
              backgroundColor: "#f1530f",
            },
            {
              legend: `"Parcialmente Insuficiente": ${porcentagemParcialmenteInsuficienteTurma.toFixed(
                2
              )}%`,
              percentageSlice: `${porcentagemParcialmenteInsuficienteTurma}%`,
              backgroundColor: "#f1c40f",
            },
            {
              legend: `"Suficiente": ${porcentagemSuficienteTurma.toFixed(2)}%`,
              percentageSlice: `${porcentagemSuficienteTurma}%`,
              backgroundColor: "#00e676",
            },
            {
              legend: `Sem Rubrica: ${porcentagemSemRubricaTurma.toFixed(2)}%`,
              percentageSlice: `${porcentagemSemRubricaTurma}%`,
              backgroundColor: "#333",
            },
          ]}
          itemsRigth={[
            {
              legend: `Estudantes: ${respostasEstudantesTurma}`,
              percentageSlice: `${porcentagemRespostasEstudantesTurma}%`,
              backgroundColor: "#47b9ee",
            },
            {
              legend: `Transcritores: ${respostasTranscritorTurma}`,
              percentageSlice: `${porcentagemRespostasTranscritorTurma}%`,
              backgroundColor: "#00a0ea",
            },
            {
              legend: `OCR: ${respostasOCRTurma}`,
              percentageSlice: `${porcentagemRespostasOCRTurma}%`,
              backgroundColor: "#0072b9",
            },
            {
              legend: `Não Contabilizado: ${respostasNaoContabilizadasTurma}`,
              percentageSlice: `${porcentagemRespostasNaoContabilizadasTurma}%`,
              backgroundColor: "#333",
            },
          ]}
        />
      </summary>

      {/* Third Level */}
      {isLoadingItems ? (
        <Loading />
      ) : (
        <>
          {items.length <= 0 ? (
            <MessageEmpty />
          ) : (
            <>
              {items.map((item: IItem) => {
                const {
                  porcentagemInsuficiente: porcentagemInsuficienteItem,
                  porcentagemParcialmenteInsuficiente:
                    porcentagemParcialmenteInsuficienteItem,
                  porcentagemSuficiente: porcentagemSuficienteItem,
                  porcentagemSemRubrica: porcentagemSemRubricaItem,

                  respostasEstudantes: respostasEstudantesItem,
                  porcentagemRespostasEstudantes:
                    porcentagemRespostasEstudantesItem,

                  respostasTranscritor: respostasTranscritorItem,
                  porcentagemRespostasTranscritor:
                    porcentagemRespostasTranscritorItem,

                  respostasOCR: respostasOCRItem,
                  porcentagemRespostasOCR: porcentagemRespostasOCRItem,

                  respostasNaoContabilizadas: respostasNaoContabilizadasItem,
                  porcentagemRespostasNaoContabilizadas:
                    porcentagemRespostasNaoContabilizadasItem,
                } = quantityAndPercentage({
                  answers_stats: item.answers_stats,
                  evaluations: item.evaluations,
                });

                return (
                  <details style={{ paddingLeft: 20, marginBottom: 20 }}>
                    <summary className="first_level">
                      <LineGraph
                        titlePrimary={item.title}
                        answersTotal={item.answers_stats.total}
                        itemsLeft={[
                          {
                            legend: `"Insuficiente": ${porcentagemInsuficienteItem.toFixed(
                              2
                            )}%`,
                            percentageSlice: `${porcentagemInsuficienteItem}%`,
                            backgroundColor: "#f1530f",
                          },
                          {
                            legend: `"Parcialmente Insuficiente": ${porcentagemParcialmenteInsuficienteItem.toFixed(
                              2
                            )}%`,
                            percentageSlice: `${porcentagemParcialmenteInsuficienteItem}%`,
                            backgroundColor: "#f1c40f",
                          },
                          {
                            legend: `"Suficiente": ${porcentagemSuficienteItem.toFixed(
                              2
                            )}%`,
                            percentageSlice: `${porcentagemSuficienteItem}%`,
                            backgroundColor: "#00e676",
                          },
                          {
                            legend: `Sem Rubrica: ${porcentagemSemRubricaItem.toFixed(
                              2
                            )}%`,
                            percentageSlice: `${porcentagemSemRubricaItem}%`,
                            backgroundColor: "#333",
                          },
                        ]}
                        itemsRigth={[
                          {
                            legend: `Estudantes: ${respostasEstudantesItem}`,
                            percentageSlice: `${porcentagemRespostasEstudantesItem}%`,
                            backgroundColor: "#47b9ee",
                          },
                          {
                            legend: `Transcritores: ${respostasTranscritorItem}`,
                            percentageSlice: `${porcentagemRespostasTranscritorItem}%`,
                            backgroundColor: "#00a0ea",
                          },
                          {
                            legend: `OCR: ${respostasOCRItem}`,
                            percentageSlice: `${porcentagemRespostasOCRItem}%`,
                            backgroundColor: "#0072b9",
                          },
                          {
                            legend: `Não Contabilizado: ${respostasNaoContabilizadasItem}`,
                            percentageSlice: `${porcentagemRespostasNaoContabilizadasItem}%`,
                            backgroundColor: "#333",
                          },
                        ]}
                      />
                    </summary>
                  </details>
                );
              })}
            </>
          )}
        </>
      )}
    </details>
  );
}
