import { MenuItemProps } from "@mui/material";
import { cloneDeep } from "lodash";
import React from "react";
import KnowledegeAreasService from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges-area.services";
import KnowledegesServices from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges.services";
import AbilitiesService from "store/reducers/abilities/AsyncActions/abilities.service";
import { getSelectConfig, Ids } from "./constants";
import { SelectOptionsProps } from "./types";

export function useSelects() {
  const [knowledgeArea, setKnowledgeArea] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.KNOWLEDGE_AREA)
  );

  const [knowledge, setKnowledge] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.KNOWLEDGE)
  );

  const [ability, setAbility] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.ABILITY)
  );

  React.useEffect(() => {
    loadKnowledgeArea();
    loadKnowledge();
    loadAbilities();
  }, []);

  function loadKnowledgeArea() {
    KnowledegeAreasService.getAllKnowledgeArea().then((data) => {
      const config = cloneDeep(getSelectConfig(Ids.KNOWLEDGE_AREA))
      config.options = data.results.map<MenuItemProps>((knowledgeArea) => ({
        key: knowledgeArea.id,
        value: knowledgeArea.id,
        content: knowledgeArea.name,
      }));

      setKnowledgeArea(config);
    });
  }

  function loadKnowledge() {
    KnowledegesServices.getAll().then(({ data }) => {
      const config = cloneDeep(getSelectConfig(Ids.KNOWLEDGE));
      config.options = data.results.map<MenuItemProps>((knowledge) => ({
        key: knowledge.id,
        value: knowledge.id,
        content: knowledge.name,
      }));

      setKnowledge(config);
    });
  }

  function loadAbilities() {
    AbilitiesService.getAll().then(({ data }) => {
      const config = cloneDeep(getSelectConfig(Ids.ABILITY));
      config.options = data.results.map<MenuItemProps>((ability) => ({
        key: ability.id,
        value: ability.id,
        content: ability.name,
      }));

      setAbility(config);
    });
  }

  return { knowledgeArea, knowledge, ability };
}
