import { CircularProgress } from "@mui/material";

export function Loading() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        backgroundColor: "var(--neutral-2)",
        padding: 8,
        borderRadius: 4,
      }}
    >
      <h6>Por favor, aguarde...</h6>
      <CircularProgress />
    </div>
  );
}
