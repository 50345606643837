import { useEffect, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';

import './styles.css'

interface IGraficoAnaliseAlunos {
    qtd_concludentes: number;
    qtd_fazendo: number;
    qtd_pendentes: number;
}

export function GraficoAnaliseAlunos({ qtd_concludentes = 0, qtd_fazendo = 0, qtd_pendentes = 0 }: IGraficoAnaliseAlunos) {
    const [grafico, setGrafico] = useState({
        total: 0,
        porcentagem_concludentes: 0,
        porcentagem_fazendo: 0,
        porcentagem_pendentes: 0,
    })
    
    useEffect(() => {
        const total = qtd_concludentes + qtd_fazendo + qtd_pendentes;

        const porcentagem_concludentes = 100 * qtd_concludentes / total;
        const porcentagem_fazendo = 100 * qtd_fazendo / total;
        const porcentagem_pendentes = 100 * qtd_pendentes / total;

        setGrafico({
            total,
            porcentagem_concludentes,
            porcentagem_fazendo,
            porcentagem_pendentes
        })

    }, [qtd_concludentes, qtd_fazendo, qtd_pendentes])
    
    return (
        <div className="grafico_analise_alunos">
            <span>Análise das Provas</span>
            
            <div className="grafico_conteudo">
                {
                    qtd_concludentes > 0 || qtd_fazendo > 0 || qtd_pendentes > 0 ?
                    <>
                        <Tooltip title={<h5>Concludentes: {qtd_concludentes}</h5>} placement="top" arrow>
                            <span style={{ backgroundColor: 'var(--primary-dark)', width: `${grafico.porcentagem_concludentes}%` }}/>
                        </Tooltip>

                        <Tooltip title={<h5>Fazendo: {qtd_fazendo}</h5>} placement="top" arrow>
                            <span style={{ backgroundColor: 'var(--info-regular)', width: `${grafico.porcentagem_fazendo}%` }}/>
                        </Tooltip>

                        <Tooltip title={<h5>Pendentes: {qtd_pendentes}</h5>} placement="top" arrow>
                            <span style={{ backgroundColor: 'var(--neutral-7)', width: `${grafico.porcentagem_pendentes}%` }}/>
                        </Tooltip>
                    </>
                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <h6>Nenhum filtro aplicado</h6>
                    </div>
                }
            </div>

            <div className="grafico_rodape">
                <div className="grafico_legendas">
                    <div>
                        <span className="marcador concludentes"/> Concludentes: {qtd_concludentes}
                    </div>

                    <div>
                        <span className="marcador fazendo"/> Fazendo: {qtd_fazendo}
                    </div>

                    <div>
                        <span className="marcador pendentes"/> Pendentes: {qtd_pendentes}
                    </div>
                </div>

                <span>Total de Respostas: {grafico.total}</span>
            </div>
        </div>
    )
}