import { createReducer } from "@reduxjs/toolkit";
import { IRegisterProps } from "../Authentication/AsyncActions/types";
import {
  bindUserTranscribers,
  fetchUserTranscriber,
  getUsersTranscribers,
  removeUserTranscribers,
  resetBindsTranscribers,
  resetUsersTranscribers,
  setSearchDataTranscriber,
  updateTranscriber,
} from "./Actions";
import { TranscriberItemAssignment } from "./AsyncActions/types";

const empty_bind: TranscriberItemAssignment = {
  id: 0,
  assessment: {
    code: "",
    description: "",
    ends_at: "",
    exam_time: 0,
    exams: [],
    starts_at: "",
    title: "",
  },
  exam: { tasks: [], code: "", description: "", published: false },
  created_at: undefined,
  item: 0,
  transcriber: 0,
  updated_at: undefined,
};

export interface IsearchDataTranscriber {
  idExam: number;
  idTask: number;
  idItem: number;
  idUser: number;
  idAssessment: number;
}

export interface IStateUserTranscriber {
  transcribers: IRegisterProps[];
  userTranscriberAssessment: TranscriberItemAssignment[];
  dataForm: TranscriberItemAssignment;
  filters: IsearchDataTranscriber;
}

const INITIAL_STATE: IStateUserTranscriber = {
  transcribers: [],
  userTranscriberAssessment: [],
  dataForm: empty_bind,
  filters: { idExam: 0, idItem: 0, idTask: 0, idUser: 0, idAssessment: 0 },
};

export default createReducer(INITIAL_STATE, {
  [setSearchDataTranscriber.type]: (state, action) => {
    return { ...state, filters: action.payload };
  },
  [resetUsersTranscribers.type]: (state) => {
    return { ...state, transcriber: [] };
  },
  [getUsersTranscribers.type]: (state, action) => {
    let usuarios: IRegisterProps[] = [...state.transcribers].concat(
      action.payload
    );
    return { ...state, transcribers: usuarios };
  },
  [resetBindsTranscribers.type]: (state) => {
    return { ...state, userTranscriberAssessment: [] };
  },
  [fetchUserTranscriber.type]: (state, action) => {
    let binds = [...state.userTranscriberAssessment].concat(action.payload);
    return { ...state, userTranscriberAssessment: binds };
  },
  [bindUserTranscribers.type]: (state, action) => {
    let listUserAssessments: TranscriberItemAssignment[] = [
      ...state.userTranscriberAssessment,
    ];
    listUserAssessments.push(action.payload);
    return { ...state, userTranscriberAssessment: listUserAssessments };
  },
  [removeUserTranscribers.type]: (state, action) => {
    let listUserAssessments: TranscriberItemAssignment[] = [
      ...state.userTranscriberAssessment,
    ];
    listUserAssessments = listUserAssessments.filter(
      (a: TranscriberItemAssignment) => {
        return a.id !== action.payload;
      }
    );
    return { ...state, userTranscriberAssessment: listUserAssessments };
  },
  [updateTranscriber.type]: (state, action) => {
    let listUserAssessments: TranscriberItemAssignment[] = [
      ...state.userTranscriberAssessment,
    ];

    const index: number = listUserAssessments.indexOf(
      listUserAssessments.find(
        (a: TranscriberItemAssignment) => a.id === action.payload.id
      ) as TranscriberItemAssignment
    );

    listUserAssessments[index] = action.payload;
    return { ...state, userTranscriberAssessment: listUserAssessments };
  },
});
