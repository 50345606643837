import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

interface ISearchTreeFilter {
  callBackFilterFuction: any;
}

const label = "Pesquise...";
export default function FindBar(props: ISearchTreeFilter) {
  const [searchText, setSearchText] = useState("");

  return (
    <Grid
      container
      spacing={2}
      paddingLeft={3}
      paddingTop={3}
      paddingRight={3}
      paddingBottom={1}
    >
      <Grid item xs={8}>
        <TextField
          placeholder={label}
          title={label}
          variant="outlined"
          fullWidth={true}
          size="small"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          sx={{
            width: "100%",
            padding: 1,
            textTransform: "capitalize",
          }}
          variant="contained"
          onClick={(e) => props.callBackFilterFuction(searchText)}
        >
          Pesquisar
        </Button>
      </Grid>
    </Grid>
  );
}
