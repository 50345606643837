interface ILoadHeader {
  headerText: string;
}

export function Header({ headerText }: ILoadHeader) {
  return (
    <header>
      <h3>{headerText}</h3>
    </header>
  );
}
