import { Box } from "@mui/material";

import DeleteDialog from "components/Admin/dialogComponents/DeleteDialog";
import CardBindItemToTeacher from "components/Admin/forms/CardBindItemToTeacher";
import CardBindTranscriberToTeacher from "components/Admin/forms/CardBindTranscibertToItem";
import CardExamRegister from "components/Admin/forms/CardExamRegister";
import CardItemRegister from "components/Admin/forms/CardItemRegister";
import CardRegisterAbility from "components/Admin/forms/CardRegisterAbility";
import CardAssessmentRegister from "components/Admin/forms/CardRegisterAssessment";
import CardRegisterKnowledge from "components/Admin/forms/CardRegisterKnowledge";
import CardTaskRegister from "components/Admin/forms/CardTaskRegister";
import CardTaskSubcontent from "components/Admin/forms/CardTaskSubConntent";
import { PrintAvaluation } from "pages/PrintAvaluation";
import { AbilityRelationship } from "pages/Records/AbilityRelationship";
import { KnowledgeArea } from "pages/Records/KnowladgeArea";
import Relatorio from "pages/Relatorio";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { findExams } from "store/reducers/Admin/AsyncActions/Exam";
import { getTasks } from "store/reducers/Admin/AsyncActions/Tasks";
import { findAssessment } from "store/reducers/Admin/AsyncActions/assessment";
import Footer from "./Footer";
import { MenuIds } from "./Header";
import Sidebar from "./SideBar";

import EditorModal from "components/Admin/editor/EditorModal";

export default function AdminContent(): React.ReactElement {

  const editorOpened: boolean = useSelector(
    (state: any) => state.admin.editorState.open
  );

  return (
    <>
      <Box
        className="admin-full-screen"
        sx={{
          height: "85vh",
        }}
      >

        <Box className="admin-content">
          <Switch>
            <Route
              path="/admin/bind/teacher/assessment"
              component={CardBindItemToTeacher}
            />
            <Route
              path={`/admin/${MenuIds.ABILITY}`}
              component={CardRegisterAbility}
            />
            <Route
              path={`/admin/${MenuIds.KNOWLEDGE_AREA}`}
              component={KnowledgeArea}
            />
            <Route
              path={`/admin/${MenuIds.ABILITY_RELATIONSHIP}`}
              component={AbilityRelationship}
            />

            <Route
              path={`/admin/${MenuIds.KNOWLEDGE}`}
              component={CardRegisterKnowledge}
            />

            <Route
              path="/admin/transcribers"
              component={CardBindTranscriberToTeacher}
            />
            <Route
              path="/admin/dashboard"
              component={Relatorio}
              // component={Dashboard}
            />
            <Route path="/admin/exam/print/" component={PrintAvaluation} />
          </Switch>

          {editorOpened && <EditorModal />}
        </Box>
        
      </Box>
    </>
  );
}
