import { has } from "immer/dist/internal";
import { Ability } from "services/admin/Interfaces/Types";
import { isGetAccessor } from "typescript";

export const validateFormData = (dataForm: Ability): string[] => {
  let erros: string[] = [];
  if (!hasName(dataForm)) erros.push("O Campo nome é obrigatório");

  if (!hasCode(dataForm)) erros.push("O campo código é obrigatório");

  if (!hasKnowledgeArea(dataForm))
    erros.push("É necessário informa a área do conhecimento");

  return erros;
};

function hasName(dataForm: Ability): boolean {
  let hasName = false;
  if (dataForm.name) {
    hasName = true;
  }
  return hasName;
}

function hasCode(dataForm: Ability): boolean {
  let hasCode = false;
  if (dataForm.code) {
    hasCode = true;
  }
  return hasCode;
}

function hasKnowledgeArea(dataForm: Ability): boolean {
  let hasKnowledgeArea = false;
  if (dataForm.knowledge && dataForm.knowledge !== 0) {
    hasKnowledgeArea = true;
  }
  return hasKnowledgeArea;
}
