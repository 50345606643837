import { Button, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  svgImage: any;
  active: boolean;
  action?: any;
  redirectPath?: string;
  toolTipText: string;
}

export default function EditNavHeadButton({
  svgImage,
  active,
  action,
  redirectPath,
  toolTipText,
}: Props) {
  return (
    <>
      {active ? (
        <Link to={redirectPath as string} onClick={(e) => action()}>
          <Tooltip title={toolTipText}>
            <IconButton size="large">
              <SvgIcon
                viewBox="0 0 48 48"
                className={"button-edit-nav-icon-enabled"}
                sx={{ transform: "scale(1.2)", fontSize: "2.5rem" }}
              >
                <rect width="24" height="24" fill="none" />
                {svgImage}
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Link>
      ) : (
        <Tooltip title={toolTipText}>
          <IconButton size="large">
            <SvgIcon
              viewBox="0 0 48 48"
              className={"button-edit-nav-icon-disabled"}
              sx={{ transform: "scale(1.2)", fontSize: "2.5rem" }}
            >
              {svgImage}
            </SvgIcon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
