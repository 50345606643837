import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Roboto",
  },
  components: {
    MuiTab: {
      styleOverrides: {
        textColorPrimary: "red",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#E5193B",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          paddind: 1,
          boxShadow: "none",
          transition: "background-color 0.2s",
          fontFamily: "Poppins",
          ":hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          fontFamily: "Poppins",
          color: "var(--neutral-1)",
          backgroundColor: "var(--primary-regular)",
          transition: "all 0.2s linear",
          "&:hover": {
            backgroundColor: "var(--primary-regular)",
            filter: "brightness(0.9)",
            color: "var(--neutral-1)",
          },
        },
        containedSecondary: {
          fontFamily: "Poppins",
          color: "#1D2432",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#0001",
          },
          border: '1px solid #CACDD5',
          fontWeight: '900'
        },
        containedWarning: {
          fontFamily: "Poppins",
          color: "var(--neutral-1)",
          backgroundColor: "var(--wheat-regular)",
          transition: "all 0.2s linear",
          "&:hover": {
            backgroundColor: "var(--wheat-regular)",
            filter: "brightness(0.9)",
            color: "var(--neutral-1)",
          },
        },
      },
    },
  },
});
