import {
  TaskSubContent,
  TaskSubContentFormData,
} from "services/admin/Interfaces/Types";

export const validateFormData = (
  form: TaskSubContentFormData,
  subs: TaskSubContent[]
): string[] => {
  let erros: string[] = [];
  if (!hasItemContent(form))
    erros.push("O subenunciado deve ter obrigatoriamente uma descrição.");
  if (!hasItemNumber(form))
    erros.push("Deve-se indicar a ordem do subenunciado na tarefa.");
  if (isOrderNegative(form)) erros.push("Ordem do subenunciado inválida.");
  if (isSubContentOrderInUse(form, subs))
    erros.push(
      "A ordem indicada para o sub enunciado já está atribuida a outro subenunciado presente na mesma tarefa."
    );

  return erros;
};

function hasItemContent(dataForm: TaskSubContentFormData): boolean {
  let hasContent = (dataForm.content !== undefined &&
    dataForm.content &&
    dataForm.content !== "") as boolean;
  return hasContent;
}

function hasItemNumber(dataForm: TaskSubContentFormData): boolean {
  let hasNumber: boolean = false;
  if (dataForm.position !== undefined && dataForm.position !== 0)
    hasNumber = true;

  return hasNumber;
}

function isOrderNegative(dataForm: TaskSubContentFormData): boolean {
  let zeroOrnegative: boolean = false;
  if ((dataForm.position as number) < 1) zeroOrnegative = true;

  return zeroOrnegative;
}

function isSubContentOrderInUse(
  dataForm: TaskSubContentFormData,
  subs: TaskSubContent[]
): boolean {
  let numberAlreadyInUse: boolean = false;
  let subsFiltered: TaskSubContent[] | undefined = subs.filter((i) => {
    return i.id !== dataForm.id && i.position === Number(dataForm.position);
  });

  if (subsFiltered !== undefined && subsFiltered.length > 0)
    numberAlreadyInUse = true;

  return numberAlreadyInUse;
}
