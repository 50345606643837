import AlertService from "services/admin/AlertService";
import {
  AssessmentExam,
  AssessmentFormData,
  SelectedElement,
} from "services/admin/Interfaces/Types";
import { getAllAssessmentExam } from "../../Actions/assessmentExam/action";
import { setSelectedElement } from "../../Actions/treeView/actions";
import { findAssessment } from "../assessment";
import AssessmentsExamServices from "./assessment.exam.service";

export const registerAssessmentExam = (
  idExam: number,
  idAssessment: number
) => {
  return (dispatch: any) => {
    AssessmentsExamServices.registerExamAssesment(idExam, idAssessment).then(
      ({ data }: any) => {
        //dispatch(findAssessmentExam());
        dispatch(findAssessment());
      }
    );
  };
};

export const registerAssessmentExamWithCallBack = (
  idExam: number,
  idAssessment: number,
  callBackFunction: any
) => {
  return (dispatch: any) => {
    AssessmentsExamServices.registerExamAssesment(idExam, idAssessment)
      .then(({ data }: any) => {
        //dispatch(findAssessmentExam());
        dispatch(findAssessment());
        callBackFunction();
        AlertService.showAlert({
          type: "success",
          time: 5000,
          message: "Exame carregado com sucesso.",
        });
      })
      .catch(() =>
        AlertService.showAlert({
          type: "erro",
          time: 5000,
          message: "Não foi possível carregar Exame.",
        })
      );
  };
};

export const registerAssessmentExamAndUpdateSelectedElementDataForm = (
  selectedElement: SelectedElement,
  idExam: number,
  idAssessment: number
) => {
  return (dispatch: any) => {
    AssessmentsExamServices.registerExamAssesment(idExam, idAssessment).then(
      ({ data }: any) => {
        let formData: AssessmentFormData = {
          ...selectedElement.elementDataForm,
        } as AssessmentFormData;

        let exams = [...formData.exams];
        exams.push(data);
        formData["exams"] = exams;

        selectedElement["elementDataForm"] = formData;
        dispatch(setSelectedElement(selectedElement));
        dispatch(findAssessment());
      }
    );
  };
};

export const deleteAssessmentExamAndUpdateSelectedElementDataForm = (
  selectedElement: SelectedElement,
  exclusionIndex: number,
  idAssessmentExam: number
) => {
  return (dispatch: any) => {
    AssessmentsExamServices.deleteExamAssesment(idAssessmentExam).then(
      ({ data }: any) => {
        let formData: AssessmentFormData = {
          ...selectedElement.elementDataForm,
        } as AssessmentFormData;

        let exams = [...formData.exams];
        exams.splice(exclusionIndex, 1);
        formData["exams"] = exams;

        selectedElement["elementDataForm"] = formData;
        dispatch(setSelectedElement(selectedElement));
        dispatch(findAssessment());
      }
    );
  };
};

export const findAssessmentExam = () => {
  return (dispatch: any) => {
    AssessmentsExamServices.getExamAssesment().then(({ data }: any) => {
      const assessmentExam: AssessmentExam[] = data.results;
      dispatch(getAllAssessmentExam(assessmentExam));
    });
  };
};

export const proccessDeleteAssessmentExam = (id: number) => {
  return (dispatch: any) => {
    AssessmentsExamServices.deleteExamAssesment(id).then(({ data }: any) => {
      //dispatch(findAssessmentExam());
      dispatch(findAssessment());
    });
  };
};
