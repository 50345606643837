// @ts-nocheck
import { useState } from "react";

import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";

import { DatePicker } from "@mui/lab";

import BasicChart from "./BasicChart";

import { GraficoAnaliseAlunos } from "./components/GraficoAnaliseAlunos";
import { GraficoCircular } from "./components/GraficoCircular";
import { Filters } from "./components/Filters";
import { TabelaRelatorio } from "./components/TabelaRelatorio";

import { BoxTotalRespostasSintetizadas } from "./components/BoxTotalRespostasSintetizadas";

import api from "../../../services/api";

export default function Dashboard() {
  const [report, setReport] = useState<any>(null);

  const [idAvaliacao, setIdAvaliacao] = useState<string>("");

  async function getReport({
    idAssessment,
    idSchool = null,
    idExam = null,
    idClass = null,
  }: {
    idAssessment: number | null;
    idSchool?: number;
    idExam?: number;
    idClass?: number;
  }) {
    if (idAssessment == null) {
      setReport(null);
      setIdAvaliacao("");
      return;
    }

    setIdAvaliacao(idAssessment);

    await api
      .get(`assessments/assessments/${idAssessment}/report/`, {
        params: {
          school: idSchool,
          exam: idExam,
          class: idClass,
        },
      })
      .then((res: any) => {
        res.data.detail === "Not found."
          ? setReport(null)
          : setReport(res.data);
      })
      .catch((err) => setReport({ err: err }));
  }

  return (
    <Box p={3}>
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "50px",
              }}
            >
              Dashboard
            </Typography>
          </Grid>
        </Grid>

        <Filters getReport={getReport} />

        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ marginTop: "24px" }}>
              <Typography variant="h5">Sumário da avaliação</Typography>
            </Grid>

            <Grid item md={8}>
              <GraficoAnaliseAlunos
                qtd_concludentes={(report && report.students_finished) ?? 0}
                qtd_fazendo={(report && report.students_in_progress) ?? 0}
                qtd_pendentes={(report && report.students_pending) ?? 0}
              />
            </Grid>

            <Grid item md={4}>
              <BoxTotalRespostasSintetizadas
                total={(report && report?.answers_stats?.total) ?? 0}
              />
            </Grid>

            <Grid
              item
              md={12}
              style={{
                display: 'flex', 
                alignItems: 'strech',
                gap: 10,
              }}
            >
              <GraficoCircular
                valueTotal={100}
                titleChart="Percentual de Avaliações"
                titleListLegends="Rubricas:"
                pieChartElementCenterTitle={
                  (report?.evaluations?.total_percentage ?? "0") + "%"
                }
                pieChartElementCenterSubTitle="Total"
                data={[
                  {
                    color: "#F1530F",
                    title: '"Insuficiente"',
                    value: report?.evaluations?.rubrics[0].percentage ?? 0,
                    valueLegend: `${
                      report?.evaluations?.rubrics[0].percentage ?? 0
                    }%`,
                  },
                  {
                    color: "#F1C40F",
                    title: '"Parcialmente Insuficiente"',
                    value: report?.evaluations?.rubrics[1].percentage ?? 0,
                    valueLegend: `${
                      report?.evaluations?.rubrics[1].percentage ?? 0
                    }%`,
                  },
                  {
                    color: "#00E676",
                    title: '"Suficiente"',
                    value: report?.evaluations?.rubrics[2].percentage ?? 0,
                    valueLegend: `${
                      report?.evaluations?.rubrics[2].percentage ?? 0
                    }%`,
                  },
                  {
                    color: "#333",
                    title: "Sem Rubrica",
                    value: 100 - (report?.evaluations?.total_percentage ?? 0),
                    valueLegend: `${
                      Number.isInteger(
                        100 - (report?.evaluations?.total_percentage ?? 0)
                      )
                        ? 100 - (report?.evaluations?.total_percentage ?? 0)
                        : (
                            100 - (report?.evaluations?.total_percentage ?? 0)
                          ).toFixed(2)
                    }%`,
                  },
                ]}
              />

              <GraficoCircular
                valueTotal={report?.answers_stats?.total ?? 0}
                titleChart="Descritivo de Respostas"
                pieChartElementCenterTitle={report?.answers_stats?.total ?? 0}
                pieChartElementCenterSubTitle="Total"
                positionElementsLegend="first_title"
                data={[
                  {
                    color: "var(--info-regular)",
                    title: "Total Alunos:",
                    value: report?.answers_stats?.source_student ?? 0,
                    valueLegend: report?.answers_stats?.source_student ?? 0,
                  },
                  {
                    color: "var(--primary-regular)",
                    title: "Total Transcritos:",
                    value: report?.answers_stats?.source_transcriber ?? 0,
                    valueLegend: report?.answers_stats?.source_transcriber ?? 0,
                  },
                  {
                    color: "var(--primary-dark)",
                    title: "Total OCR:",
                    value: report?.answers_stats?.source_transcriber_ocr ?? 0,
                    valueLegend:
                      report?.answers_stats?.source_transcriber_ocr ?? 0,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <TabelaRelatorio
            items={report?.items_stats ?? []}
            idAvaliacao={idAvaliacao}
          />
        </Grid>

        <Grid item md={6} sx={{ display: "none" }}>
          <Box p={2}>
            <Typography variant="h5">Avaliações por mês</Typography>
            <Typography variant="body1">
              Visualize abaixo o resumo das avaliações aplicadas no perído de
              xx/yy a zz/yy
            </Typography>
            <ButtonGroup sx={{ marginBottom: "24px", marginTop: "12px" }}>
              <Button>Atualizar dados</Button>
              <Button>Limpar período</Button>
              <Button>Exportar CSV</Button>
            </ButtonGroup>

            <DatePicker label="Início do período" />
            <DatePicker label="Fim do período" />
            <BasicChart />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
