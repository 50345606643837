import { useEffect } from 'react';
import { Backdrop, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Await() {
    const history = useHistory();

    useEffect(() => {
        setTimeout(()=> {
            history.push("/")
        },1000)

    }, [])
    return(
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
    )
}