import { createAction } from "@reduxjs/toolkit";
import { AbilityEdge, Page } from "services/admin/Interfaces/Types";

export const registerAbilityEdge = createAction<AbilityEdge>(
  "REGISTER_ABILITY_EDGE"
);
export const fetchAbilityEdge =
  createAction<Page<AbilityEdge>>("FETCH_ABILITY_EDGE");
export const updateAbilitEdge = createAction<AbilityEdge>(
  "UPDATE_ABILITY_EDGE"
);
export const removeAbilityEdge = createAction("REMOVE_ABILITY_EDGE");
export const clearAbilityEdge = createAction("CLEAR_ABILITY_EDGE");
