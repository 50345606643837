import { IconButton } from "@mui/material";

interface IInputFormIconButton {
  icon: any;
  action: any;
}

const InputFormIconButton = ({ icon, action }: IInputFormIconButton) => {
  return (
    <IconButton
      sx={{
        width: "35px",
        height: "35px",
        ml: "5px",
        borderRadius: "3px",
        background: "var(--primary-regular)",
        color: "var(--neutral-1)",
        "&:hover": {
          backgroundColor: "var(--primary-regular)",
          color: "var(--neutral-1)",
          boxShadow: "none",
        },
        "&.Mui-focusVisible": {
          backgroundColor: "transparent",
        },
      }}
      onClick={(e) => {
        action();
      }}
    >
      {icon}
    </IconButton>
  );
};

export default InputFormIconButton;
