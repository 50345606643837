import { Search, FilterListOff } from "@mui/icons-material";
import { TextField, MenuItem, Button } from "@mui/material";
import { SelectOptionsProps, SearchAreaProps } from "./types";
import { Label } from "components/Label";
import "./search-area.scss";

function SelectOptions({
  id,
  label,
  placeholder,
  options,
  value,
  onChange,
}: SelectOptionsProps) {
  return (
    <div className="select-item">
      <Label htmlFor={id} text={label} />
      <TextField
        id={id}
        name={id}
        placeholder={placeholder}
        select
        value={value ?? "0"}
        onChange={({ target: { value, name } }) => {
          if (value === "0") return;
          onChange && onChange(value, name);
        }}
      >
        <MenuItem value={"0"} disabled>
          <span className="default-selection">{placeholder}</span>
        </MenuItem>
        {(options || []).map(({ content, ...option }) => (
          <MenuItem {...option}>{content}</MenuItem>
        ))}
      </TextField>
    </div>
  );
}

export function SearchArea({
  filters,
  selects,
  onReset,
  onChange,
  onFilter,
  loading = false,
}: SearchAreaProps) {
  return (
    <form id="search-area-card-load-task">
      <div className="form-item">
        <Label htmlFor="title" text="Título do Conteúdo" />
        <TextField
          id="title"
          name="title"
          placeholder="Digite o título do conteúdo..."
          value={filters["title"] ?? ""}
          onChange={({ target: { value, name } }) => {
            onChange && onChange(value, name);
          }}
        />
      </div>

      <div className="form-item">
        <Label htmlFor="text" text="Descrição do Conteúdo" />
        <TextField
          id="text"
          name="text"
          placeholder="Digite a descrição do conteúdo..."
          value={filters["text"] ?? ""}
          onChange={({ target: { value, name } }) => {
            onChange && onChange(value, name);
          }}
        />
      </div>

      {selects.map((props) => (
        <div className="form-item" key={props.id}>
          <SelectOptions
            {...props}
            onChange={onChange}
            value={filters[props.id]}
          />
        </div>
      ))}

      <div className="form-actions">
        <Button
          fullWidth={false}
          type="button"
          variant="contained"
          endIcon={<Search />}
          onClick={() => onFilter()}
          disabled={loading}
        >
          {loading ? "Carregando..." : "Pesquisar"}
        </Button>

        <Button
          variant="text"
          type="reset"
          onClick={onReset}
          endIcon={<FilterListOff />}
        >
          Limpar Filtro
        </Button>
      </div>
    </form>
  );
}
