async function addBreakPageAndHeaderPage(identifyElements: Element[], assessmentCode: string) {
    if (identifyElements.length <= 0) return

    for (const identify of identifyElements) {
        if (identify.parentNode) {
            const numPage = identifyElements.indexOf(identify) + 1

            const pageHeader = document.createElement("div");

            pageHeader.classList.add('page_header')

            if (numPage > 1) {
                pageHeader.classList.add('page_before_break')
            }

            pageHeader.innerHTML = `
                <div class="info_page">
                    <div id="pageFooter">P. ${numPage}</div>
                </div>

                <div class="info_notebook">
                    CADERNO ${assessmentCode}
                </div>
            `

            identify?.parentNode.insertBefore(pageHeader, identify);
        }
    }
}

export async function addBreakPage(elementFather: string, assessmentCode: string) {
    const father = document.querySelector(elementFather)
            
    if (!father) return false

    const totalHeightPageA4 = 1122
    let sumTotalHeight = 60
    let arrayForAddHeaderAndBreakPage: Element[] = []

    for (let t in father.children) {
        if (father.children[t].clientHeight) {
            sumTotalHeight += father.children[t].clientHeight;

            if (sumTotalHeight > totalHeightPageA4 || t === '0' || t === '1') {
                arrayForAddHeaderAndBreakPage.push(father.children[t])
                sumTotalHeight = 60 + father.children[t].clientHeight
            }
        }
    }

    await addBreakPageAndHeaderPage(arrayForAddHeaderAndBreakPage, assessmentCode)

    return true
}