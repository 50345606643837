import { FormControl, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Content,
  ContentFormData,
  Item,
  SelectedElement,
  /*   EditorModalState, */
  Task,
  TaskFormData,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processTaskUpdate,
  registerTask,
  registerTaskWithContent,
  registerTaskWithExam,
  updateTaskWithContent,
} from "store/reducers/Admin/AsyncActions/Tasks";

//import { openMarkdown } from "../utils/newMarkdownUtils";

import { validateFormData } from "../validators/taskValidator";
import CardBase from "./CardBase";
import { InputMarkdown } from "components/InputMarkdown";
import { useEffect, useState } from "react";
import { gerarTaskFormData } from "../utils/elementBuilders";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";

export default function CardRegisterTaskContent() {
  const dispatch = useDispatch();
  const params = useParams() as { id_exam: string; number_order: string };

  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  let contentDataForm: ContentFormData = {
    ...selectedElement.elementDataForm,
  } as ContentFormData;

  const [dataForm, setDataForm] = useState<TaskFormData>();
  useEffect(() => {
    if (contentDataForm.object) {
      let form: TaskFormData = gerarTaskFormData(
        contentDataForm.object as Task
      );
      setDataForm(form);
    } else {
      setDataForm(gerarTaskFormData(undefined));
    }
  }, [dispatch]);

  const handleDataForm = (key: string, value: unknown) => {
    let form: TaskFormData = { ...dataForm } as TaskFormData;
    form[key] = value;
    setDataForm(form);
  };

  const buildTaskFromForm = () => {
    if (!dataForm) {
      return undefined;
    }

    let task: Task = {
      id: dataForm.id,
      content: dataForm.description,
      title: dataForm.title,
      items: dataForm.items as Item[],
    };
    return task;
  };

  const buildFormData = (): Content => {
    return {
      id: contentDataForm.id,
      title: contentDataForm.title,
      subcontent_type: contentDataForm.subcontent_type,
      created_at: contentDataForm.created_at,
      created_by: contentDataForm.created_by,
      updated_at: contentDataForm.updated_at,
      subcontents: contentDataForm.subcontents,
      parent: contentDataForm.parent,
    };
  };

  return (
    <CardBase
      title={dataForm?.id ? "Tarefa" : "Inserir Nova Tarefa"}
      actionRegister={() =>
        registerTaskWithContent(buildTaskFromForm() as Task, buildFormData())
      }
      actionUpdate={() =>
        updateTaskWithContent(buildTaskFromForm() as Task, buildFormData())
      }
      validateMethod={() => validateFormData(dataForm as TaskFormData)}
      postSubmitRedirectUrl="/editor"
      dataForm={dataForm}
      editorContent={true}
    >
      <Box>
        <InputLabel required>Título</InputLabel>
        <FormControl fullWidth>
          <TextField
            placeholder="Digite o título da tarefa"
            size="medium"
            required
            value={dataForm?.title}
            onChange={(e) => handleDataForm("title", e.target.value)}
            sx={{ background: "white" }}
          />
        </FormControl>
      </Box>

      <Box>
        <InputMarkdown
          required
          label="Descrição da tarefa"
          textValue={dataForm?.description as string}
          callBackValue={(e) => handleDataForm("description", e)}
        />
      </Box>
    </CardBase>
  );
}
