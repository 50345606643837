import { createAction } from "@reduxjs/toolkit";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IsearchData } from "..";
import { TeacherItemAssignment } from "../AsyncActions/types";

export const setSearchData = createAction<IsearchData>("set_search_data");
export const getUsers = createAction<IRegisterProps[]>("GET_USERS");
export const resetUsers = createAction("RESET_USERS");
export const resetBinds = createAction("RESET_BINDS");
export const setDataForm = createAction<TeacherItemAssignment>("SET_DATA_FORM");
export const bindUserAssessment = createAction<TeacherItemAssignment>(
  "BIND_USER_ASSESSMENT"
);
export const removeUserAssessment = createAction<number>(
  "REMOVE_USER_ASSESSMENT"
);

export const updateUserAssessment = createAction<TeacherItemAssignment>(
  "UPDATE_USER_ASSESSMENT"
);

export const fetchUserAssessment = createAction<TeacherItemAssignment[]>(
  "FETCH_USER_ASSESSMENTS"
);
