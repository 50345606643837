import { createAction } from "@reduxjs/toolkit";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IsearchDataTranscriber } from "..";
import { TranscriberItemAssignment } from "../AsyncActions/types";

export const getUsersTranscribers = createAction<IRegisterProps[]>(
  "GET_USERS_TRANSCRIBERS"
);
export const resetUsersTranscribers = createAction("RESET_USERS_TRANSCRIBERS");
export const resetBindsTranscribers = createAction("RESET_BINDS_TRANSCRIBERS");
export const setDataFormTranscribers = createAction<TranscriberItemAssignment>(
  "SET_DATA_FORM_TRANSCRIBERS"
);
export const bindUserTranscribers = createAction<TranscriberItemAssignment>(
  "BIND_USER_TRANSCRIBERS"
);
export const removeUserTranscribers = createAction<number>(
  "REMOVE_USER_TRANSCRIBERS"
);

export const updateTranscriber = createAction<TranscriberItemAssignment>(
  "UPDATE_USER_TRANSCRIBERS"
);

export const fetchUserTranscriber = createAction<TranscriberItemAssignment[]>(
  "FETCH_USER_TRANSCRIBERS"
);

export const setSearchDataTranscriber = createAction<IsearchDataTranscriber>(
  "SET_SEARCH_DATA_TRANSCRIBER"
);
