import { createAction } from "@reduxjs/toolkit";
import { TaskSubContent } from "services/admin/Interfaces/Types";

export const FETCH_TASK_SUB_CONTENT = createAction<TaskSubContent[]>(
  "FETCH_TASK_SUB_CONTENT"
);
export const FETCH_TASK_SUB_CONTENT_ID = createAction<TaskSubContent>(
  "FETCH_TASK_SUB_CONTENT_ID"
);

export const RESET_TASK_SUB_CONTENT = createAction("RESET_TASK_SUB_CONTENT");
export const RESET_TASK_SUB_CONTENT_ID = createAction<TaskSubContent>(
  "RESET_TASK_SUB_CONTENT_ID"
);

export const SET_TASK_SUB_CONTENT_DATAFORM = createAction(
  "SET_TASK_SUB_CONTENT_DATAFORM"
);
export const RESET_TASK_SUB_CONTENT_DATAFORM = createAction(
  "RESET_TASK_SUB_CONTENT_DATAFORM"
);
