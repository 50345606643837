import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
} from "@mui/material";

import React from "react";
import { useQuery, useQueryClient } from "react-query";
import AbilitiesService from "store/reducers/abilities/AsyncActions/abilities.service";
import KnowledegeAreasService from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges-area.services";
import KnowledegesServices from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges.services";

enum Selectors {
  ABILITY = "ability",
  KNOWLEDGE_AREA = "knowledge_area",
  KNOWLEDGE = "knowledge",
}

const INITIAL_DATA = {
  [Selectors.ABILITY]: 0,
  [Selectors.KNOWLEDGE]: 0,
  [Selectors.KNOWLEDGE_AREA]: 0,
};

export interface onSelectAbilityEvent {
  abilityId: number;
  name: string;
}

interface Props {
  onSelectAbility: (response: onSelectAbilityEvent) => void;
  toClear: (cleanFunction: () => void) => void;
  name: string;
}

export const AbilitySelector = ({ name, onSelectAbility, toClear }: Props) => {
  const [data, setData] = React.useState<object>(INITIAL_DATA);
  const { data: knowledgeAreasData } = useQuery(
    Selectors.KNOWLEDGE_AREA,
    KnowledegeAreasService.getAllKnowledgeArea
  );

  const client = useQueryClient();

  const generateKey = (key: string) => {
    return `${key}_${name}`;
  };

  const { data: knowledgeData } = useQuery(
    [generateKey(Selectors.KNOWLEDGE), data[Selectors.KNOWLEDGE_AREA]],
    () =>
      KnowledegesServices.getKnowledesByFilter({
        knowledge_area: data[Selectors.KNOWLEDGE_AREA],
      })
  );

  const { data: abilitiesData } = useQuery(
    [generateKey(Selectors.ABILITY), data["knowledge"]],
    () => AbilitiesService.getAbilityByFilter({ knowledge: data["knowledge"] })
  );

  const clear = () => {
    setData(INITIAL_DATA);
  };
  const handleChange = (event: SelectChangeEvent<number>) => {
    if (event.target.name === Selectors.KNOWLEDGE_AREA) {
      setData({
        [event.target.name]: event.target.value,
        [Selectors.ABILITY]: 0,
        [Selectors.KNOWLEDGE]: 0,
      });
      client.invalidateQueries(Selectors.KNOWLEDGE);
      client.invalidateQueries(Selectors.ABILITY);
      return;
    }

    if (event.target.name === Selectors.KNOWLEDGE) {
      setData({
        ...data,
        [event.target.name]: event.target.value,
        [Selectors.ABILITY]: 0,
      });
      client.invalidateQueries(Selectors.ABILITY);
      return;
    }

    setData({ ...data, [event.target.name]: event.target.value });
    onSelectAbility({ abilityId: event.target.value as number, name });
    toClear(clear);
  };

  return (
    <>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="knowleged_area_label">
            Área do conhecimento
          </InputLabel>
          <Select
            labelId="knowleged_area_label"
            id="knowledge-area"
            name={Selectors.KNOWLEDGE_AREA}
            label="Área do conhecimento"
            onChange={handleChange}
            defaultValue={0}
            value={data[Selectors.KNOWLEDGE_AREA]}
          >
            <MenuItem disabled value={0}>
              <em>Selecione...</em>
            </MenuItem>
            {knowledgeAreasData?.results.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="knowleged_label">Conhecimento</InputLabel>
          <Select
            labelId="knowleged_label"
            id={Selectors.KNOWLEDGE}
            name={Selectors.KNOWLEDGE}
            label="Conhecimento"
            defaultValue={0}
            onChange={handleChange}
            disabled={!data[Selectors.KNOWLEDGE_AREA]}
            value={data[Selectors.KNOWLEDGE]}
          >
            <MenuItem disabled value={0}>
              <em>Selecione...</em>
            </MenuItem>
            {knowledgeData?.data.results.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="abilities_label">Habilidade</InputLabel>
          <Select
            labelId="abilities_label"
            id={Selectors.ABILITY}
            name={Selectors.ABILITY}
            label="Habilidade"
            defaultValue={0}
            onChange={handleChange}
            disabled={!data[Selectors.KNOWLEDGE]}
            value={data[Selectors.ABILITY]}
          >
            <MenuItem disabled value={0}>
              <em>Selecione...</em>
            </MenuItem>
            {abilitiesData?.data.results.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
