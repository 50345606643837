import { createReducer } from "@reduxjs/toolkit";
import { Knowledge, Page } from "services/admin/Interfaces/Types";
import { FETCH_KNOWLEDGES, SET_DATA_FORM_KNOWLEDGE } from "./Actions";

const emptyPage: Page<Knowledge> = {
  count: 0,
  previous: "",
  next: "",
  results: [],
};

const emptyDataForm: Knowledge = {
  code: "",
  name: "",  
  knowledge_area: 0,
};

const INITIAL_VALUES = {
  currentPage: emptyPage,
  dataForm: emptyDataForm,
};

export default createReducer(INITIAL_VALUES, {
  [FETCH_KNOWLEDGES.type]: (state, action) => {
    state.currentPage = action.payload;
  },
  [SET_DATA_FORM_KNOWLEDGE.type]: (state, action) => {
    state.dataForm = action.payload;
  },
});
