import QRCode from "react-qr-code";

import { MarkdownRender } from "lib_editor";
import {
  T1,
  T2,
  T3,
  T4,
  T5,
  Dica,
  Observacao,
  Audio,
  Atividade,
  Video,
  Explanation,
  Carrossel,
  Equacao,
  CodeHighlighter,
} from "lib_editor";
import "lib_editor/dist/style.css";

import { Item } from "services/admin/Interfaces/Types";

import "./styles.css";

const components = {
  ex: Explanation,
  dica: Dica,
  obs: Observacao,
  au: Audio,
  atv: Atividade,
  video: Video,
  equacao: Equacao,
  code: CodeHighlighter,
  carrossel: Carrossel,
  h1: T1,
  h2: T2,
  h3: T3,
  h4: T4,
  h5: T5,
};

interface IItemEvaluation {
  item: Item;
  num_item: number;
  id_exam: number;
  id_assessment: number;
}

const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export function ItemEvaluation({
  item,
  num_item,
  id_assessment,
  id_exam,
}: IItemEvaluation) {
  const QTD_LINHAS_RESPOSTA = 4;

  function renderOptions() {
    return (
      <div className="alternatives" id={`alternatives_question_${num_item}`}>
        {item.choices &&
          item.choices.map((item, index) => (
            <div className="alternative" id={`alternative_question_${item.id}`}>
              <div className="letter_question">{`${ALPHABET[index]})`} </div>

              <div
                style={{ width: "100%", position: "relative", bottom: "20px" }}
              >
                <MarkdownRender
                  fit
                  components={components}
                  markdownCode={item.content}
                />
              </div>
            </div>
          ))}
      </div>
    );
  }

  function renderLinesForAnswer() {
    return (
      <>
        {QTD_LINHAS_RESPOSTA > 0 && (
          <div className="lines_answer" id={`lines_answer_${num_item}`}>
            {Array.from({ length: QTD_LINHAS_RESPOSTA }).map(() => (
              <>
                <hr style={{ marginBlock: "10px" }} />
              </>
            ))}
          </div>
        )}
      </>
    );
  }

  return (
    <div className="content_item_evaluation" id={`question_${num_item}`}>
      <span className="num_item" id={`head_question_${num_item}`}>
        Questão {num_item}
      </span>

      <div id={`content_question_${num_item}`}>
        {item.content && (
          <MarkdownRender
            fit
            components={components}
            markdownCode={item.content}
          />
        )}
      </div>

      <div className="area_qr_code">
        <QRCode
          value={`${id_assessment};${id_exam};${item.id}`}
          size={50}
          bgColor={"#ffffff"}
          fgColor={"#202020"}
          level={"L"}
        />
      </div>

      {(item.is_hybrid || item.choices.length > 0) && renderOptions()}

      {(item.is_hybrid || item?.choices?.length <= 0) && renderLinesForAnswer()}
    </div>
  );
}
