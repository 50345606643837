import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  gerarAssessmentFormData,
  gerarContentFormData,
  gerarElementoSelecionavel,
  gerarExamFormData,
  gerarItemFormData,
  gerarTaskFormData,
  gerarTaskSubcontentFormData,
} from "../utils/elementBuilders";

import { ReactComponent as Lixeira } from "assets/editiNavHeadImages/Principal-7.svg";

import { ReactComponent as Pencil } from "assets/editiNavHeadImages/pencil.svg";
import { ReactComponent as Duplicate } from "assets/editiNavHeadImages/duplicate.svg";
import { ReactComponent as Duplicate_DISABLED } from "assets/editiNavHeadImages/duplicate_disabled.svg";
import { ReactComponent as Up } from "assets/editiNavHeadImages/UP.svg";
import { ReactComponent as Down } from "assets/editiNavHeadImages/DOWN.svg";
import { ReactComponent as Up_ENABLED } from "assets/editiNavHeadImages/UP_ENABLED.svg";
import { ReactComponent as Down_ENABLED } from "assets/editiNavHeadImages/DOWN_ENABLED.svg";

import { ReactComponent as Avaliacao } from "assets/editiNavHeadImages/contextButtomMenu/avaliacao.svg";
import { ReactComponent as Exame } from "assets/editiNavHeadImages/contextButtomMenu/exame.svg";
import { ReactComponent as Item } from "assets/editiNavHeadImages/contextButtomMenu/item.svg";
import { ReactComponent as Subenunciado } from "assets/editiNavHeadImages/contextButtomMenu/subenunciado.svg";
import { ReactComponent as Tarefa } from "assets/editiNavHeadImages/contextButtomMenu/tarefa.svg";

import { ReactComponent as Aula } from "assets/editiNavHeadImages/contextButtomMenu/aula_ativo.svg";
import { ReactComponent as Conteudo } from "assets/editiNavHeadImages/contextButtomMenu/conteudo_ativo.svg";
import { ReactComponent as TarefaAtivo } from "assets/editiNavHeadImages/contextButtomMenu/tarefa_ativo.svg";
import { ReactComponent as ItemAtivo } from "assets/editiNavHeadImages/contextButtomMenu/item_ativo.svg";

import {
  Content,
  ElementTypes,
  ExamFormData,
  SelectedElement,
  Task,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import EditNavHeadButton from "./EditNavHeadButton";
import { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import ContextualMenuListItem from "./ContextualMenuListItem";
import { padding } from "@mui/system";
import { moveTaskInsideExam } from "store/reducers/Admin/AsyncActions/Tasks";

interface IEditNavHead {
  onClickDelete: any;
}

export default function EditNavHead({ onClickDelete }: IEditNavHead) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedElement: SelectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;

  const hasSelectedElement = () => {
    return (
      selectedElement.elementType !== undefined &&
      selectedElement.element !== undefined
    );
  };

  const checkSelectedElementType = (checkType: string) => {
    return selectedElement.elementType === checkType;
  };

  const initDataFormCreationTask = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK,
          undefined,
          gerarTaskFormData(undefined),
          selectedElement.element?.id
        )
      )
    );
  };

  const initDataFormCreationItem = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ITEM,
          undefined,
          gerarItemFormData(undefined, selectedElement.element as Task),
          undefined
        )
      )
    );
  };

  const initDataFormCreationSubcontent = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK_SUBCONTENT,
          undefined,
          gerarTaskSubcontentFormData(
            undefined,
            selectedElement.element as Task
          ),
          undefined
        )
      )
    );
  };

  const initDataFormCreationExam = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.EXAM,
          undefined,
          gerarExamFormData(undefined, undefined),
          selectedElement.element?.id
        )
      )
    );
  };

  const initDataFormCreationAssessment = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ASSESSMENT,
          undefined,
          gerarAssessmentFormData(undefined),
          undefined
        )
      )
    );
  };

  const initDataFormCreationContent = (
    contentSubType: "task" | "item" | "subcontent" | "content",
    parent: number | undefined
  ) => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.CONTENT,
          undefined,
          gerarContentFormData(undefined, contentSubType, parent),
          undefined
        )
      )
    );
  };

  const moveSelectedElement = (direction: "UP" | "DOWN") => {
    if (hasSelectedElement() && checkSelectedElementType(ElementTypes.TASK)) {
      dispatch(
        moveTaskInsideExam(
          selectedElement.element as Task,
          selectedElement.parentElement as number,
          direction
        )
      );
    }
  };
  // function generateRouteCreateTask() {
  //   if (selectedElement.elementType === ElementTypes.EXAM) {
  //     const qtdTasks: ExamFormData =
  //       selectedElement.elementDataForm as ExamFormData;

  //     return `/editor/create/task/${selectedElement.element?.id}/${qtdTasks.tasks.length}`;
  //   } else {
  //     return "/editor/create/task";
  //   }
  // }

  return (
    <Grid
      container
      spacing={2}
      paddingLeft={2}
      paddingRight={3}
      paddingBottom={0}
    >
      <Grid item xs={5} gap={"12px"}>
        <Box
          sx={{
            paddingLeft: 1,
            paddingTop: 1,
          }}
        >
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
            sx={{ textTransform: "none", width: "100%" }}
            disabled={selectedElement.elementType === undefined}
          >
            Inserir
          </Button>
        </Box>

        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            color: "var(--primary-regular)",
            "& .MuiPaper-root": {
              border: "1px solid var(--neutral-3)",
              borderRadius: "8px",
            },
          }}
        >
          {selectedElement.elementType === ElementTypes.CONTENT ? (
            <>
              <ContextualMenuListItem
                text="Aula"
                icon={<Aula />}
                action={() => initDataFormCreationContent("content", undefined)}
                redirectUrl="/editor/create/class/content"
              />
              <ContextualMenuListItem
                text="Conteúdo"
                icon={<Conteudo />}
                disabled={
                  !hasSelectedElement() ||
                  ((selectedElement.element as Content).subcontent_type !==
                    "content" &&
                    (selectedElement.element as Content).subcontent_type !==
                      "subcontent")
                }
              />
              <ContextualMenuListItem
                text="Novo Conteúdo"
                disabled={
                  !hasSelectedElement() ||
                  ((selectedElement.element as Content).subcontent_type !==
                    "content" &&
                    (selectedElement.element as Content).subcontent_type !==
                      "subcontent")
                }
                action={() =>
                  initDataFormCreationContent(
                    "subcontent",
                    selectedElement.element?.id
                  )
                }
                redirectUrl="/editor/create/class/subcontent"
              />
              <ContextualMenuListItem
                text="Carregar Conteúdo"
                action={() => handleClose()}
                disabled={
                  !hasSelectedElement() ||
                  ((selectedElement.element as Content).subcontent_type !==
                    "content" &&
                    (selectedElement.element as Content).subcontent_type !==
                      "subcontent")
                }
                redirectUrl="/editor/load/content/"
              />

              <ContextualMenuListItem
                text="Tarefa"
                icon={<Tarefa />}
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("CONTENT"))
                }
              />
              <ContextualMenuListItem
                text="Nova Tarefa"
                redirectUrl="/editor/create/class/task"
                action={() =>
                  initDataFormCreationContent(
                    "task",
                    selectedElement.element?.id
                  )
                }
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("CONTENT"))
                }
              />
              <ContextualMenuListItem
                text="Carregar Tarefa"
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("CONTENT"))
                }
                redirectUrl="/editor/load/task"
              />
              <ContextualMenuListItem
                text="Item"
                icon={<ItemAtivo />}
                redirectUrl="/editor/create/item"
                action={() => {
                  handleClose();
                  dispatch(
                    setSelectedElement({
                      elementType: selectedElement.elementType,
                      element: selectedElement.element,
                      elementDataForm: {
                        ...gerarItemFormData(undefined, undefined),
                        number:
                          (selectedElement.element as Content).subcontents!
                            .length + 1,
                        taskId: undefined,
                        task: undefined,
                      },
                      parentElement: undefined,
                    })
                  );
                }}
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("CONTENT"))
                }
                // action={() =>
                //   initDataFormCreationContent(
                //     "item",
                //     selectedElement.element?.id
                //   )
                // }
                // disabled={
                //   !selectedElement.element ||
                //   (selectedElement.element as Content).subcontent_type !==
                //     "task"
                // }
              />
            </>
          ) : null}

          {selectedElement.elementType !== ElementTypes.CONTENT ? (
            <>
              <ContextualMenuListItem
                text="Avaliação"
                icon={<Avaliacao />}
                action={initDataFormCreationAssessment}
                redirectUrl="/editor/create/assessment"
              />
              <ContextualMenuListItem
                text="Exame"
                icon={<Exame />}
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.ASSESSMENT)
                  )
                }
              />
              <ContextualMenuListItem
                text="Novo Exame"
                action={initDataFormCreationExam}
                redirectUrl="/editor/create/exam"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.ASSESSMENT)
                  )
                }
              />
              <ContextualMenuListItem
                text="Carregar Exame"
                action={initDataFormCreationExam}
                redirectUrl="/editor/load/exam"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.ASSESSMENT)
                  )
                }
              />
              <ContextualMenuListItem
                text="Tarefa"
                icon={<Tarefa />}
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.EXAM)
                  )
                }
              />
              <ContextualMenuListItem
                text="Nova Tarefa"
                action={initDataFormCreationTask}
                redirectUrl="/editor/create/task"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.EXAM)
                  )
                }
              />
              <ContextualMenuListItem
                text="Carregar Tarefa"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.EXAM)
                  )
                }
                redirectUrl="/editor/load/task"
              />

              <ContextualMenuListItem
                text="Item"
                icon={<Item />}
                action={initDataFormCreationItem}
                redirectUrl="/editor/create/item"
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("TASK"))
                }
              />

              <ContextualMenuListItem
                text="Sub-Enunciado"
                icon={<Subenunciado />}
                action={initDataFormCreationSubcontent}
                redirectUrl="/editor/create/subcontent"
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("TASK"))
                }
              />
            </>
          ) : null}
        </Menu>
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={false}
          svgImage={<Duplicate_DISABLED />}
          toolTipText="Duplicar Elemento"
        />
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={enableOrderButtom(selectedElement)}
          svgImage={
            enableOrderButtom(selectedElement) ? <Up_ENABLED /> : <Up />
          }
          toolTipText="Mover Elemento"
          redirectPath="/editor"
          action={() => moveSelectedElement("UP")}
        />
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={enableOrderButtom(selectedElement)}
          svgImage={
            enableOrderButtom(selectedElement) ? <Down_ENABLED /> : <Down />
          }
          toolTipText="Mover Elemento"
          redirectPath="/editor"
          action={() => moveSelectedElement("DOWN")}
        />
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={hasSelectedElement()}
          redirectPath={"/editor/"}
          action={onClickDelete}
          svgImage={<Lixeira />}
          toolTipText="Remover Elemento"
        />
      </Grid>
    </Grid>
  );
}

function enableOrderButtom(selectedElement): boolean {
  return (
    selectedElement.element !== undefined &&
    selectedElement.element.id !== undefined &&
    selectedElement.elementType === ElementTypes.TASK
  );
}
