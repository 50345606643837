import DeleteDialog from "components/Admin/dialogComponents/DeleteDialog";
import CardBindItemToTeacher from "components/Admin/forms/CardBindItemToTeacher";
import CardBindTranscriberToTeacher from "components/Admin/forms/CardBindTranscibertToItem";
import CardExamRegister from "components/Admin/forms/CardExamRegister";
import CardItemRegister from "components/Admin/forms/CardItemRegister";
import CardRegisterAbility from "components/Admin/forms/CardRegisterAbility";
import CardAssessmentRegister from "components/Admin/forms/CardRegisterAssessment";
import CardRegisterKnowledge from "components/Admin/forms/CardRegisterKnowledge";
import CardTaskRegister from "components/Admin/forms/CardTaskRegister";
import CardTaskSubcontent from "components/Admin/forms/CardTaskSubConntent";
import { PrintAvaluation } from "pages/PrintAvaluation";
import { AbilityRelationship } from "pages/Records/AbilityRelationship";
import { KnowledgeArea } from "pages/Records/KnowladgeArea";
import Relatorio from "pages/Relatorio";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { findExams } from "store/reducers/Admin/AsyncActions/Exam";
import { getTasks } from "store/reducers/Admin/AsyncActions/Tasks";
import { findAssessment } from "store/reducers/Admin/AsyncActions/assessment";
import { fetchContent } from "store/reducers/Admin/AsyncActions/content";
import Footer from "./Footer";
import { MenuIds } from "./Header";
import Sidebar from "./SideBar";
import { Box } from "@mui/material";
import CardRegisterAula from "components/Admin/forms/CardRegisterAula";
import CardRegisterAulaSubContent from "components/Admin/forms/CardRegisterAulaSubContent";
import EditorModal from "components/Admin/editor/EditorModal";
import { CardLoadTask } from "components/Admin/forms/CardLoadTask";
import { CardLoadContent } from "components/Admin/forms/CardLoadContent";
import CardRegisterTaskContent from "components/Admin/forms/CardRegisterTaskContent";
import CardLoadExam from "components/Admin/forms/CardLoadExam/CardLoadExam";

const EditorContent = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  let location = useLocation();
  console.log(location);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTasks());
    dispatch(findExams());
    dispatch(findAssessment());
    dispatch(fetchContent());
  }, [dispatch]);

  return (
    <>
      <Box className="admin-full-screen" sx={{ height: "90vh" }}>
        <Sidebar onClickDelete={() => setOpenDeleteDialog(true)} />
        <Box className="edit-admin-content">
          <Switch>
            <Route path="/editor/create/item" component={CardItemRegister} />
            <Route
              path="/editor/create/subcontent"
              component={CardTaskSubcontent}
            />
            <Route
              path="/editor/create/task/:id_exam/:number_order"
              component={CardTaskRegister}
            />

            <Route path="/editor/create/task" component={CardTaskRegister} />
            <Route path="/editor/load/task" component={CardLoadTask} />
            <Route path="/editor/load/content/" component={CardLoadContent} />
            <Route path="/editor/create/exam" component={CardExamRegister} />
            <Route
              path="/editor/create/assessment"
              component={CardAssessmentRegister}
            />
            <Route
              path="/editor/create/class/content"
              component={CardRegisterAula}
            />
            <Route
              path="/editor/create/class/subcontent"
              component={CardRegisterAulaSubContent}
            />
            <Route
              path="/editor/create/class/task"
              component={CardRegisterTaskContent}
            />

            <Route path="/editor/load/exam" component={CardLoadExam} />
          </Switch>

          <DeleteDialog
            open={openDeleteDialog}
            closeModalAction={() => setOpenDeleteDialog(false)}
          />

          <EditorModal />
        </Box>
      </Box>
    </>
  );
};

export default EditorContent;
