import { KnowledgeArea, KnowledgeAreaPagination } from "interfaces";
import api from "services/api";
export const LIMIT = 5;
const BASE_URL = "/matrices/knowledge_areas/";
export default class KnowledegeAreasService {
  public static async getAllKnowledgeAreaWithPagination(page: number) {
    return api
      .get<KnowledgeAreaPagination>(BASE_URL, {
        params: {
          limit: LIMIT,
          offset: page * LIMIT,
        },
      })

      .then(({ data }) => {
        return data;
      });
  }
  public static async getAllKnowledgeArea() {
    return api.get<KnowledgeAreaPagination>(BASE_URL).then(({ data }) => {
      return data;
    });
  }
  public static async saveKnowledgeArea(dataToSave: KnowledgeArea) {
    return api.post(BASE_URL, dataToSave);
  }
  public static async editKnowledgeArea({
    id,
    ...dataToUpdate
  }: KnowledgeArea) {
    return api.patch(`${BASE_URL}${id}/`, dataToUpdate);
  }
  public static async deleteKnowledgeArea(id: number) {
    return api.delete(`${BASE_URL}${id}/`);
  }
}
