import { Box, SvgIcon, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface ITreeItemLabel {
  icon: any;
  element?: any;
  elemenLabel: any;
  redirectLink: string;
  proccesClickFunction?: any;
}

export default function TreeItemLabel({
  icon,
  element,
  elemenLabel,
  redirectLink,
  proccesClickFunction,
}: ITreeItemLabel) {
  return (
    <Link
      to={redirectLink}
      className="label-tree-nav"
      onClick={(e) => {
        if (proccesClickFunction) proccesClickFunction(element);
      }}
    >
      <Box
        className="content-label-tree-nav"
        display="flex"
        flexDirection="row"
      >
        {icon !== undefined ? (
          <Box className="content-label-tree-nav-icon">
            <SvgIcon className="icon-tree-view-menu" viewBox="0 0 23 23">
              {icon}
            </SvgIcon>
          </Box>
        ) : (
          <></>
        )}
        <Box className="content-label-tree-nav-text" pl={1}>
          <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
            {elemenLabel}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
}
