import api from "services/api";

import { IAuthProps, IRegisterProps } from "./types";
export default class AuthenticationServices {
  public static async authenticationUser(props: IAuthProps) {
    const formData = new FormData();
    formData.append("username", props.email);
    formData.append("password", props.password);
    formData.append("grant_type", "password");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID as string);
    formData.append(
      "client_secret",
      process.env.REACT_APP_CLIENT_SECRET as string
    );

    return await api.post("/oauth2/o/token/", formData);
  }

  public static async register(props: IRegisterProps) {
    return api.post("oauth2/register/", { ...props });
  }

  public static async fetchUserData() {
    return api.get("/oauth2/users/me/");
  }

  public static async fetchUsers() {
    return api.get("/oauth2/users/");
  }

  public static async fetchUsersByRole(role: string) {
    return api.get("/oauth2/users/?profile__type=" + role);
  }

  public static async changePassword(data: {
    current_password: string;
    new_password: string;
  }) {
    return api.post("oauth2/users/change_password/", data);
  }
}
