import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { login, saveUserdata } from "store/reducers/Authentication/Actions";
import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { ProfileMenu } from "components/ProfileMenu";

import "./styles.css";

export function ButtonAccountMenu() {
  const dispath = useDispatch();
  const userDataRedux = useSelector(
    (state: any) => state.authentication
  ).userData;

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    let dataUserSession: any = sessionStorage.getItem("@App:U");

    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispath(login({ ...appSession } as ILoginProps));
    }

    if (dataUserSession) {
      dataUserSession = JSON.parse(window.atob(dataUserSession));
      dispath(saveUserdata(dataUserSession as IRegisterProps));
    }
  }, [
    dispath,
    sessionStorage.getItem("@App:S"),
    sessionStorage.getItem("@App:U"),
  ]);

  return (
    <>
      <ProfileMenu
        first_name={userDataRedux.first_name}
        last_name={userDataRedux.last_name}
      />
    </>
  );
}
