import Tooltip from '@mui/material/Tooltip';

import { returnSizeSliceAndOffset } from './utils'

import './styles.css'

interface IGraficoCircular {
    valueTotal: number;
    titleChart: string;
    positionElementsLegend: 'first_value' | 'first_title';
    data: {
        color: string;
        title: string;
        value: number; 
        valueLegend: string;
    }[];
    titleListLegends?: string;
    pieChartElementCenterTitle?: string;
    pieChartElementCenterSubTitle?: string;
};

export function GraficoCircular({
    data,
    valueTotal,
    titleChart,
    positionElementsLegend = 'first_value',
    titleListLegends,
    pieChartElementCenterTitle,
    pieChartElementCenterSubTitle,
}: IGraficoCircular) {
    let offset_store = 0

    function returnZIndex(posicao_item: number, qtd_items: number, posicao_slice: number, qtd_slices: number) {
        if (posicao_item === 0 && posicao_slice === 0 && qtd_slices > 1) {
            return 2
        }

        if (
            (posicao_item + 1 === qtd_items && posicao_slice + 1 === qtd_slices)
            ||
            (qtd_slices <= 1 || posicao_slice === 0)
        ) {
            return 1
        }

        return 0
    }

    return (
        <div className="pie-chart-wrapper">
            <div className="pie-chart-details">
                <h2>{titleChart}</h2>
                
                <span>{titleListLegends}</span>

                <ul className="pie-chart-legend">
                    {
                        data.map(item => (
                            <li
                                style={{
                                    borderColor: item.color,
                                    flexDirection: positionElementsLegend === 'first_title' ? 'row-reverse' : 'row',
                                    justifyContent: positionElementsLegend === 'first_title' ? 'flex-end' : 'flex-start',
                                }}
                                key={item.title}
                            >
                                <span>{item.valueLegend}</span>
                                {item.title}
                            </li>
                        ))
                    }
                </ul>
            </div>

            <div className="pie-chart">
                {
                    data.map((item, index_data) => {
                        if (item.value > 0) {
                            const { response, offset } = returnSizeSliceAndOffset(item.value, offset_store, valueTotal);

                            offset_store = offset;

                            return (
                                <>
                                    {
                                        response.map((response_item, index_response) => (
                                            <Tooltip title={<h6>{`${item.valueLegend} ${item.title}`}</h6>} placement="top" arrow>
                                                <div
                                                    key={response_item.get_offset}
                                                    className="slice"
                                                    style={{
                                                        zIndex: returnZIndex(index_data, data.length, index_response, response.length),
                                                        transform: `rotate(${response_item.get_offset}deg) translate3d(0,0,0)`
                                                    }}
                                                >
                                                    <span style={{ transform: `rotate(${response_item.get_rotation_slice}deg) translate3d(0,0,0)`, backgroundColor: item.color }}/>
                                                </div>
                                            </Tooltip>
                                        ))
                                    }
                                </>
                            )
                        } else {
                            return ( <></> )
                        }
                    })
                }
                
                {
                    pieChartElementCenterTitle || pieChartElementCenterSubTitle ?
                        <div className="pie-chart-center">
                            <span className="pie-chart-center-title">{pieChartElementCenterTitle}</span>
                            <span className="pie-chart-center-subtitle">{pieChartElementCenterSubTitle}</span>
                        </div>
                    : <></>
                }
            </div>
        </div>
    )
}