import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { InputMarkdown } from "components/InputMarkdown";
import TransferList from "components/TransferList";
import {
  Atividade,
  Audio,
  Carrossel,
  CodeHighlighter,
  Dica,
  Equacao,
  Explanation,
  MarkdownRender,
  Observacao,
  T1,
  T2,
  T3,
  T4,
  T5,
  Video,
} from "lib_editor";
import "lib_editor/dist/style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Choice,
  ElementTypes,
  Item,
  ItemFormData,
  SelectedElement,
  Task,
} from "services/admin/Interfaces/Types";
import api from "services/api";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processItemUpdate,
  registerItem,
} from "store/reducers/Admin/AsyncActions/Itens";
import { EditorModalWithoutRedux } from "../editor/EditorModalWithoutRedux";
import { getTaskById } from "../utils/elementGetters";
import { validateFormData } from "../validators/ItemValidator";
import CardBase from "./CardBase";
import "./styles.css";

const modal_style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60% ",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 3,
};

const components = {
  ex: Explanation,
  dica: Dica,
  obs: Observacao,
  au: Audio,
  atv: Atividade,
  video: Video,
  equacao: Equacao,
  code: CodeHighlighter,
  carrossel: Carrossel,
  h1: T1,
  h2: T2,
  h3: T3,
  h4: T4,
  h5: T5,
};

export default function CardItemRegister() {
  const history = useHistory();

  // Choices
  const [isOpenEditorForAlternatives, setIsOpenEditorForAlternatives] =
    useState(false);
  const [choiceSelected, setChoiceSelected] = useState(-1);
  const [choicesWithRenderActivated, setChoicesWithRenderActivated] = useState<
    number[]
  >([]);

  const dispatch = useDispatch();
  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  const saveText: string | null = useSelector(
    (state: any) => state.admin.editorState.saveText
  );

  let dataForm: ItemFormData = {
    ...selectedElement.elementDataForm,
  } as ItemFormData;

  let tasks: Task[] = [...useSelector((state: any) => state.admin).tasks];
  let items: Item[] | undefined = getTaskById(
    tasks,
    dataForm.taskId as number
  )?.items;

  const receiveChoices = (): Choice[] => {
    let choices: Choice[] = [];

    try {
      for (let i = 0; i < dataForm.choices.length; i++) {
        let choice: Choice = { ...dataForm.choices[i] };
        delete choice["created_at"];
        choices.push(choice);
      }
      return choices;
    } catch (e) {
      return choices;
    }
  };

  dataForm.choices = receiveChoices();
  const handleDataForm = (key: string, value: unknown) => {
    let form = { ...dataForm };
    let sel = { ...selectedElement };
    form[key] = value;
    sel.elementDataForm = form;
    dispatch(setSelectedElement(sel));
  };

  const handleTypeChange = (value: "A" | "M" | "H") => {
    let form = { ...dataForm };
    let sel = { ...selectedElement };

    if (value === "H") {
      form["is_hybrid"] = true;
    } else {
      form["is_hybrid"] = false;
    }

    form["itemType"] = value;
    sel.elementDataForm = form;
    dispatch(setSelectedElement(sel));
  };

  const handleChoiceChange = (key: number, content: string) => {
    let choiceTemp = dataForm.choices;
    let ch = choiceTemp[key];

    ch.content = content;
    choiceTemp[key] = ch;
    handleDataForm("choices", choiceTemp);
  };

  const handleRadioChoiceChange = (correctItem) => {
    let choiceTemp = dataForm.choices;
    for (let i = 0; i < choiceTemp.length; i++) {
      if (i === correctItem) choiceTemp[i].correct = true;
      else choiceTemp[i].correct = false;
    }
    handleDataForm("choices", choiceTemp);
  };

  const mountChoicesRadio = (mapa: Choice[]) => (
    <div>
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr className="table-header">
            <th style={{ width: "30%" }}>Resposta Correta</th>
            <th style={{ width: "70%" }}>Opções de Respostas</th>
          </tr>
        </thead>
        <tbody>
          {mapa.map((choice, index) => {
            const letra = String.fromCharCode(65 + index); // Converte índice para letra

            return (
              <tr key={"content_item_choices_" + index}>
                <td style={{ textAlign: "left", padding: "8px", width: "30%" }}>
                  <Grid container alignItems="center" justifyContent="left">
                    <Grid item>
                      Alternativa {letra}{" "}
                      {/* Exibe a letra em vez do índice numérico */}
                    </Grid>
                    <Grid item key={"content_check_choices_" + index}>
                      <Radio
                        key={"input_Check_choices_" + index}
                        checked={choice.correct}
                        onChange={(e) => handleRadioChoiceChange(index)}
                        sx={{ transform: "scale(1.0)" }} // Ajusta o tamanho do Radio
                      />
                    </Grid>
                  </Grid>
                </td>
                <td
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    width: "70%",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid var(--neutral-3)",
                      borderRadius: 8,
                      padding: "0.6rem 0.4rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: 6,
                      alignItems: "flex-end",
                    }}
                  >
                    {choicesWithRenderActivated.includes(index) ? (
                      <div style={{ width: "100%" }}>
                        <MarkdownRender
                          fit
                          components={components}
                          markdownCode={choice.content}
                        />
                      </div>
                    ) : (
                      <TextField
                        fullWidth
                        key={"input_choice_" + index}
                        value={choice.content}
                        size="small"
                        placeholder={`Escreva a resposta da alternativa ${letra}`}
                        onChange={(e) =>
                          handleChoiceChange(index, e.target.value)
                        }
                        multiline
                        minRows={1}
                        maxRows={3}
                      />
                    )}

                    <div className="area_markdown_alternativa">
                      <div className="area_ativar_markdown">
                        <Switch
                          checked={choicesWithRenderActivated.includes(index)}
                          onChange={() => {
                            if (!choicesWithRenderActivated.includes(index)) {
                              setChoicesWithRenderActivated((prevState) => [
                                ...prevState,
                                index,
                              ]);
                            } else {
                              const newChoicesActivated: number[] = [];

                              for (const numberChoice of choicesWithRenderActivated) {
                                if (numberChoice !== index) {
                                  newChoicesActivated.push(numberChoice);
                                }
                              }

                              setChoicesWithRenderActivated(
                                newChoicesActivated
                              );
                            }
                          }}
                        />

                        <span>Markdown</span>
                      </div>

                      <Button
                        size="small"
                        startIcon={<OpenInNewIcon />}
                        variant="contained"
                        onClick={() => {
                          setIsOpenEditorForAlternatives(true);
                          setChoiceSelected(index);
                        }}
                      >
                        Abrir Editor Markdown
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const buildItem = (dataForm): Item => {
    let item: Item = {
      id: dataForm.id,
      title: dataForm.title,
      content: dataForm.description,
      task: dataForm.taskId,
      choices:
        dataForm.itemType === "M" || dataForm.is_hybrid === true
          ? dataForm.choices
          : [],
      number:
        dataForm.number == 0 || dataForm.number == null
          ? undefined
          : dataForm.number,
      is_hybrid: dataForm.is_hybrid,
      is_public: dataForm.is_public ?? false,
    };

    return item;
  };

  const [tabControl, setTabControl] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabControl(newValue + "");
  };

  const [habilities, setHabilities] = useState<any>({
    habilities: [],
    knowledges: [],
    selected_knowledge_id: 0,
    selected_habilities: [],
    selected_habilitie_id: 0,
  });

  const [knowledgeId, setKnowledgeId] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [habilities_list, setHabilities_list] = useState<any | any[]>(null);
  const [itemhabilities_list, setItem_Habilities_list] = useState<any | any[]>(
    null
  );
  const [selecedHabilities, setSelectedHabilities] = useState<any[]>([]);
  const [ability, setAbility] = useState<any[]>([]);
  const [ability_id, setAbilityId] = useState<any>(0);

  const [abilities_list, setAbilitiesList] = useState<any[]>([]);

  const [selectedKnowLedge, setselectedKnowLedge] = useState("");

  const getKnowledgeAreas = async () => {
    return await api.get("/matrices/knowledge_areas").then((res: any) => {
      setHabilities({ ...habilities, knowledges: res.data.results });
    });
  };

  const clearHabilities = () => {
    setItem_Habilities_list([]);
  };

  const getHabilities = async (val?: any) => {
    return await api
      .get("/matrices/knowledges", {
        params: {
          knowledge_area: val ? val : habilities.selected_knowledge_id,
        },
      })
      .then((res: any) => {
        setHabilities({ ...habilities, habilities_list: res.data.results });
        setHabilities_list(res.data.results);
      });
  };

  const getItemHabilities = async (val?: any) => {
    return await api
      .get("/matrices/abilities", {
        params: {
          knowledge: val ? val : habilities.selected_knowledge_id,
        },
      })
      .then((res: any) => {
        // setHabilities({ ...habilities, habilities_list: res.data.results })
        setItem_Habilities_list(res.data.results);
      });
  };

  const getItemAbilities = async () => {
    if (dataForm.id)
      await api
        .get("/matrices/abilities/?limit=1000&offset=0")
        .then((res: any) => {
          setAbilitiesList(res.data.results);
          return res.data.results;
        })
        .then(async (results: any) => {
          await api
            .get(`/items/items/${dataForm.id}`, {
              params: {
                abilities: 1,
              },
            })
            .then((res: any) => {
              // setSelectedHabilities(res.data.abilities)
              const ids = res.data.abilities.map((item) => item.ability);

              const abs = results.filter((item) => ids.includes(item.id));

              // Add ability_id property to each element in abs
              const absWithAbilityId = abs.map((item) => ({
                ...item,
                ability_id: res.data.abilities.find(
                  (a) => a.ability === item.id
                )?.id,
              }));

              setSelectedHabilities(absWithAbilityId);
            });
        });
  };

  const postItemAbilities = async (id: number | string) => {
    await api
      .post("/items/itemabilities/", {
        item: dataForm.id,
        ability: id,
      })
      .then(async () => {
        await getItemAbilities();
      });
  };

  const deleteItemAbility = async (id: number | string) => {
    await api.delete(`/items/itemabilities/${id}`).then(async () => {
      getItemAbilities();
    });
  };

  useEffect(() => {
    getKnowledgeAreas();
    getItemAbilities();
  }, []);

  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  useEffect(() => {
    setShowBackdrop(true);
    Promise.allSettled([getKnowledgeAreas(), getItemAbilities()]).then(() => {
      setShowBackdrop(false);
    });
  }, [dataForm.id]);

  useEffect(() => {
    if (
      selectedElement.elementType !== ElementTypes.ITEM &&
      selectedElement.elementType !== ElementTypes.CONTENT
    ) {
      history.push("/editor");
    }
  }, []);

  //TODO: adicionar validação para habilitar showActionButtons na aba de item
  return (
    <CardBase
      title={dataForm.id ? "Atualizar Item" : "Inserir Novo Item"}
      actionRegister={() => registerItem(buildItem(dataForm), selectedElement)}
      actionUpdate={() =>
        processItemUpdate(buildItem(dataForm), selectedElement)
      }
      validateMethod={() =>
        validateFormData(dataForm, items as Item[], selectedElement.elementType)
      }
      dataForm={dataForm}
      postSubmitRedirectUrl="/editor"
      showActionButtons={tabControl === "1" ? true : false}
      editorContent={true}
    >
      <Backdrop
        open={showBackdrop}
        sx={{
          zIndex: "999999",
        }}
      >
        <CircularProgress />
      </Backdrop>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Box display="flex" columnGap={2}>
          <Box flex={1}>
            <InputLabel className="title-form-item">
              Título do Item*:
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                value={dataForm.title}
                onChange={(e) => handleDataForm("title", e.target.value)}
                sx={{ background: "white" }}
                required
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box>
            <InputLabel className="title-form-item">Público</InputLabel>
            <FormControl>
              <Switch
                value={dataForm.is_public}
                defaultChecked={dataForm.is_public ?? false}
                onChange={(e) => handleDataForm("is_public", e.target.checked)}
              />
            </FormControl>
          </Box>
        </Box>
        <Box>
          <InputMarkdown
            required
            label="Descrição do item"
            textValue={dataForm.description}
            callBackValue={(e) => handleDataForm("description", e)}
          />
        </Box>

        {selectedElement.elementType !== ElementTypes.CONTENT ? (
          <>
            <InputLabel id="ordem" className="title-form-item">
              Ordem:
            </InputLabel>
            <FormControl>
              <TextField
                type={"number"}
                value={dataForm.number}
                onChange={(e) => handleDataForm("number", e.target.value)}
                sx={{ background: "white" }}
                required
                variant="outlined"
              />
            </FormControl>
          </>
        ) : null}
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <InputLabel className="title-form-item">Tipo</InputLabel>
        <FormControl fullWidth>
          <Select
            id="simple-select"
            value={dataForm.itemType}
            sx={{ background: "white" }}
            onChange={(e) => {
              handleTypeChange(e.target.value as "A" | "M" | "H");
            }}
          >
            <MenuItem value={"A"}>Questão Subjetiva</MenuItem>
            <MenuItem value={"M"}>Questão Objetiva - Escolha Única</MenuItem>
            <MenuItem value={"H"}>
              Questão Híbrida - Escolha Única mais Justificativa
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      {dataForm.itemType === "M" || dataForm.is_hybrid === true ? (
        <Box width="100%" pt={2}>
          {mountChoicesRadio(dataForm.choices)}
        </Box>
      ) : (
        <></>
      )}

      {dataForm.id ? (
        <Box p={0}>
          <TransferList
            filterButton={handleOpen}
            filterButtonClear={clearHabilities}
            skills={itemhabilities_list}
            setSkills={setItem_Habilities_list}
            selectedSkills={selecedHabilities}
            setSelectedSkills={setSelectedHabilities}
            setItemAbilities={postItemAbilities}
            deleteItemAbility={deleteItemAbility}
          />
        </Box>
      ) : (
        <></>
      )}

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            width: "60%",
            padding: "32px",
            top: "100px",
            left: "100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Box sx={modal_style}>
            <Grid container>
              <Grid item md={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant={"h5"}
                    component={"h5"}
                    style={{ fontSize: "18px", fontWeight: 600 }}
                  >
                    Filtrar Habilidades
                  </Typography>
                  <Button endIcon></Button>

                  <IconButton aria-label="delete" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <InputLabel
                    id={"demo-simple-select-label"}
                    className="title-form-item"
                  >
                    Área do conhecimento
                  </InputLabel>

                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={habilities.selected_knowledge_id}
                      onChange={async (e: any) => {
                        // alert(e.target.value);
                        await getHabilities(e.target.value).then(() => {
                          setHabilities({
                            ...habilities,
                            selected_knowledge_id: e.target.value,
                          });
                        });
                      }}
                    >
                      {habilities &&
                        habilities.knowledges &&
                        habilities.knowledges.map((item: any) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>

                  <InputLabel
                    id={"demo-simple-select-label"}
                    className="title-form-item"
                  >
                    Conhecimento
                  </InputLabel>

                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedKnowLedge}
                      onChange={(e) => {
                        setselectedKnowLedge(e.target.value.toString());
                        // var h = habilities_list.filter((h) => h.id === e.target.value)
                        // setSelectedHabilities(selecedHabilities.concat(h))
                      }}
                    >
                      {habilities_list &&
                        habilities_list.map((item: any) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={(e) => {
                        setKnowledgeId(selectedKnowLedge);
                        getItemHabilities(selectedKnowLedge);
                        handleClose();
                      }}
                    >
                      Filtrar
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>

      <EditorModalWithoutRedux
        isOpen={isOpenEditorForAlternatives}
        setIsOpen={setIsOpenEditorForAlternatives}
        setValue={(value) => {
          handleChoiceChange(choiceSelected, value);
        }}
        value={
          choiceSelected !== -1 ? dataForm.choices[choiceSelected].content : ""
        }
      />
    </CardBase>
  );
}
