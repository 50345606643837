export function MessageEmpty() {
  return (
    <div
      style={{
        marginBottom: 4,
        backgroundColor: "#f6f6f6",
        borderRadius: 4,
        padding: 10,
        textAlign: "center",
      }}
    >
      Nenhum resultado correspondente...
    </div>
  );
}
