import { Item } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class ItensServices {
  public static async registerItem(props: Item) {
    return api.post("/items/items/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async updateItem(props: Item) {
    return api.patch("/items/items/" + props.id + "/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async deleteItem(props: Item) {
    return api.delete("/items/items/" + props.id + "/");
  }
}
