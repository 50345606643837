import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Divider } from "@mui/material";

import { Header } from "./header";
import { SearchArea } from "./search-area";
import { SelectContent } from "./select-content";
import { useSelects } from "./use-selects";

import ContentServices, { IGetListByFilter } from "store/reducers/Admin/AsyncActions/content/contents.service";
import { fetchContent } from "store/reducers/Admin/AsyncActions/content";

import { Filters, Option } from "./types";
import { ElementTypes, SelectedElement } from "services/admin/Interfaces/Types";

import "./index.scss";

export function CardLoadContent() {
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;

  const subContentSelectedElement = selectedElement.element ? selectedElement.element!.subcontents.map(item => (item.id)) : []

  const { ability, knowledge, knowledgeArea } = useSelects();

  const [filters, setFilter] = React.useState<Filters>({});
  const [contentList, setContentsList] = React.useState<Option[]>([]);
  const [loadingContent, setLoadingContents] = React.useState(false);

  function loadContents() {
    setLoadingContents(true);

    setContentsList([]);

    const getFilters: IGetListByFilter = {
      title: filters.title,
      text: filters.text,
      ability: filters.ability,
      knowledge: filters.knowledge,
      knowledge_area: filters['knowledge-area'],
    }

    ContentServices.getListByFilter(getFilters).then(({ data }) => {
      let filtered = data

      if (selectedElement && selectedElement.element && selectedElement.element.id) {
        const parent_id = selectedElement.element.id

        filtered = filtered.filter(itemContent => itemContent.id !== parent_id && !subContentSelectedElement.includes(itemContent.id))
      }

      const tasks = filtered.map<Option>((content) => ({
        id: content.id!,
        name: content.title ?? '-',
      }));
      setContentsList(tasks);
      setLoadingContents(false);
    });
  }

  function onSave(object: number) {
    if (!selectedElement?.element || !selectedElement?.element.id) {
      toast.info('Necessário selecionar a aula')

      return
    }

    if (selectedElement.element.id === object) {
      toast.error('Este item não pode ser selecionado!')

      return
    }

    if (subContentSelectedElement.includes(object)) {
      toast.error('Este item já foi carregado!')

      return
    }

    setLoadingContents(true);

    ContentServices.toLoad({ object, parent: selectedElement.element.id }).then(() => {
      toast.success('Tarefa carregada com sucesso')

      dispatch(fetchContent());
    })
    .finally(() => setLoadingContents(false))
  }

  const actions = {
    onChange(value: string, name: string) {
      setFilter((currentState) => ({ ...currentState, [name]: value }));
    },
    onFilter() {
      loadContents();
    },
    onReset() {
      setFilter({});
      loadContents();
    },
  };

  useEffect(() => {
    if (selectedElement.elementType !== ElementTypes.CONTENT) {
      history.push('/')

      return
    }

    loadContents()
  }, [selectedElement])

  const selects = [knowledgeArea, knowledge, ability];

  return (
    <section className="card-load-task">
      <Header />
      <SearchArea
        filters={filters}
        loading={loadingContent}
        selects={selects}
        {...actions}
      />
      <Divider sx={{ mt: "1rem", ml: "2rem", mr: "2rem", mb: "1rem" }} />
      <SelectContent
        tasks={contentList}
        isLoading={loadingContent}
        onSave={onSave}
      />
    </section>
  );
}
