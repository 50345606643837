import { Choice, ElementTypes, Item, ItemFormData } from "services/admin/Interfaces/Types";

export const validateFormData = (
  form: ItemFormData,
  items: Item[],
  typeValidate?: ElementTypes
): string[] => {
  let erros: string[] = [];
  if (!hasItemTitle(form))
    erros.push("O item deve ter obrigatoriamente um titulo.");
  if (!hasItemContent(form))
    erros.push("O item deve ter obrigatoriamente uma descrição.");
  if (!hasItemChoicesContent(form))
    erros.push("Todas as opções devem ter obrigatoriamente a descrição.");
  if (!hasItemChoicesOneCorrectAlternative(form))
    erros.push("Deve haver Obrigatoriamente uma opção correta no item.");
  if (!hasItemNumber(form))
    erros.push("Deve-se indicar a ordem do item na tarefa.");
  if (isOrderNegative(form)) erros.push("Ordem do do item inválida.");
  
  if (typeValidate !== ElementTypes.CONTENT) {
    if (isItemNumberInUse(form, items))
      erros.push(
        "A ordem indicada para o item já está atribuida a outro item presente na tarefa."
      );
  }
  return erros;
};

function hasItemTitle(dataForm: ItemFormData): boolean {
  let hasTitle: boolean = (dataForm.title !== undefined &&
    dataForm.title &&
    dataForm.title !== "") as boolean;
  return hasTitle;
}

function hasItemContent(dataForm: ItemFormData): boolean {
  let hasContent = (dataForm.description !== undefined &&
    dataForm.description &&
    dataForm.description !== "") as boolean;
  return hasContent;
}

function hasItemChoicesContent(dataForm: ItemFormData): boolean {
  let result = true;
  if (dataForm.itemType === "M" || dataForm.is_hybrid === true) {
    for (let i = 0; i < dataForm.choices.length; i++) {
      let choice: Choice = dataForm.choices[i];

      if (
        choice.content === undefined ||
        !choice.content ||
        choice.content === ""
      ) {
        result = false;
        break;
      }
    }
  }
  return result;
}

function hasItemChoicesOneCorrectAlternative(dataForm: ItemFormData): boolean {
  if (dataForm.itemType === "M" || dataForm.is_hybrid === true) {
    let result = false;
    for (let i = 0; i < dataForm.choices.length; i++) {
      let choice: Choice = dataForm.choices[i];
      if (choice.correct) {
        result = true;
        break;
      }
    }
    return result;
  } else {
    return true;
  }
}

function hasItemNumber(dataForm: ItemFormData): boolean {
  let hasNumber: boolean = false;
  if (dataForm.number !== undefined && dataForm.number !== 0) hasNumber = true;

  return hasNumber;
}

function isItemNumberInUse(dataForm: ItemFormData, items: Item[]): boolean {
  let numberAlreadyInUse: boolean = false;
  let itemsFiltered: Item[] | undefined = items.filter((i) => {
    return i.id !== dataForm.id && Number(i.number) === Number(dataForm.number);
  });

  if (itemsFiltered !== undefined && itemsFiltered.length > 0)
    numberAlreadyInUse = true;

  return numberAlreadyInUse;
}

function isOrderNegative(dataForm: ItemFormData): boolean {
  let zeroOrnegative: boolean = false;
  if ((dataForm.number as number) < 1) zeroOrnegative = true;

  return zeroOrnegative;
}
