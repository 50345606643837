import { useState } from "react";

import { ArrowDropUp } from "@mui/icons-material";

import "./styles.css";
import api from "services/api";
import { Loading } from "components/Loading";
import { MessageEmpty } from "components/MessageEmpty";
import { quantityAndPercentage } from "../utils/quantityAndPercentage";
import { IClass, ItemTurma } from "./ItemTurma";
import { LineGraph } from "./LineGraph";

export interface ITabelaRespostasEscola {
  idAvaliacao: string;
  school_id: number;
  school_name: string;
  evaluations: {
    total: number;
    total_percentage: number;
    rubrics: {
      id: number;
      name: string;
      percentage: number;
      total: number;
    }[];
  };
  answers_stats: {
    source_student: number;
    source_transcriber: number;
    source_transcriber_ocr: number;
    total: number;
  };
}

export function TabelaRespostasEscola({
  idAvaliacao,
  school_id,
  school_name,
  answers_stats,
  evaluations,
}: ITabelaRespostasEscola) {
  const {
    porcentagemInsuficiente: porcentagemInsuficienteEscola,
    porcentagemParcialmenteInsuficiente:
      porcentagemParcialmenteInsuficienteEscola,
    porcentagemSuficiente: porcentagemSuficienteEscola,
    porcentagemSemRubrica: porcentagemSemRubricaEscola,

    respostasEstudantes: respostasEstudantesEscola,
    porcentagemRespostasEstudantes: porcentagemRespostasEstudantesEscola,

    respostasTranscritor: respostasTranscritorEscola,
    porcentagemRespostasTranscritor: porcentagemRespostasTranscritorEscola,

    respostasOCR: respostasOCREscola,
    porcentagemRespostasOCR: porcentagemRespostasOCREscola,

    respostasNaoContabilizadas: respostasNaoContabilizadasEscola,
    porcentagemRespostasNaoContabilizadas:
      porcentagemRespostasNaoContabilizadasEscola,
  } = quantityAndPercentage({ answers_stats, evaluations });

  const [classes, setClasses] = useState([]);

  const [isLoadingClasses, setIsLoadingClasses] = useState(false);

  const [wasClicked, setWasClicked] = useState(false);

  async function getClasses() {
    if (wasClicked) return;

    setIsLoadingClasses(true);

    await api
      .get(
        `assessments/assessments/${idAvaliacao}/report/evaluations_school/?school_id=${school_id}`
      )
      .then(({ data }) => {
        setClasses(data);
        setWasClicked(true);
      })
      .catch(console.log)
      .finally(() => setIsLoadingClasses(false));
  }

  return (
    <details style={{ marginBottom: 20 }}>
      <summary className="first_level" onClick={getClasses}>
        <span className="identify_row">
          <ArrowDropUp htmlColor="#00A0EA" fontSize="large" />
          {school_name}
        </span>

        <LineGraph
          titlePrimary={String(evaluations?.total)}
          answersTotal={answers_stats?.total}
          itemsLeft={[
            {
              legend: `"Insuficiente": ${porcentagemInsuficienteEscola.toFixed(
                2
              )}%`,
              percentageSlice: `${porcentagemInsuficienteEscola}%`,
              backgroundColor: "#f1530f",
            },
            {
              legend: `"Parcialmente Insuficiente": ${porcentagemParcialmenteInsuficienteEscola.toFixed(
                2
              )}%`,
              percentageSlice: `${porcentagemParcialmenteInsuficienteEscola}%`,
              backgroundColor: "#f1c40f",
            },
            {
              legend: `"Suficiente": ${porcentagemSuficienteEscola.toFixed(
                2
              )}%`,
              percentageSlice: `${porcentagemSuficienteEscola}%`,
              backgroundColor: "#00e676",
            },
            {
              legend: `Sem Rubrica: ${porcentagemSemRubricaEscola.toFixed(2)}%`,
              percentageSlice: `${porcentagemSemRubricaEscola}%`,
              backgroundColor: "#333",
            },
          ]}
          itemsRigth={[
            {
              legend: `Estudantes: ${respostasEstudantesEscola}`,
              percentageSlice: `${porcentagemRespostasEstudantesEscola}%`,
              backgroundColor: "#47b9ee",
            },
            {
              legend: `Transcritores: ${respostasTranscritorEscola}`,
              percentageSlice: `${porcentagemRespostasTranscritorEscola}%`,
              backgroundColor: "#00a0ea",
            },
            {
              legend: `OCR: ${respostasOCREscola}`,
              percentageSlice: `${porcentagemRespostasOCREscola}%`,
              backgroundColor: "#0072b9",
            },
            {
              legend: `Não Contabilizado: ${respostasNaoContabilizadasEscola}`,
              percentageSlice: `${porcentagemRespostasNaoContabilizadasEscola}%`,
              backgroundColor: "#333",
            },
          ]}
        />
      </summary>

      {/* Second Level */}
      {isLoadingClasses ? (
        <Loading />
      ) : (
        <>
          {classes.length <= 0 ? (
            <MessageEmpty />
          ) : (
            <>
              {classes.map((itemClass: IClass) => (
                <ItemTurma
                  idAvaliacao={idAvaliacao}
                  itemClass={{
                    ...itemClass,
                    answers_stats: {
                      ...itemClass.answers_stats,
                      source_student: 0,
                    },
                  }}
                />
              ))}
            </>
          )}
        </>
      )}
    </details>
  );
}
