import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material'

import './styles.css'

export interface IDialogDelete {
    textInfo?: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export function DialogDelete({ textInfo = "", isOpen, onClose, onConfirm }: IDialogDelete) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="align-elements-center">
                Confirmar Exclusão
            </DialogTitle>

            <DialogContent dividers>
                <Alert severity="warning">
                    <AlertTitle>Atenção</AlertTitle>
                    {
                        textInfo ? textInfo :
                        <>
                            Ao confirmar a exclusão <strong>não será possível recuperar</strong>.
                        </> 
                    }
                </Alert>
            </DialogContent>

            <DialogActions className="align-elements-center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                >
                    <strong>Confirmar</strong>
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                >
                    <strong>Cancelar</strong>
                </Button>
            </DialogActions>
        </Dialog>
    )
}