import { BrowserRouter } from "react-router-dom";

import Appbar from "./components/Appbar";

import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

import { Provider } from "react-redux";
import store from "store";

import Routes from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "@cenpe/auth";
import { useEffect } from "react";

import "./assets/globalStyles.css";
import Navbar from "components/Appbar/navbar";

export default function App() {
  const queryClient = new QueryClient();
  const { check } = useAuth.getState();

  function onRedirect() {
    sessionStorage.clear();
  }

  useEffect(() => {
    setInterval(() => {
      check({ withRedirectSSO: true, onRedirect });
    }, 1000);
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Appbar />
            <Routes />

            <ToastContainer
              position="top-right"
              theme="colored"
              autoClose={2000}
            />
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
}
