import { Content, Item } from "services/admin/Interfaces/Types";
import api from "services/api";

export interface IGetListByFilter {
  title: string;
  text: string;
  knowledge_area: string;
  knowledge: string;
  ability: string;
}

export default class ContentServices {
  public static async fetch(options: { limit?: number } = {}) {
    return api.get("items/contents/", { params: { ...options } });
  }

  public static async register(props: Content) {
    return api.post("/items/contents/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async toLoad(props: { parent: number; object: number }) {
    return api.post("/items/contents/", {
      ...props,
      subcontent_type: "subcontent",
    });
  }

  public static async update(props: Content) {
    return api.patch(
      "items/contents/" + props.id + "/",
      JSON.stringify(props),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  public static async delete(props: Content) {
    return api.delete("/items/contents/" + props.id + "/");
  }

  public static async getListByFilter(params: IGetListByFilter) {
    return await api.get<Content[] | never>("/items/contents/", { params });
  }
}
