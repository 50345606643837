import { FormControl, InputLabel, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  Item,
  SelectedElement,
  /*   EditorModalState, */
  Task,
  TaskFormData,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processTaskUpdate,
  registerTaskWithExam,
} from "store/reducers/Admin/AsyncActions/Tasks";

//import { openMarkdown } from "../utils/newMarkdownUtils";

import { validateFormData } from "../validators/taskValidator";
import CardBase from "./CardBase";
import { InputMarkdown } from "components/InputMarkdown";

export default function CardTaskRegister() {
  const dispatch = useDispatch();

  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  /*   let editorState: EditorModalState = {
    ...useSelector((state: any) => state.admin).editorState
  } */

  let dataForm: TaskFormData = {
    ...selectedElement.elementDataForm,
  } as TaskFormData;

  const handleDataForm = (key: string, value: unknown) => {
    let form = { ...dataForm };
    let sel = { ...selectedElement };
    form[key] = value;

    sel.elementDataForm = form;
    dispatch(setSelectedElement(sel));
  };

  const buildTaskFromForm = () => {
    let task: Task = {
      id: dataForm.id,
      content: dataForm.description,
      title: dataForm.title,
      items: dataForm.items as Item[],
      is_public: dataForm.is_public,
    };
    return task;
  };

  return (
    <CardBase
      title={dataForm.id ? "Tarefa" : "Inserir Nova Tarefa"}
      actionRegister={() =>
        registerTaskWithExam(
          buildTaskFromForm(),
          selectedElement.parentElement as number
        )
      }
      actionUpdate={() => processTaskUpdate(buildTaskFromForm())}
      validateMethod={() => validateFormData(dataForm)}
      postSubmitRedirectUrl="/editor"
      dataForm={dataForm}
      editorContent={true}
    >
      <Box display="flex" columnGap={2} alignItems="flex-end">
        <FormControl fullWidth style={{ flex: 1 }}>
          <TextField
            label="Titulo"
            placeholder="Digite o título da tarefa"
            size="medium"
            required
            value={dataForm.title}
            onChange={(e) => handleDataForm("title", e.target.value)}
            sx={{ background: "white" }}
          />
        </FormControl>
        <Box>
          <InputLabel className="title-form-item">Público</InputLabel>
          <FormControl>
            <Switch
              value={dataForm.is_public}
              defaultChecked={dataForm.is_public ?? false}
              onChange={(e) => handleDataForm("is_public", e.target.checked)}
            />
          </FormControl>
        </Box>
      </Box>

      <Box>
        <InputMarkdown
          required
          label="Descrição da tarefa"
          textValue={dataForm.description}
          callBackValue={(e) => handleDataForm("description", e)}
        />
      </Box>
    </CardBase>
  );
}
