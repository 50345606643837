import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/system";
import { quicksand_text_theme } from "components/Admin/Themes";
import AdminContent from "pages/Admin/sections/Content";
// import Footer from "pages/Admin/sections/Footer";
import Header from "pages/Admin/sections/Header";

import "./admin.css";
export default function Admin() {
  return (
    <Box>
      {/* <ThemeProvider theme={quicksand_text_theme}> 
      <Header />*/}
      <AdminContent />
      {/* <Footer /> */}
      {/* </ThemeProvider> */}
    </Box>
  );
}
