import AuthenticationServices from "./authentication.services";

import { login, saveUserdata } from "../Actions";

import { IAuthProps, IRegisterProps } from "./types";
import AlertService from "services/admin/AlertService";
import { toast } from "react-toastify";
import api from "services/api";
import { AuthData } from "@cenpe/auth";

const getEstructureDataUser = (
  token: string,
  refresh_token: string,
  idUser: number
) => {
  return {
    user: {
      signed: true,
      token: token,
      refresh_token: refresh_token,
      idUser,
    },
  };
};

async function loadUserData({ data, dispatch }: any) {
  api.defaults!.headers!.Authorization = `Bearer ${data.access_token}`;
  const valuesLogin = getEstructureDataUser(
    data.access_token,
    data.refresh_token,
    1
  );

  sessionStorage.setItem(
    "@App:S",
    window.btoa(JSON.stringify({ ...valuesLogin }))
  );

  // Armazena o refresh token
  sessionStorage.setItem("@App:R", data.refresh_token);

  await AuthenticationServices.fetchUserData().then((response: any) => {
    const valuesLogin = getEstructureDataUser(
      data.access_token,
      data.refresh_token,
      response.data.id
    );

    sessionStorage.setItem("@App:L", JSON.stringify(response.data));
    dispatch(login(valuesLogin));
    dispatch(fetchUserData());
  });
}

export const auth = (props: AuthData) => {
  return (dispatch: any) => {
    loadUserData({ data: props, dispatch });
    /**AuthenticationServices.authenticationUser(props).then(({ data }: any) => {
      const valuesLogin = {
        user: {
          signed: true,
          token: data.access_token,
        },
      };

      sessionStorage.setItem(
        "@App:S",
        window.btoa(JSON.stringify({ ...valuesLogin }))
      );
      dispatch(login(valuesLogin));
      dispatch(fetchUserData());
    }).catch((error: any) => {
      AlertService.showAlert({ message: "Usuário ou senha estão incorretos!", type: "erro" })
    });*/
  };
};

export const fetchUserData = () => {
  return (dispatch: any) => {
    AuthenticationServices.fetchUserData().then(({ data }: any) => {
      sessionStorage.setItem(
        "@App:userData",
        window.btoa(JSON.stringify({ ...data }))
      );
      dispatch(saveUserdata(data as IRegisterProps));
    });
  };
};

export const register = (props: IRegisterProps) => {
  return () => {
    AuthenticationServices.register(props)
      .then(() => {
        return {
          success: 1,
        };
      })
      .catch(() => {
        return {
          success: 0,
        };
      });
  };
};
