import { createReducer } from "@reduxjs/toolkit";

import { login, logout, saveUserdata } from "./Actions";

const INITIAL_VALUES = {
  user: {
    signed: false,
    token: "",
  },

  userData: {},
};

export default createReducer(INITIAL_VALUES, {
  [login.type]: (state, action) => {
    state.user = action.payload.user;
  },
  [saveUserdata.type]: (state, action) => {
    state.userData = action.payload;
  },
  [logout.type]: () => ({ ...INITIAL_VALUES }),
});
