import React from "react";

// Importando as rotas
import SignRoutes from "./SignRoutes";
import OuterRoutes from "./OuterRoutes";

import { useSelector } from "react-redux";

const Routes: React.FC = () => {
  const { user } = useSelector((state: any) => state.authentication);

  return user.signed ? <OuterRoutes /> : <SignRoutes />;
};
export default Routes;
