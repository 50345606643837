import { TaskSubContent } from "services/admin/Interfaces/Types";
import { resetClickedElements } from "store/reducers/Admin/Actions/treeView/actions";
import { getTasks } from "store/reducers/Admin/AsyncActions/Tasks";
import { FETCH_TASK_SUB_CONTENT } from "../actions";
import TaskSubContentService from "./taskSubcontent.services";

export const createNewSubContent = (subContent: TaskSubContent) => {
  return (dispatch) => {
    TaskSubContentService.create(subContent).then((response) => {
      dispatch(getTasks());
    });
  };
};

export const updateSubContent = (subContent: TaskSubContent) => {
  return (dispatch) => {
    TaskSubContentService.update(subContent).then((response) => {
      dispatch(getTasks());
    });
  };
};

export const removeSubContent = (
  subContent: TaskSubContent,
  successCallBack: any,
  errorCallBack: any
) => {
  return (dispatch) => {
    TaskSubContentService.delete(subContent.id as number)
      .then((response) => {
        dispatch(getTasks());
        dispatch(resetClickedElements());
        successCallBack();
      })
      .catch(() => errorCallBack());
  };
};

export const fetchSubContent = () => {
  return (dispatch) => {
    TaskSubContentService.find().then((response) => {
      dispatch(FETCH_TASK_SUB_CONTENT(response.data));
    });
  };
};
export const fetchSubContentById = (id: number) => {
  return (dispatch) => {
    TaskSubContentService.findByid(id).then((response) => {
      dispatch(FETCH_TASK_SUB_CONTENT(response.data));
    });
  };
};
