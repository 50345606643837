import { TextFields } from "@mui/icons-material";
import { Box, FormControl, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedElement,
  Task,
  TaskSubContent,
  TaskSubContentFormData,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  createNewSubContent,
  updateSubContent,
} from "store/reducers/task_subcontent/AsyncActions";
import { getTaskById } from "../utils/elementGetters";
//import openStackEdit from "../utils/markdownUtils";
import { validateFormData } from "../validators/taskSubcontentValidator";
import CardBase from "./CardBase";
import { Label } from "components/Label";
import { InputMarkdown } from "components/InputMarkdown";

export default function CardTaskSubcontent() {
  const dispatch = useDispatch();
  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  let dataForm: TaskSubContentFormData = {
    ...selectedElement.elementDataForm,
  } as TaskSubContentFormData;

  let tasks: Task[] = [...useSelector((state: any) => state.admin).tasks];
  let subs: TaskSubContent[] | undefined = getTaskById(
    tasks,
    dataForm.task as number
  )?.subcontents;

  const handleDataForm = (key: string, value: unknown) => {
    let form = { ...dataForm };
    let sel = { ...selectedElement };
    form[key] = value;
    sel.elementDataForm = form;
    dispatch(setSelectedElement(sel));
  };

  const buildSubContent = (data: TaskSubContentFormData): TaskSubContent => {
    return {
      id: data.id,
      content: data.content,
      position: data.position,
      task: data.task,
      title: data.title || "",
    };
  };

  return (
    <CardBase
      title={
        dataForm?.id === undefined ? "Inserir Subenunciado" : "Subenunciado"
      }
      actionRegister={() => createNewSubContent(buildSubContent(dataForm))}
      actionUpdate={() => updateSubContent(buildSubContent(dataForm))}
      validateMethod={() =>
        validateFormData(dataForm, subs as TaskSubContent[])
      }
      dataForm={dataForm}
      postSubmitRedirectUrl="/editor"
      editorContent={true}
      successButtonText="Inserir"
    >
      {/* <Box p={1}>
        <FormControl fullWidth>
          <Label
            htmlFor="task_subcontent_description"
            text="Conteúdo do Subenunciado"
            required
          />
          <TextField
            id="task_subcontent_description"
            value={dataForm.content}
            multiline
            rows={10}
            maxRows={7}
            onChange={(e) => handleDataForm("content", e.target.value)}
            sx={{ background: "white" }}
            onClick={(e) =>
              openStackEdit(
                "task_subcontent_description",
                "content",
                handleDataForm
              )
            }
            required
          />
        </FormControl>
      </Box> */}
      <Box>
        <FormControl fullWidth>
          <TextField
            label="Titulo"
            placeholder="Digite o título"
            size="medium"
            required
            value={dataForm.title}
            onChange={(e) => handleDataForm("title", e.target.value)}
            sx={{ background: "white" }}
          />
        </FormControl>
      </Box>
      <Box>
        <InputMarkdown
          required
          label="Descrição da tarefa"
          textValue={dataForm.content}
          callBackValue={(eventValue) => {
            if (eventValue) handleDataForm("content", eventValue);
          }}
        />
      </Box>
      <Box p={1}>
        <FormControl>
          <Label htmlFor="position" text="Posição" required />
          <TextField
            id="position"
            type={"number"}
            value={dataForm.position}
            onChange={(e) => handleDataForm("position", e.target.value)}
            sx={{ background: "white" }}
            required
          />
        </FormControl>
      </Box>
    </CardBase>
  );
}
