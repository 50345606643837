import './styles.css'

interface IBoxTotalRespostasSintetizadas {
    total: number;
}

export function BoxTotalRespostasSintetizadas({ total = 0 }: IBoxTotalRespostasSintetizadas) {
    return (
        <div className="box_total_respostas_sintetizadas_content">
            <h3>Total de Respostas</h3>

            <div>
                <h3>{total}</h3>
                <h4>Totais Sintetizados</h4>
            </div>
        </div>
    )
}