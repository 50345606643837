import { Button, Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function ErroDialog({ errorMsg, open, closeModalAction }) {
  return (
    <Dialog
      open={open}
      BackdropProps={{ invisible: true }}
      sx={{ marginLeft: "auto", marginRight: "auto", width: "28%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box p={1} sx={{ background: "#F3F7F9" }}>
          <Typography variant="h4" align="center" fontWeight="600">
            Operação não Realizada!
          </Typography>
        </Box>
        <Box
          p={1}
          sx={{
            background: "#EAF2F7",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" align="center">
            {errorMsg}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box p={2}>
              <Button onClick={(e) => closeModalAction()} variant="contained">
                {" "}
                Entendi
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
