export function orderArrayById(elementos, key: string = "id") {
  if (elementos.length > 0) {
    const orderedArray = [...elementos];

    orderedArray.sort((a, b) => (a[key] as number) - (b[key] as number));

    return orderedArray;
  } else {
    return elementos;
  }
}
