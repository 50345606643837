import { Task, TaskFilters } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class TaskServices {
  public static async registerTask(props: Task) {
    const task: Partial<Task> = {
      title: props.title,
      content: props.content,
      is_public: props.is_public ?? false,
    };

    return api.post("/items/tasks/", task);
  }

  public static async getAllTasks(payload?: TaskFilters) {
    return api.get<Task[] | never>("/items/tasks/", { params: payload });
  }

  public static async deleteTask(props: Task) {
    return api.delete("/items/tasks/" + props.id + "/");
  }

  public static async updateTask(props: Task) {
    const task: Partial<Task> = {
      title: props.title,
      content: props.content,
      is_public: props.is_public,
    };
    return api.put("/items/tasks/" + props.id + "/", task);
  }

  public static async findById(id: number) {
    return api.get("/items/tasks/" + id + "/");
  }
}
