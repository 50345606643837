import {
  Exam,
  Task,
  TaskExam,
  TaskFormData,
} from "services/admin/Interfaces/Types";

export const validateFormData = (form: TaskFormData): string[] => {
  var errors: string[] = [];
  if (!taskHasTitle(form))
    errors.push("Não é possivel cadastrar uma tarefa sem informar um título.");

  if (!taskHasContent(form))
    errors.push(
      "Não é possivel cadastrar uma tarefa sem informar uma descrição."
    );

  return errors;
};

function taskHasTitle(dataForm: TaskFormData): boolean {
  var hasTitle: boolean = (dataForm.title !== undefined &&
    dataForm.title &&
    dataForm.title !== "") as boolean;
  return hasTitle;
}

function taskHasContent(dataForm: TaskFormData): boolean {
  var hasContent: boolean = (dataForm.description !== undefined &&
    dataForm.description &&
    dataForm.description !== "") as boolean;
  return hasContent;
}

export function validateDelete(task: Task, exams: Exam[]): string | undefined {
  let errorMsg: string | undefined = undefined;
  for (const exam of exams) {
    const taskExam: TaskExam | undefined = exam.tasks.find(
      (examTask) => examTask.task === task.id
    );
    if (taskExam !== undefined) {
      errorMsg =
        "Não é possivel remover esta tarefa pois ela está vinculada a uma prova.";
      break;
    }
  }
  return errorMsg;
}
