import React from "react";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const Forbiden: React.FC = () => {
  const history = useHistory();

  return (
    <div className="container mt-5">
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <p style={{ marginTop: "24px", marginBottom: "24px" }}>
          Usuário não Autorizado
        </p>
        <Button
          onClick={() => {
            history.push("/");
            history.go(0);
            sessionStorage.clear();
          }}
        >
          Retornar à navegação
        </Button>
      </Box>
    </div>
  );
};

export default Forbiden;
