import { createReducer } from "@reduxjs/toolkit";
import { AbilityEdge, Page } from "services/admin/Interfaces/Types";
import {
  clearAbilityEdge,
  fetchAbilityEdge,
  registerAbilityEdge,
} from "./actions";

const DEFAULT_VALUE = {
  abilities: undefined,
};

export default createReducer(DEFAULT_VALUE, {
  [clearAbilityEdge.type]: (state) => DEFAULT_VALUE,
  [fetchAbilityEdge.type]: (state, action) => ({
    ...state,
    abilites_edge: action.payload,
  }),
});
