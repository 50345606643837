import React from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import { useDispatch } from "react-redux";
import { register } from "store/reducers/Authentication/AsyncActions";

import "./styles.css";

export default function Register() {
  const dispath = useDispatch();

  const [dataForm, setDataForm] = React.useState({
    agree_to_terms: false,
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    type: "student",
  });

  const handleDataForm = (position: string, value: unknown) => {
    setDataForm({ ...dataForm, [position]: value });
  };

  const onSubmit = () => {
    dispath(
      register({
        first_name: dataForm.first_name,
        last_name: dataForm.last_name,
        username: dataForm.username,
        email: dataForm.email,
        password: dataForm.password,
        type: dataForm.type as "student",
      })
    )
  };

  const onValidateForm = (e: React.FormEvent) => {
    e.preventDefault();

    if (!dataForm.first_name) {
      alert("Necessário preencher o campo de primeiro nome.");
      return;
    }

    if (!dataForm.last_name) {
      alert("Necessário preencher o campo de sobrenome.");
      return;
    }

    if (!dataForm.username) {
      alert("Necessário preencher o campo de nome de usuário.");
      return;
    }

    if (!dataForm.email) {
      alert("Necessário preencher o campo de e-mail.");
      return;
    }

    if (!dataForm.password) {
      alert("Necessário preencher o campo de senha.");
      return;
    }

    if (!dataForm.confirm_password) {
      alert("Necessário preencher o campo de confirmação de senha.");
      return;
    }

    if (dataForm.password !== dataForm.confirm_password) {
      alert("O campo de senha e confirmação estão diferentes.");
      return;
    }

    if (!dataForm.agree_to_terms) {
      alert("Necessário aceitar os termos de política de privacidade.");
      return;
    }

    onSubmit();
  };

  return (
    <Container maxWidth="sm" className="content">
      <Box>
        <Typography variant="h4">Cadastre-se</Typography>
        <Typography variant="subtitle2">
          Prencha o formulário para prosseguir
        </Typography>
      </Box>

      <form onSubmit={onValidateForm}>
        <TextField
          variant="outlined"
          fullWidth
          label="Primeiro Nome:"
          placeholder="Digite o seu primeiro nome..."
          required
          value={dataForm.first_name}
          onChange={(e) => handleDataForm("first_name", e.target.value)}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Sobrenome:"
          placeholder="Digite o seu sobrenome..."
          required
          value={dataForm.last_name}
          onChange={(e) => handleDataForm("last_name", e.target.value)}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Nome de usuário:"
          placeholder="Digite o nome de usuário..."
          required
          value={dataForm.username}
          onChange={(e) => handleDataForm("username", e.target.value)}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Melhor E-mail:"
          placeholder="Digite o seu melhor e-mail..."
          required
          value={dataForm.email}
          onChange={(e) => handleDataForm("email", e.target.value)}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Senha:"
          placeholder="Digite uma senha..."
          required
          value={dataForm.password}
          onChange={(e) => handleDataForm("password", e.target.value)}
          type="password"
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Confirme a senha:"
          placeholder="Confirme a sua senha..."
          required
          value={dataForm.confirm_password}
          onChange={(e) => handleDataForm("confirm_password", e.target.value)}
          type="password"
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={dataForm.agree_to_terms}
                value={dataForm.agree_to_terms}
                onChange={() =>
                  handleDataForm("agree_to_terms", !dataForm.agree_to_terms)
                }
              />
            }
            label="Ao clicar em 'Continuar' você concorda com nossa política de privacidade e coleta de dados."
          />
        </FormGroup>

        <Button variant="contained" color="primary" type="submit">
          Aceitar e continuar
        </Button>
      </form>
    </Container>
  );
}
