import {
  ElementTypes,
  Item,
  Task,
  TaskSubContent,
} from "services/admin/Interfaces/Types";
import {
  resetClickedElements,
  setSelectedElement,
} from "store/reducers/Admin/Actions/treeView/actions";
import task_subcontent from "store/reducers/task_subcontent";
import {
  gerarAssessmentFormData,
  gerarContentFormData,
  gerarElementoSelecionavel,
  gerarExamFormData,
  gerarItemFormData,
  gerarTaskFormData,
  gerarTaskSubcontentFormData,
} from "./elementBuilders";

export interface TaskContent {
  element: Item | TaskSubContent;
  type: "ITEM" | "TASK_SUBCONTENT";
  description: string;
  order: number | undefined | null;
  link: string;
}

export const generateTaskContent = (task: Task): TaskContent[] => {
  let content: TaskContent[] = [];

  task.subcontents?.forEach((sc) =>
    content.push({
      element: sc,
      type: "TASK_SUBCONTENT",
      order: sc.position,
      description: "Subenunciado",
      link: "/editor/create/subcontent/",
    })
  );

  task.items.forEach((i) =>
    content.push({
      element: i,
      type: "ITEM",
      order: i.number,
      description: i.title,
      link: "/editor/create/item/",
    })
  );

  content.sort((a, b) => (a.order as number) - (b.order as number));
  return content;
};

export const proccesRootElementSelectUtil = (dispatch) => {
  dispatch(resetClickedElements());
};

export const proccesTaskSelectionUtil = (task, examId, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.TASK,
        task,
        gerarTaskFormData(task),
        examId
      )
    )
  );
};

export const proccesTaskSubcontentSelectionUtil = (subcontent, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.TASK_SUBCONTENT,
        subcontent,
        gerarTaskSubcontentFormData(subcontent, undefined),
        undefined
      )
    )
  );
};

export const proccesItemSelectionUtil = (item, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.ITEM,
        item,
        gerarItemFormData(item, undefined),
        undefined
      )
    )
  );
};

export const proccesExamSelectionUtil = (exam, tasks, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.EXAM,
        exam,
        gerarExamFormData(exam, tasks),
        undefined
      )
    )
  );
};

export const proccesAssessmentSelectionUtil = (assessment, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.ASSESSMENT,
        assessment,
        gerarAssessmentFormData(assessment),
        undefined
      )
    )
  );
};

export const proccesContentSelectionUtil = (content, dispatch) => {
  const IS_ITEM = content !== undefined && content?.subcontent_type && content.subcontent_type === 'item'

  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.CONTENT,
        content,
        IS_ITEM ? gerarItemFormData(content.object, undefined) : gerarContentFormData(content, undefined, undefined),
        undefined
      )
    )
  );
};