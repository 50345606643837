import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  ptBR,
} from "@mui/x-data-grid";
import axios from "axios";
import CardBase from "components/Admin/forms/CardBase";
import { DialogDelete, IDialogDelete } from "components/DialogDelete";
import { Loading } from "components/Loading";
import { KnowledgeArea as KnowledgeAreaInterface } from "interfaces";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import KnowledegeAreasService, {
  LIMIT,
} from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges-area.services";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

const messagesOptions = [
  "This field may not be blank.",
  "knowledge area with this Code already exists.",
] as const;

type MessageOptions = (typeof messagesOptions)[number];
interface ErrorResponses {
  code: MessageOptions[];
  name: MessageOptions[];
}

const defaultDataForm = { code: "", name: "" };
export const KnowledgeArea = () => {
  const [dataForm, setDataForm] =
    React.useState<KnowledgeAreaInterface>(defaultDataForm);
  const [page, setPage] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState<IDialogDelete>({
    isOpen: false,
    onClose: () => {
      setIsOpen({
        ...isOpen,
        isOpen: false,
      });
    },
    onConfirm: () => {},
  });

  const onSuccess = (type: "insert" | "update" | "delete") => {
    queryClient.invalidateQueries("knowledge-areas");

    let message = 'Cadastro realizado com sucesso'

    if (type !== 'delete') {
      setDataForm(defaultDataForm);
    }

    if (type === 'update') {
      message = 'Atualização realizada com sucesso'
    } else if (type === 'delete') {
      message = 'Remoção realizada com sucesso'
    }

    toast.success(message)
  };

  const onError = (error: unknown) => {
    if (axios.isAxiosError(error) && error.response?.data) {
      const responseError = error.response?.data as ErrorResponses;

      Object.keys(responseError)
        .reverse()
        .forEach((key) => {
          responseError[key].forEach((message: MessageOptions) => {
            if (message === "This field may not be blank.") {
              const fieldName = key === "name" ? "nome" : "código";
              toast.error(`O campo ${fieldName} deve ser preenchido.`);
            }
            if (message === "knowledge area with this Code already exists.") {
              toast.error("Já existe uma área do conhecimento com esse código");
            }
          });
        });
    }
  };

  const { data, isLoading } = useQuery(["knowledge-areas", page], () =>
    KnowledegeAreasService.getAllKnowledgeAreaWithPagination(page)
  );

  const queryClient = useQueryClient();
  // saveKnowledgeArea
  const postMutation = useMutation(KnowledegeAreasService.saveKnowledgeArea, {
    onSuccess: () => onSuccess('insert'),
    onError,
  });

  const editMutation = useMutation(KnowledegeAreasService.editKnowledgeArea, {
    onSuccess: () => onSuccess('update'),
    onError,
  });

  const deleteMutation = useMutation(
    KnowledegeAreasService.deleteKnowledgeArea,
    {
      onSuccess: () => onSuccess('delete'),
      onError: (error: unknown) => {
        let error_message = 'Ocorreu um erro inesperado!'

        if (axios.isAxiosError(error) && error.response?.data) {
          const response_data: any = error.response?.data

          if (response_data?.message) {
            if (response_data.message.includes('because they are referenced')) {
              error_message = 'Item referenciado em outro local. Não pode ser excluído!'
            } else {
              error_message = response_data?.message
            }
          }
        }
        
        toast.error(error_message)
      }
    }
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 100,
    },
    {
      field: "name",
      headerName: "Nome",
      sortable: false,
      width: 400,
    },
    {
      field: "code",
      headerName: "Código",
      sortable: false,
      width: 100,
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 150,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<KnowledgeAreaInterface>) => {
        return (
          <Button
            variant="outlined"
            title="Editar"
            sx={{
              background: "white",
              color: "#1D2432",
              borderColor: "#CACDD5",
            }}
            onClick={() => {
              setDataForm(row);
            }}
          >
            <Edit sx={{ marginRight: "10px" }} />
            <Typography>Editar</Typography>
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Deletar",
      width: 100,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<KnowledgeAreaInterface>) => {
        return (
          <Button
            variant="outlined"
            title="Deletar"
            aria-describedby={row.id}
            sx={{
              background: "white",
              color: "#1D2432",
              borderColor: "#CACDD5",
            }}
            onClick={() => {
              setIsOpen({
                ...isOpen,
                isOpen: true,
                onConfirm: () => {
                  deleteMutation.mutate(row.id!);
                  isOpen.onClose();
                },
              });
            }}
          >
            <Delete />
          </Button>
        );
      },
    },
  ];

  const { id: idCreator }: IRegisterProps = useSelector(
    (state: any) => state.authentication
  ).userData;

  if (
    isLoading ||
    postMutation.isLoading ||
    editMutation.isLoading ||
    deleteMutation.isLoading
  ) {
    return <Loading />;
  }

  function handleDataForm(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setDataForm({ ...dataForm, [event.target.name]: event.target.value });
  }

  return (
    <CardBase
      title="Gerenciamento de Área do Conhecimento"
      dataForm={dataForm}
      withRedux={false}
      actionRegister={() => {
        postMutation.mutate({ ...dataForm, created_by: idCreator || 0 });
      }}
      showActionButtons={false}
    >
      <Grid container spacing={2} paddingTop={1} sx={{ paddingBottom: "2vh" }}>
        <Grid item xs={6}>
          <TextField
            label="Nome"
            value={dataForm.name}
            onChange={(event) => handleDataForm(event)}
            sx={{ background: "white" }}
            required
            name="name"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <TextField
              label="Código"
              value={dataForm.code}
              onChange={(event) => handleDataForm(event)}
              sx={{ background: "white" }}
              inputProps={{ maxLength: 10 }}
              required
              name="code"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <Button
              sx={{ p: 1, width: "100%" }}
              variant="contained"
              onClick={(e) => {
                if (!dataForm.id) {
                  postMutation.mutate(dataForm);
                } else {
                  editMutation.mutate(dataForm);
                }
              }}
            >
              <Typography>Cadastrar</Typography>
            </Button>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <Button
              sx={{
                p: 1,
                width: "100%",
                background: "#EDF1FF",
                color: "#365BDC",
              }}
              variant="contained"
              onClick={(e) => {
                setDataForm(defaultDataForm);
              }}
            >
              <Typography>Cancelar</Typography>
            </Button>
          </FormControl>
        </Grid>
      </Grid>

      <DataGrid
        autoHeight
        columns={columns}
        rowCount={data?.count}
        rows={data!.results}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        onPageChange={(e) => setPage(e)}
        pagination
        page={page}
        paginationMode="server"
        disableColumnMenu
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      />

      <DialogDelete {...isOpen} />
    </CardBase>
  );
};
