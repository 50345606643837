import { createReducer } from "@reduxjs/toolkit";
import {
  SelectedElement,
  EditorModalState,
} from "services/admin/Interfaces/Types";
import { getAllAssessments } from "./Actions/Assessment/action";
import { getAllAssessmentExam } from "./Actions/assessmentExam/action";
import { getExams } from "./Actions/Exam/action";
import { getContents } from "./Actions/contents/actions";
import { getKnowledgesArea } from "./Actions/KnowledgeArea/action";

//import { createItem, removeItem, updateItem } from "./Actions/Itens/actions";
import { getAllTasks } from "./Actions/Tasks/actions";
import {
  resetClickedElements,
  setSelectedElement,
} from "./Actions/treeView/actions";
import {
  toggleEditor,
  openEditor,
  closeEditor,
  setContent,
  attach,
  saveEditor,
} from "./Actions/Editor/actions";

var emptySelectedElement: SelectedElement = {
  elementType: undefined,
  element: undefined,
  elementDataForm: undefined,
  parentElement: undefined,
};

const emptyEditor: EditorModalState = {
  open: false,
  text: "Hello World",
  attachedTo: "",
  saveText: null,
};

const INITIAL_VALUES = {
  tasks: [],
  assessments: [],
  exams: [],
  knowledgeAreas: [],
  assessmentsExam: [],
  contents: [],
  selectedElement: emptySelectedElement as SelectedElement,
  editorState: emptyEditor as EditorModalState,
};

export default createReducer(INITIAL_VALUES, {
  [getAllTasks.type]: (state, action) => {
    state.tasks = action.payload;
  },

  [getAllAssessments.type]: (state, action) => {
    state.assessments = action.payload;
  },

  [getAllAssessmentExam.type]: (state, action) => {
    state.assessmentsExam = action.payload;
  },

  [getExams.type]: (state, action) => {
    state.exams = action.payload;
  },

  [getKnowledgesArea.type]: (state, action) => {
    state.knowledgeAreas = action.payload;
  },

  [resetClickedElements.type]: (state) => {
    state.selectedElement = emptySelectedElement;
  },

  [setSelectedElement.type]: (state, action) => {
    state.selectedElement = action.payload;
  },

  [toggleEditor.type]: (state, action) => {
    state.editorState = {
      ...state.editorState,
      open: !state.editorState.open,
    };
  },
  [openEditor.type]: (state, action) => {
    state.editorState = {
      ...state.editorState,
      open: true,
    };
  },
  [closeEditor.type]: (state, action) => {
    state.editorState = {
      ...state.editorState,
      open: false,
    };
  },
  [setContent.type]: (state, action) => {
    state.editorState.text = action.payload;
  },
  [attach.type]: (state, action) => {
    state.editorState.attachedTo = action.payload;
  },
  [saveEditor.type]: (state, action) => {
    state.editorState.saveText = action.payload;
  },

  [getContents.type]: (state, action) => {
    state.contents = action.payload;
  },
});
