import { AssessmentExam } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class AssessmentsExamServices {
  public static async registerExamAssesment(
    idExam: number,
    idAssessment: number
  ) {
    let obj: AssessmentExam = {
      id: undefined,
      exam: idExam,
      assessment: idAssessment,
    };
    return api.post("/assessments/assessmentexams/", JSON.stringify(obj), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async finishAssessment(idAssessment) {
    return api.post("/assessments/assessments/"+ idAssessment + "/finish/")
  }

  public static async getExamAssesment() {
    return api.get("/assessments/assessmentexams/");
  }

  public static async deleteExamAssesment(idAssessmentExam: number) {
    return api.delete("/assessments/assessmentexams/" + idAssessmentExam + "/");
  }
}
