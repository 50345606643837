import api from "services/api";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { ServiceBindUserItemAssessment } from ".";
import {
  bindUserAssessment,
  fetchUserAssessment,
  getUsers,
  removeUserAssessment,
  resetBinds,
  resetUsers,
} from "../Actions";
import { IRegisterTeacherItemAssignment, TeacherItemAssignment } from "./types";

export const registerAssessmentToTeacher = (
  resource: IRegisterTeacherItemAssignment
) => {
  return (dispatch) => {
    ServiceBindUserItemAssessment.createBindUserAssesments(resource).then(
      ({ data }: any) => {
        const bind: TeacherItemAssignment = data;
        dispatch(bindUserAssessment(bind));
      }
    );
  };
};

export const findAssessmentsTeacherByParam = (
  idTeacher: number,
  idAssessment: number,
  idExam: number
) => {
  return (dispatch) => {
    dispatch(resetBinds());
    ServiceBindUserItemAssessment.getUserAssesmentsByParams(
      idTeacher,
      idAssessment,
      idExam
    ).then(({ data }: any) => {
      const binds: TeacherItemAssignment[] = data.results;
      dispatch(fetchUserAssessment(binds));
      if (data.next) {
        recursiveBindFetch(data.next, dispatch);
      }
    });
  };
};

export const findAssessmentsTeacher = () => {
  return (dispatch) => {
    dispatch(resetBinds());
    ServiceBindUserItemAssessment.getUserAssesments().then(({ data }: any) => {
      const binds: TeacherItemAssignment[] = data.results;
      dispatch(fetchUserAssessment(binds));
      if (data.next) {
        recursiveBindFetch(data.next, dispatch);
      }
    });
  };
};

function recursiveBindFetch(url: string, dispatch) {
  api.get(url).then(({ data }: any) => {
    dispatch(fetchUserAssessment(data.results));
    if (data.next) {
      recursiveBindFetch(data.next, dispatch);
    }
  });
}

export const deleteAssessmentsTeacher = (id: number) => {
  return (dispatch) => {
    ServiceBindUserItemAssessment.deleteBindUserAssesments(id).then(() => {
      dispatch(removeUserAssessment(id));
    });
  };
};

export const findAllTeachers = () => {
  return (dispatch) => {
    dispatch(resetUsers());
    AuthenticationServices.fetchUsersByRole("teacher").then(({ data }: any) => {
      const professores: IRegisterProps[] = filterTeacher(data.results);
      dispatch(getUsers(professores));
      if (data.next) recursiveTeacherFetch(data.next, dispatch);
    });
  };
};

function filterTeacher(usuarios: IRegisterProps[]): IRegisterProps[] {
  const professores: IRegisterProps[] = usuarios.filter((a: IRegisterProps) => {
    return a.type === "teacher";
  });
  return professores;
}

function recursiveTeacherFetch(url: string, dispatch) {
  api.get(url).then(({ data }: any) => {
    const professores: IRegisterProps[] = filterTeacher(data.results);
    dispatch(getUsers(professores));
    if (data.next) {
      recursiveTeacherFetch(data.next, dispatch);
    }
  });
}
