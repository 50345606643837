import AbilitiesService from "store/reducers/abilities/AsyncActions/abilities.service";
import { LIMIT } from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges-area.services";
import {
  FETCH_SERIE_ABILITY,
  FETCH_SERIE,
  SET_MODAL_SIMPLE_ABILITY_SERIE,
} from "../Actions";
import AbilitySerieService from "./AbililitySerie.service";
import { toast } from "react-toastify";

export const registerSerieAbility = (param: {
  serie: number;
  ability: number;
}) => {
  return (dispatch) => {
    AbilitySerieService.registerAbilitySerie(param).then((data: any) => {
      dispatch(fetchSerieAbility({ limit: LIMIT, offset: 0 }));
    });
  };
};

export const registerSerieAbilityWithCallBack = (
  param: {
    serie: number;
    ability: number;
  },
  callBackFunction: any
) => {
  return (dispatch) => {
    AbilitySerieService.registerAbilitySerie(param).then((data: any) => {
      AbilitiesService.getAbilityById(param.ability).then((response: any) => {
        dispatch(SET_MODAL_SIMPLE_ABILITY_SERIE(response.data.series));
        callBackFunction();
      });
    });
  };
};

export const fetchSerieAbility = (param: { limit: number; offset: number }) => {
  return (dispatch) => {
    AbilitySerieService.findAbilitySerie(param).then((response: any) => {
      dispatch(FETCH_SERIE_ABILITY(response.data));
    });
  };
};

export const fetchSerie = (param: { limit: number; offset: number }) => {
  return (dispatch) => {
    AbilitySerieService.findSeries(param).then((response: any) => {
      dispatch(FETCH_SERIE(response.data));
    });
  };
};

export const deleteSerieAbility = (id: number) => {
  return (dispatch) => {
    AbilitySerieService.deleteAbilitySerie(id).then((data: any) => {
      toast.success("Relacionamento Deletado");
    });
  };
};

export const deleteSerieAbilityWithCallback = (
  id: number,
  idAbility: number,
  callBackFunction: any
) => {
  return (dispatch) => {
    AbilitySerieService.deleteAbilitySerie(id).then((data: any) => {
      AbilitiesService.getAbilityById(idAbility).then((response: any) => {
        dispatch(SET_MODAL_SIMPLE_ABILITY_SERIE(response.data.series));
        callBackFunction(response.data);
      });
    });
  };
};
