import { ExamFormData } from "services/admin/Interfaces/Types";

export const validateFormData = (form: ExamFormData): string[] => {
  var errors: string[] = [];
  if (!taskHasCode(form))
    errors.push("Não é possivel cadastrar uma tarefa sem informar um Código.");

  if (!taskHasDescription(form))
    errors.push(
      "Não é possivel cadastrar uma tarefa sem informar uma descrição."
    );

  if (!taskHasKnowledgeArea(form))
    errors.push(
      "Não é possivel cadastrar uma tarefa sem informar uma Área do Conhecimento."
    );

  return errors;
};

function taskHasCode(dataForm: ExamFormData): boolean {
  var hasTitle: boolean = (dataForm.code !== undefined &&
    dataForm.code &&
    dataForm.code !== "") as boolean;
  return hasTitle;
}

function taskHasDescription(dataForm: ExamFormData): boolean {
  var hasDescription: boolean = (dataForm.description !== undefined &&
    dataForm.description &&
    dataForm.description !== "") as boolean;
  return hasDescription;
}

function taskHasKnowledgeArea(dataForm: ExamFormData): boolean {
  var hasArea: boolean = (dataForm.knowledge_area !== undefined &&
    dataForm.knowledge_area !== 0) as boolean;
  return hasArea;
}
