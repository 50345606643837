import { partialMatchKey } from "react-query/types/core/utils";
import api from "services/api";

export default class AbilitySerieService {
  public static async findAbilitySerie(param: {
    limit: number;
    offset: number;
  }) {
    return api.get(`/matrices/abilities_series/`, { params: { ...param } });
  }

  public static async findSeries(param: { limit: number; offset: number }) {
    return api.get(`/groups/series/`, { params: { ...param } });
  }

  public static async registerAbilitySerie(props: {
    serie: number;
    ability: number;
  }) {
    return api.post("/matrices/abilities_series/", props);
  }

  public static async deleteAbilitySerie(id: number) {
    return api.delete(`/matrices/abilities_series/${id}`);
  }
}
