import { useEffect, useState } from "react";
import { Button, Grid, Pagination, TextField, Typography } from "@mui/material";

import { TabelaRespostasGerais } from "./TabelaRespostasGerais";
import {
  ITabelaRespostasEscola,
  TabelaRespostasEscola,
} from "./TabelaRespostasEscola";
import api from "services/api";
import { MessageEmpty } from "components/MessageEmpty";
import { Loading } from "components/Loading";

interface ITabelaRelatorio {
  items: any[];
  idAvaliacao: string;
}

export function TabelaRelatorio({ items, idAvaliacao = "" }: ITabelaRelatorio) {
  const [aba, setAba] = useState<"gerais" | "escola">("gerais");

  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState('')

  const [itemsEscola, setItemsEscola] = useState<ITabelaRespostasEscola[]>([])

  const [itemsPaginadosGerais, setItemsPaginadosGerais] = useState([]);
  const [itemsPaginadosEscola, setItemsPaginadosEscola] = useState<any[]>([]);

  const [indicePaginacao, setIndicePaginacao] = useState<number>(1);

  // Ajusta os itens para paginação
  function paginateArray(
    array: any[],
    pageSize = 10,
    tipo: "gerais" | "escola" = "gerais"
  ) {
    const pageCount = Math.ceil(array.length / pageSize);
    const paginatedArray = [];

    for (let i = 0; i < pageCount; i++) {
      // Calculate the starting and ending indices for each page
      const start = i * pageSize;
      const end = start + pageSize;

      // Extract the portion of the array for the current page
      const pageItems = array.slice(start, end);

      // Push the extracted items into the paginated array
      paginatedArray.push(pageItems as never);
    }

    tipo === "gerais"
      ? setItemsPaginadosGerais(paginatedArray)
      : setItemsPaginadosEscola(paginatedArray);

    return paginatedArray;
  }

  // Faz a busca para o primeiro nivel
  async function getReportsEvaluations() {
    if (!idAvaliacao || itemsPaginadosEscola.length > 0) return;

    setIsLoading(true);

    await api
      .get(`assessments/assessments/${idAvaliacao}/report/evaluations/`)
      .then(({ data }) => {
        setItemsEscola(data)

        paginateArray(data, 10, "escola")
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }

  function handleSearch(textFilter: string) {
    setSearchFilter(textFilter)

    let filterItems: any[] = []

    if (aba === 'gerais'){
      filterItems = items.filter(item => String(item.item_title[0]).toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()) || item.task_title.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()))

      paginateArray(filterItems)

      return
    }

    filterItems = itemsEscola.filter(item => item.school_name.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()))

    paginateArray(filterItems, 10, "escola")
  }

  useEffect(() => {
    setAba("gerais");
    setIndicePaginacao(1);
    setItemsPaginadosEscola([]);

    paginateArray(items);
  }, [items]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="h5">Tabela de Relatório</Typography>
      </Grid>

      <Grid item md={6}>
        <div
          style={{
            backgroundColor: "var(--neutral-1)",
            padding: "0.4rem",
            borderRadius: 4,
            display: "flex",
            alignItems: "stretch",
          }}
        >
          <button
            style={{
              flex: 1,
              backgroundColor: aba === "gerais" ? "var(--primary-dark)" : "transparent",
              color: aba === "gerais" ? "var(--neutral-1)" : "var(--neutral-7)",
              borderRadius: 4,
              padding: "0.2rem",
              textAlign: "center",
              border: "none",
            }}
            onClick={() => {
              setAba("gerais");
              setIndicePaginacao(1);
              handleSearch('')
            }}
          >
            Respostas Gerais
          </button>

          <button
            style={{
              flex: 1,
              backgroundColor: aba === "escola" ? "var(--primary-dark)" : "transparent",
              color: aba === "escola" ? "var(--neutral-1)" : "var(--neutral-7)",
              borderRadius: 4,
              padding: "0.2rem",
              textAlign: "center",
              border: "none",
            }}
            onClick={() => {
              setAba("escola");
              setIndicePaginacao(1);
              getReportsEvaluations();
              handleSearch('')
            }}
          >
            Respostas por Escolas
          </button>
        </div>
      </Grid>

      <Grid item md={6} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <TextField
          placeholder={aba === 'gerais' ? "Pesquise pelo item..." : "Pesquise pelo escola..."}
          fullWidth
          value={searchFilter}
          onChange={(e) => handleSearch(e.target.value)}
        />

        <Button
          variant="contained"
          size="large"
        >
          Pesquisar
        </Button>
      </Grid>

      <Grid
        item
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div>
          <h6>Percentual de Avaliações:</h6>

          <div
            style={{ flex: 1, display: "flex", alignItems: "center", gap: 8 }}
          >
            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#f1530f",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              "Insuficiente"
            </span>

            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#f1c40f",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              "Parcialmente Insuficiente"
            </span>

            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#00e676",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              "Suficiente"
            </span>

            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#333333",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              Sem Rubrica
            </span>
          </div>
        </div>
      </Grid>

      <Grid
        item
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div>
          <h6>Descritivo de Respostas:</h6>

          <div
            style={{ flex: 1, display: "flex", alignItems: "center", gap: 8 }}
          >
            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#47b9ee",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              Estudantes
            </span>

            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#00a0ea",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              Transcriber
            </span>

            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#0072b9",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              OCR
            </span>

            <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "#202020",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                }}
              />
              Não Contabizado
            </span>
          </div>
        </div>
      </Grid>

      <Grid item md={12}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {aba === "gerais" ? (
              <TabelaRespostasGerais
                items={itemsPaginadosGerais[indicePaginacao - 1] ?? []}
              />
            ) : (
              <>
                {itemsPaginadosEscola.length > 0 ? (
                  <>
                    {itemsPaginadosEscola[indicePaginacao - 1].map(
                      (escola: ITabelaRespostasEscola) => (
                        <TabelaRespostasEscola
                          key={escola.school_id}
                          {...escola}
                          idAvaliacao={idAvaliacao}
                        />
                      )
                    )}
                  </>
                ) : (
                  <MessageEmpty />
                )}
              </>
            )}
          </>
        )}
      </Grid>

      <Grid item md={12}>
        {(itemsPaginadosGerais.length > 0 && aba === "gerais") ||
        (itemsPaginadosEscola.length > 0 && aba === "escola") ? (
          <Pagination
            count={
              aba === "gerais"
                ? itemsPaginadosGerais.length ?? 1
                : itemsPaginadosEscola.length ?? 1
            }
            color="secondary"
            onChange={(_, pageNumber) => setIndicePaginacao(pageNumber)}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
