import { Add, Delete, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Assessment, Exam, Item, Task } from "services/admin/Interfaces/Types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import {
  IsearchDataTranscriber,
  IStateUserTranscriber,
} from "store/reducers/UsuarioTranscricaoItem";
import { setSearchDataTranscriber } from "store/reducers/UsuarioTranscricaoItem/Actions";
import {
  deleteTranscriberUserItem,
  findAllTranscribers,
  findTranscriberByParam,
  registerTranscriber,
} from "store/reducers/UsuarioTranscricaoItem/AsyncActions";
import {
  IRegisterTranscriberItemAssignment,
  TranscriberItemAssignment,
} from "store/reducers/UsuarioTranscricaoItem/AsyncActions/types";
import {
  getAssessmentById,
  getExamById,
  getExamsFromAssessment,
  getTaskById,
  getTasksFromExam,
} from "../utils/elementGetters";
import CardBase from "./CardBase";

const CardBindTranscriberToTeacher: React.FC = () => {
  const dispatch = useDispatch();
  const state: IStateUserTranscriber = useSelector(
    (state: any) => state.transcriberItem
  );

  const filters = state.filters;
  const transcribers: IRegisterProps[] = state.transcribers;
  const binds: TranscriberItemAssignment[] = state.userTranscriberAssessment;

  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;
  const exams: Exam[] = useSelector((state: any) => state.admin).exams;

  const [selectedItem, setSelectedItem] = useState(0);

  const assessments: Assessment[] = useSelector(
    (state: any) => state.admin
  ).assessments;

  let dataForm: TranscriberItemAssignment = state.dataForm;

  useEffect(() => {
    dispatch(findAllTranscribers());
  }, [dispatch]);

  const handleFilterChange = (filtersUp: IsearchDataTranscriber) => {
    getBinds(filtersUp);
    dispatch(setSearchDataTranscriber(filtersUp));
  };

  const initiResgister = (idItem: number) => {
    if (idItem !== 0) {
      let newBind: IRegisterTranscriberItemAssignment = {
        assessment: filters.idAssessment,
        exam: filters.idExam,
        item: idItem,
        transcriber: filters.idUser,
      };
      registerBind(newBind);
      setSelectedItem(0);
    }
  };

  const registerBind = (resource: IRegisterTranscriberItemAssignment) => {
    dispatch(registerTranscriber(resource));
  };

  const removeBind = (id: number) => {
    dispatch(deleteTranscriberUserItem(id));
  };

  const getBinds = (filters: IsearchDataTranscriber) => {
    if (filters.idUser) {
      dispatch(
        findTranscriberByParam(
          filters.idUser,
          filters.idAssessment,
          filters.idExam
        )
      );
    }
  };

  const getExamsFromAsessment = (): Exam[] => {
    let assessment: Assessment | undefined = getAssessmentById(
      assessments,
      filters.idAssessment
    );
    let retorno: Exam[] = [];
    if (assessment) {
      retorno = getExamsFromAssessment(
        assessment as Assessment,
        assessment.exams,
        exams
      );
    }
    return retorno;
  };

  const getTaskFromExam = (): Task[] => {
    let exam: Exam | undefined = getExamById(exams, filters.idExam);
    let retorno: Task[] = [];
    if (exam) {
      retorno = getTasksFromExam(exam, tasks);
    }
    return retorno;
  };

  const getItemsFromTask = () => {
    const task: Task | undefined = getTaskById(tasks, filters.idTask);
    let retorno: Item[] = [];
    if (task) {
      retorno = task.items;
    }
    return retorno;
  };

  const itemJaAlocado = (idItem: number): boolean => {
    let jaAlocado: boolean = false;
    for (const bind of binds) {
      if (idItem === bind.item) {
        jaAlocado = true;
        break;
      }
    }
    return jaAlocado;
  };

  const getTaskItemsAlocados = (
    vinculacao: TranscriberItemAssignment
  ): Task[] => {
    let tasksAlocacao: Task[] = [];
    const exam: Exam | undefined = getExamById(
      exams,
      vinculacao.exam.id as number
    );
    if (exam) {
      tasksAlocacao = getTasksFromExam(exam, tasks);
    }
    return tasksAlocacao;
  };

  const getDetalheVinculacao = (vinculacao: TranscriberItemAssignment) => {
    let descricao: string | undefined = undefined;
    const tasks: Task[] = getTaskItemsAlocados(vinculacao);
    for (let task of tasks) {
      let prefix = task.title;
      let items: Item[] | undefined = task.items.filter((i) => {
        return i.id === vinculacao.item;
      });

      if (items !== undefined && items.length > 0) {
        descricao = prefix + " > " + items[0].title;
        break;
      }
    }

    return descricao;
  };

  const atualizarFiltroUser = (id: number) => {
    let filtersUp: IsearchDataTranscriber = {
      ...filters,
      idUser: id,
    };
    handleFilterChange(filtersUp);
  };

  const atualizarFiltroAplicacao = (id: number) => {
    let filtersUp: IsearchDataTranscriber = {
      ...filters,
      idAssessment: id,
      idExam: 0,
      idTask: 0,
    };
    handleFilterChange(filtersUp);
  };

  const atualizarFiltroExame = (id: number) => {
    let filtersUp: IsearchDataTranscriber = {
      ...filters,
      idExam: id,
      idTask: 0,
    };
    handleFilterChange(filtersUp);
  };

  const atualizarFiltroTarefa = (id: number) => {
    let filtersUp: IsearchDataTranscriber = { ...filters, idTask: id };
    handleFilterChange(filtersUp);
  };
  return (
    <CardBase
      title={"Alocação para Validação"}
      actionRegister={undefined}
      actionUpdate={undefined}
      dataForm={dataForm}
      validateMethod={() => {
        return [];
      }}
      showActionButtons={false}
    >
      <Box key="root_content_card_exam" display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputLabel>Usuário</InputLabel>
            <Select
              value={filters.idUser}
              label="Professor"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroUser(e.target.value as number);
              }}
              defaultValue={0}
              fullWidth
            >
              <MenuItem key={"list_knowledges_area_0"} value={0}>
                {"Selecione o Usuário"}
              </MenuItem>
              {transcribers.map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.first_name + " " + i.last_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Avaliação</InputLabel>
            <Select
              value={filters.idAssessment}
              label="Avaliação de Prova"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroAplicacao(e.target.value as number);
              }}
              defaultValue={0}
              fullWidth
            >
              <MenuItem key={"list_knowledges_aplicacao_0"} value={0}>
                {"Selecione a Avaliação da Prova"}
              </MenuItem>
              {assessments.map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Exame</InputLabel>
            <Select
              value={filters.idExam}
              label="Prova"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroExame(e.target.value as number);
              }}
              defaultValue={0}
              fullWidth
            >
              <MenuItem key={"list_knowledges_aplicacao_0"} value={0}>
                {"Selecione o Exame"}
              </MenuItem>
              {getExamsFromAsessment().map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Tarefa</InputLabel>
            <Select
              value={filters.idTask}
              label="Tarefa"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroTarefa(e.target.value as number);
              }}
              fullWidth
            >
              <MenuItem key={"list_knowledges_aplicacao_0"} value={0}>
                {"Selecione a tarefa"}
              </MenuItem>
              {getTaskFromExam().map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={10}>
            <InputLabel>Item</InputLabel>
            <Select
              value={selectedItem}
              sx={{ background: "white" }}
              onChange={(e) => {
                // console.log(e.target.value);
                setSelectedItem(e.target.value as number);
              }}
              fullWidth
            >
              <MenuItem key={"item-0"} value={0}>
                {"Selecione o item"}
              </MenuItem>
              {getItemsFromTask().map((item, index) =>
                !itemJaAlocado(item.id as number) ? (
                  <MenuItem
                    key={"list_task_exam_item_" + index}
                    value={item.id as number}
                  >
                    <Typography key={"list_task_exam_item_txt_label_" + index}>
                      {item.title}
                    </Typography>
                  </MenuItem>
                ) : null
              )}
            </Select>
          </Grid>

          <Grid item xs={2}>
            <Box sx={{ height: "100%", paddingTop: 2.5 }}>
              <Button
                sx={{ p: 1, width: "100%" }}
                variant="contained"
                onClick={(e) => initiResgister(selectedItem)}
              >
                <Typography>Adicionar Item </Typography>
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              align="left"
              variant="h6"
              fontSize="600"
              p={1}
              paddingLeft={2}
              sx={{ background: "#F1F3F9" }}
            >
              Nome do Item
            </Typography>
            <List
              sx={{
                background: "white",
                height: "35vh",
                border: "1px solid #E7E9EE",
                overflowY: "auto"
              }}
            >
              {binds.map((vinvulacao, index) => (
                <div key={"list_task_exam_item_content_" + index}>
                  <ListItem
                    key={"list_task_exam_item_" + index}
                    secondaryAction={
                      <Button
                        variant="outlined"
                        sx={{
                          background: "white",
                          color: "#1D2432",
                          borderColor: "#CACDD5",
                        }}
                        key={"list_task_exam_item_secondary_btn" + index}
                        onClick={(e) => {
                          removeBind(vinvulacao.id as number);
                        }}
                      >
                        <Delete
                          key={"list_task_exam_item_remove_btn_" + index}
                        />
                      </Button>
                    }
                  >
                    <ListItemText
                      key={"list_task_exam_item_txt" + index}
                      primary={
                        <Typography
                          key={"list_task_exam_item_txt_label_" + index}
                        >
                          {vinvulacao.assessment.description +
                            " > " +
                            getDetalheVinculacao(vinvulacao)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider
                    key={"list_task_exam_item_divider" + index}
                    variant="fullWidth"
                    component="li"
                  />
                </div>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </CardBase>
  );
};

export default CardBindTranscriberToTeacher;
