import classNames from "classnames";
import "./styles.scss";

interface Props {
  text: string;
  htmlFor?: string;
  required?: boolean;
}

export const Label = ({ text, required, ...rest }: Props) => {
  return (
    <label
      {...rest}
      className={classNames({
        "exam-label": true,
        "required-symbol": required,
      })}
    >
      {text}
    </label>
  );
};
