import { useState } from "react";

import { toast } from "react-toastify";

import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useSelector } from "react-redux";

import api from "services/api";

import { Assessment } from "services/admin/Interfaces/Types";
import { SumarioDoSistema } from "../SumarioDoSistema";

interface IFilters {
  getReport: ({
    ...props
  }: {
    idAssessment: number | null;
    idSchool?: number;
    idExam?: number;
    idClass?: number;
  }) => Promise<void>;
}

export function Filters({ getReport }: IFilters) {
  const assessments: Assessment[] = useSelector(
    (state: any) => state.admin
  ).assessments;

  const [aval, setAval] = useState<any>(null);

  const [exames, setExames] = useState<any | any[]>([]);
  const [exame, setExame] = useState<any | any[]>("all");

  const [escolas, setEscolas] = useState<any | any[]>([]);
  const [escola, setEscola] = useState<any | any[]>("all");

  const [turmas, setTurmas] = useState<any | any[]>([]);
  const [turma, setTurma] = useState<any | any[]>("all");

  const [isLoadingSearchShoolsAndExams, setIsLoadingSearchSchoolsAndExams] =
    useState(false);
  const [isLoadingSearchClasses, setIsLoadingSearchClass] = useState(false);

  async function getSchoolsByAssessment(idAssessment: number) {
    await api
      .get(`/assessments/assessments/${idAssessment}/schools/`)
      .then(({ data }: any) =>
        data.schools ? setEscolas(data.schools) : setEscolas([])
      );
  }

  function getExamsByAssessment(idAssessment: number) {
    const itemAssessment = assessments.filter(
      (assessment) => assessment.id === idAssessment
    );

    // console.log(itemAssessment);

    if (itemAssessment.length) {
      setExames(itemAssessment[0].exams);
    } else {
      setExames([]);
    }
  }

  async function getClassesBySchool(idSchool: number) {
    await api
      .get(`/groups/groups/`, {
        params: {
          type: "class",
          parent: idSchool,
        },
      })
      .then((response) => setTurmas(response.data));
  }

  return (
    <>
      <Grid container spacing={1} sx={{ paddingTop: "1vh" }}>
        <Grid item md={12}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
            }}
          >
            Filtros
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "12px",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="avaliacao-label">Avaliação</InputLabel>

            <Select
              labelId="avaliacao-label"
              id="avaliacao-select"
              defaultValue={aval}
              label="Avaliação"
              onChange={async (e) => {
                if (e.target.value === "all") {
                  toast.info("Por favor, selecione uma avaliação!");
                  getReport({ idAssessment: null });
                } else {
                  setAval(e.target.value);

                  setIsLoadingSearchSchoolsAndExams(true);
                  setEscola("all");
                  setExame("all");

                  getExamsByAssessment(e.target.value);

                  await Promise.all([
                    getSchoolsByAssessment(e.target.value),
                    getReport({ idAssessment: e.target.value }),
                  ]);

                  setIsLoadingSearchSchoolsAndExams(false);
                }
              }}
            >
              {assessments.map((assessment) => (
                <MenuItem value={assessment.id}>
                  {assessment.id} - {assessment.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="exame-label">Exame</InputLabel>
            <Select
              labelId="exame-label"
              id="exame-select"
              value={exame}
              label="Exame"
              onChange={async (e) => {
                setIsLoadingSearchClass(true);
                setExame(e.target.value);

                await getReport({
                  idAssessment: aval,
                  idSchool: escola === "all" ? null : escola,
                  idExam: e.target.value === "all" ? null : e.target.value,
                  idClass: turma === "all" ? null : turma,
                });
                setIsLoadingSearchClass(false);
              }}
              defaultValue={"all"}
              disabled={isLoadingSearchShoolsAndExams || exames.length <= 0}
            >
              <MenuItem value="all">
                {isLoadingSearchShoolsAndExams ? (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <CircularProgress style={{ height: 20, width: 20 }} />{" "}
                    Carregando exames...
                  </div>
                ) : exames.length > 0 ? (
                  "Todas"
                ) : (
                  "Nenhum exame com esta avaliação"
                )}
              </MenuItem>
              {exames.map((exameItem) => {
                return (
                  <MenuItem value={exameItem.exam}>
                    {exameItem.title ?? "(Exame sem título)"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="escola-label">Escola</InputLabel>
            <Select
              labelId="escola-label"
              id="escola-select"
              value={escola}
              label="Escola"
              onChange={async (e) => {
                setIsLoadingSearchClass(true);
                setEscola(e.target.value);

                await Promise.all([
                  getClassesBySchool(e.target.value),
                  getReport({
                    idAssessment: aval,
                    idSchool: e.target.value === "all" ? null : e.target.value,
                    idExam: exame === "all" ? null : exame,
                  }),
                ]);

                setIsLoadingSearchClass(false);
              }}
              defaultValue={"all"}
              disabled={isLoadingSearchShoolsAndExams || escolas.length <= 0}
            >
              <MenuItem value="all">
                {isLoadingSearchShoolsAndExams ? (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <CircularProgress style={{ height: 20, width: 20 }} />{" "}
                    Carregando escolas...
                  </div>
                ) : escolas.length > 0 ? (
                  "Todas"
                ) : (
                  "Nenhuma escola com esta avaliação"
                )}
              </MenuItem>
              {escolas.map((escolaItem) => {
                return (
                  <MenuItem value={escolaItem.id}>{escolaItem.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="turma-label">Turmas</InputLabel>
            <Select
              labelId="turma-label"
              id="turma-select"
              value={turma}
              label="Turmas"
              onChange={async (e) => {
                setIsLoadingSearchClass(true);
                setTurma(e.target.value);

                await getReport({
                  idAssessment: aval,
                  idSchool: escola === "all" ? null : escola,
                  idExam: exame === "all" ? null : exame,
                  idClass: e.target.value === "all" ? null : e.target.value,
                });
                setIsLoadingSearchClass(false);
              }}
              defaultValue={"all"}
              disabled={isLoadingSearchClasses || turmas.length <= 0}
            >
              <MenuItem value="all">
                {isLoadingSearchClasses ? (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <CircularProgress style={{ height: 20, width: 20 }} />{" "}
                    Carregando turmas...
                  </div>
                ) : turmas.length > 0 ? (
                  "Todas"
                ) : (
                  "Nenhuma turma com esta escola"
                )}
              </MenuItem>
              {turmas.map((turmasItem) => {
                return (
                  <MenuItem value={turmasItem.id}>
                    {turmasItem.name ?? "(Turma sem nome)"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <SumarioDoSistema
        qtd_avaliacoes={assessments.length}
        qtd_escolas={escolas.length}
      />

      <Backdrop
        open={isLoadingSearchShoolsAndExams || isLoadingSearchClasses}
        sx={{ zIndex: 9999 }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}
