import { useState } from 'react';

import { toast } from "react-toastify";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";

import "./ChangePasswordForm.scss";

const scheme = z
  .object({
    oldPassword: z.string().min(1, { message: "Campo de senha é obrigatório" }),
    newPassword: z
      .string()
      .min(8, { message: "A senha deve ter no mínimo 8 caracteres" }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "A confirmação deve ser igual a nova senha",
    path: ["confirmPassword"],
  });

type ChangePassword = z.infer<typeof scheme>;

interface IChangePasswordForm {
  onSubmitForm: () => void;
}

export function ChangePasswordForm({ onSubmitForm }: IChangePasswordForm) {
  const [showActualPassword, setShowActualPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePassword>({
    resolver: zodResolver(scheme),
  });

  async function handleChange({ newPassword, oldPassword }: ChangePassword) {
    AuthenticationServices.changePassword({
      current_password: oldPassword,
      new_password: newPassword,
    })
    .then(() => {
      onSubmitForm();
      toast.success("Senha alterada com sucesso");
    })
    .catch((e) => {
      if (e.response.data.current_password) {
        toast.error("Por favor, digite a senha atual corretamente");
        return
      }
      
      toast.error("Erro ao alterar senha, verifique os dados informados");
    });
  }

  function handleShowPassword(type: 'actual' | 'new' | 'confirm') {
    switch (type) {
      case 'actual':
        setShowActualPassword(actualState => !actualState)
        break
      case 'new':
        setShowNewPassword(actualState => !actualState)
        break
      case 'confirm':
        setShowConfirmPassword(actualState => !actualState)
        break
      default:
        break
    }
  }

  return (
    <form
      id="changePasswordForm"
      style={{ background: "white" }}
      onSubmit={handleSubmit(handleChange)}
    >
      <TextField
        {...register("oldPassword")}
        label="Senha atual"
        type={showActualPassword ? 'text' : 'password'}
        error={!!errors.oldPassword?.message}
        helperText={(errors.oldPassword?.message as string) || ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleShowPassword('actual')}
                edge="end"
              >
                {showActualPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        {...register("newPassword")}
        label="Nova senha"
        type={showNewPassword ? 'text' : 'password'}
        error={!!errors.newPassword?.message}
        helperText={(errors.newPassword?.message as string) ? <b>{errors.newPassword?.message}</b> :  ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleShowPassword('new')}
                edge="end"
              >
                {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        {...register("confirmPassword")}
        label="Confirmação"
        type={showConfirmPassword ? 'text' : 'password'}
        error={!!errors.confirmPassword?.message}
        helperText={(errors.confirmPassword?.message as string) ? <b>{errors.confirmPassword?.message}</b> :  ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleShowPassword('confirm')}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button type="submit" variant="contained">
        Salvar
      </Button>
    </form>
  );
};
