import { Button, Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";

export interface IAbilityRelationDialog {
  open: boolean;
  cancelAction: any;
  action: any;
}

const AbilityRelationDialog = (props: IAbilityRelationDialog) => {
  const { open, action, cancelAction } = props;

  return (
    <Dialog
      open={open}
      BackdropProps={{ invisible: true }}
      sx={{ marginLeft: "auto", marginRight: "auto", width: "28%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box p={1} sx={{ background: "#F3F7F9" }}>
          <Typography variant="h4" align="center" fontWeight="600">
            Relacionamentos Entre Habilidades
          </Typography>
        </Box>
        <Box
          p={1}
          sx={{
            background: "#EAF2F7",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" align="center">
            Deseja confirmar a exclusão de relacionamento?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box p={2}>
              <Button
                onClick={(e) => {
                  action();
                }}
                variant="contained"
              >
                {" "}
                Sim
              </Button>
            </Box>
            <Box p={2}>
              <Button
                onClick={(e) => {
                  cancelAction();
                }}
                variant="contained"
              >
                {" "}
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AbilityRelationDialog;
