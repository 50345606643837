import { Content } from "services/admin/Interfaces/Types";
import { getContents } from "../../Actions/contents/actions";
import { resetClickedElements } from "../../Actions/treeView/actions";
import ContentServices from "./contents.service";

export const fetchContent = () => {
  return (dispatch: any) => {
    ContentServices.fetch({ limit: 1000 }).then(({ data }: any) => {
      dispatch(getContents(data));
    });
  };
};

export const registerContent = (props: Content) => {
  return (dispatch: any) => {
    ContentServices.register(props).then(({ data }: any) => {
      dispatch(resetClickedElements());
      dispatch(fetchContent());
    });
  };
};

export const processContentUpdate = (props: Content) => {
  return (dispatch: any) => {
    ContentServices.update(props).then(({ data }: any) => {
      dispatch(resetClickedElements());
      dispatch(fetchContent());
    });
  };
};

export const processContentDelete = (
  props: Content,
  successCallBack: any,
  errorCallBack: any
) => {
  return (dispatch: any) => {
    ContentServices.delete(props)
      .then(({ data }: any) => {
        dispatch(resetClickedElements());
        dispatch(fetchContent());
        successCallBack();
      })
      .catch(() => errorCallBack());
  };
};
