import { createReducer } from "@reduxjs/toolkit";
import {
  AbilitySerie,
  Page,
  Serie,
  SimpleAbilitySerie,
} from "services/admin/Interfaces/Types";
import {
  FETCH_SERIE,
  FETCH_SERIE_ABILITY,
  SET_MODAL_SIMPLE_ABILITY_SERIE,
  RESET_MODAL_SIMPLE_ABILITY_SERIE,
} from "./Actions";

const emptyPage: Page<AbilitySerie> = {
  count: 0,
  results: [],
  next: "",
  previous: "",
};

const emptyPageSerie: Page<Serie> = {
  count: 0,
  results: [],
  next: "",
  previous: "",
};

const DEFAULT_VALUE = {
  dataSerieAbility: emptyPage,
  dataSerie: [] as Serie[],
  modalAbilitieSeries: [] as SimpleAbilitySerie[],
};

const AbilitySerieStore = createReducer(DEFAULT_VALUE, {
  [FETCH_SERIE_ABILITY.type]: (state, action) => ({
    ...state,
    dataSerieAbility: action.payload,
  }),
  [FETCH_SERIE.type]: (state, action) => ({
    ...state,
    dataSerie: action.payload,
  }),
  [SET_MODAL_SIMPLE_ABILITY_SERIE.type]: (state, action) => ({
    ...state,
    modalAbilitieSeries: action.payload,
  }),
  [RESET_MODAL_SIMPLE_ABILITY_SERIE.type]: (state) => ({
    ...state,
    modalAbilitieSeries: [],
  }),
});

export default AbilitySerieStore;
