import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { login, saveUserdata } from "store/reducers/Authentication/Actions";
import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import Navbar, { IItemsOfNavigation } from "./navbar";

export enum MenuIds {
  KNOWLEDGE_AREA = "knowledge-area",
  ABILITY = "abilities",
  ABILITY_RELATIONSHIP = "ability-relationship",
  KNOWLEDGE = "knowledge",
}

const menuOptions: IItemsOfNavigation[] = [
  { name: "Editor", route: "/editor", isSelected: false },
  {
    name: "Para Validação",
    route: "/admin/transcribers",
    isSelected: false,
  },
  {
    name: "Para Correção",
    route: "/admin/bind/teacher/assessment",
    isSelected: false,
  },
  { name: "Dashboard", route: "/admin/dashboard", isSelected: false },
  {
    name: 'Matriz de Saberes',
    route: '',
    isSelected: false,
    subMenu: [
      {
        name: 'Áreas de Conhecimento',
        route: `/admin/${MenuIds.KNOWLEDGE_AREA}`,
      },
      {
        name: 'Conhecimentos',
        route: `/admin/${MenuIds.KNOWLEDGE}`,
      },
      {
        name: 'Habilidades',
        route: `/admin/${MenuIds.ABILITY}`,
      },
      {
        name: 'Relações entre Habilidades',
        route: `/admin/${MenuIds.ABILITY_RELATIONSHIP}`,
      },
    ]
  }
];

export default function Appbar(): React.ReactElement {
  const dispath = useDispatch();
  const { user } = useSelector((state: any) => state.authentication);

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    let appDataUserSession: any = sessionStorage.getItem("@App:userData");

    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispath(login(appSession as ILoginProps));
    }

    if (appDataUserSession) {
      appDataUserSession = JSON.parse(window.atob(appDataUserSession));
      dispath(saveUserdata(appDataUserSession as IRegisterProps));
    }
  }, [sessionStorage.getItem("@App:S"), dispath]);

  if (!user.signed) {
    return <></>;
  }

  return (
    <Navbar
      titleApp="Edição de Avaliações"
      itemsOfNavigation={menuOptions}
    />
  );
}
