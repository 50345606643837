import { AssessmentFormData } from "services/admin/Interfaces/Types";

export const validateFormData = (form: AssessmentFormData): string[] => {
  let erros: string[] = [];
  if (!hasCode(form))
    erros.push("A avaliação deve ter obrigatoriamente um Código.");
  if (!hasContent(form))
    erros.push("A avaliação deve ter obrigatoriamente uma descrição.");
  if (!hasTime(form))
    erros.push(
      "Para dar continuidade ao processo de cadastro é necessário informar a duração da avaliação."
    );
  if (!hasStart(form) || !hasEnd(form))
    erros.push(
      "Deve haver obrigatoriamente os dados de inicio e fim da avaliação."
    );

  if (!isStartBeforeEnd(form))
    erros.push("O início da avaliação deve ser anterior ao fim.");

  if (!hasTitle(form))
    erros.push(
      "Para cadastrar avaliações de provas é necessários informar um título"
    );

  if (!hasMinEvaluators(form))
    erros.push("É necessário informar o número mínimo de avaliadores.");

  if (!hasMinTranscribers(form))
    erros.push("É necessário informar o número mínimo de validadores.");

  return erros;
};

function hasCode(dataForm: AssessmentFormData): boolean {
  const hasTitle: boolean = (dataForm.code !== undefined &&
    dataForm.code &&
    dataForm.code !== "") as boolean;
  return hasTitle;
}

function hasContent(dataForm: AssessmentFormData): boolean {
  const hasContent = (dataForm.description !== undefined &&
    dataForm.description &&
    dataForm.description !== "") as boolean;
  return hasContent;
}

function hasTime(dataForm: AssessmentFormData): boolean {
  const hasTime = (dataForm.exam_time !== undefined &&
    dataForm.exam_time &&
    dataForm.exam_time !== undefined) as boolean;
  return hasTime;
}

function hasStart(dataForm: AssessmentFormData): boolean {
  const hasStart = (dataForm.starts_at !== undefined &&
    dataForm.starts_at &&
    dataForm.starts_at !== undefined) as boolean;
  return hasStart;
}

function hasEnd(dataForm: AssessmentFormData): boolean {
  const hasEnd = (dataForm.ends_at !== undefined &&
    dataForm.ends_at &&
    dataForm.ends_at !== undefined) as boolean;
  return hasEnd;
}

function isStartBeforeEnd(form: AssessmentFormData) {
  let start = new Date(form.starts_at);
  let end = new Date(form.ends_at);

  return start < end;
}

function hasTitle(dataForm: AssessmentFormData): boolean {
  return (dataForm.title !== undefined &&
    dataForm.title &&
    dataForm.title !== "") as boolean;
}

function hasMinTranscribers(dataForm: AssessmentFormData): boolean {
  return (dataForm.min_transcribers ? true : false) as boolean;
}

function hasMinEvaluators(dataForm: AssessmentFormData): boolean {
  return (dataForm.min_evaluators ? true : false) as boolean;
}
