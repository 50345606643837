import { useState } from 'react'
import { useSelector } from "react-redux";

import {
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Divider,
    ListItemIcon,
} from '@mui/material'

import { DialogChangePassword } from './DialogChangePassword';

import { getInitialLetterName } from "../../utils/get-initial-letters-name";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAuth } from '@cenpe/auth';

export function ProfileMenu({ first_name = '', last_name = '' }: { first_name: string; last_name: string; }) {
    const [openChangePWDialog, setChangePWDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { logout: handleLogout } = useAuth.getState();
    const open = Boolean(anchorEl);


    function logout() {
        handleLogout(false);
        sessionStorage.clear();
    }

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
    };

    const userDataRedux = useSelector(
        (state: any) => state.authentication
    ).userData;


    const handleGoLauncher = () => {
        window.location.href = process.env.REACT_APP_SSO_URL || ''
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
            <DialogChangePassword
                isOpen={openChangePWDialog}
                onClose={() => setChangePWDialog(false)}
            />

            <IconButton
                onClick={handleClick}
                className="btn_account_menu"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
            >
                <Avatar className="btn_account_avatar">
                    <Typography>
                        {getInitialLetterName(
                            userDataRedux?.first_name ?? "USER",
                            userDataRedux?.last_name ?? ""
                        )}
                    </Typography>
                </Avatar>

                <Typography>{`${userDataRedux?.first_name ?? "USER"}`}</Typography>

                {open ? (
                    <ExpandLessIcon fontSize="medium" htmlColor="var(--neutral-1)" />
                ) : (
                    <ExpandMoreIcon fontSize="medium" htmlColor="var(--neutral-1)" />
                )}
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={{ padding: 0 }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    onClick={() => {
                        setChangePWDialog(true);
                        handleClose();
                    }}>
                    Alterar senha
                </MenuItem>

                <MenuItem
                    onClick={handleGoLauncher}
                >
                    Voltar ao Launcher
                </MenuItem>

                <Divider component="li" />
                <MenuItem onClick={() => { logout() }}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" htmlColor="var(--primary-regular)" />
                    </ListItemIcon>

                    <Typography
                        style={{
                            fontWeight: "bold",
                            color: "var(--primary-regular)",
                        }}
                    >
                        Sair
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    )
}