// import { MarkdownEditor } from "../../../cempe-lib/lib/editor/markdownEditor";
import store from "../../../store"
import { openEditor, closeEditor, setContent, attach, saveEditor } from "store/reducers/Admin/Actions/Editor/actions"
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import { FormData } from "services/admin/Interfaces/Types";

// Chamar dispatch fora de um component react?
export function openMarkdown(
  textValue: string,
  onchangeCallBackFunction: (textValue: string) => void
) {
  store.dispatch(setContent(textValue));
  //store.dispatch(attach(objectProperty))
  
  store.dispatch(openEditor());
  // console.log("Openned")

  //onchangeCallBackFunction(objectProperty, file.content.text);
  //onchangeCallBackFunction(file.content.text);
}

export function openMarkdownOld(
  elementId: string,
  objectProperty: string,
) {

  const el: HTMLInputElement | null = document.getElementById(
    elementId
  ) as HTMLInputElement;
  if (el !== null) {
    store.dispatch(setContent(el.value));
    store.dispatch(attach(objectProperty))
  }
  store.dispatch(openEditor());
  // console.log("Openned")
}

export function closeMarkdown(
) {
  store.dispatch(closeEditor());
  // console.log("Closed");
  // console.log(store.getState().admin.editorState);
}


export function saveContent(
  markdownCode
){
  /* let selected = {...store.getState().admin.selectedElement};
  let form = {...selected.elementDataForm} as FormData
  let attached = store.getState().admin.editorState.attachedTo;

  form[attached] = markdownCode;
  selected.elementDataForm = form;

  store.dispatch(setSelectedElement(selected)) */
  store.dispatch(saveEditor(markdownCode));
}

