import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import Legend from "./Legend";

/**
 * Organiza os conhecimentos e habilidades em posições no gráfico.
 * @param {Array} knowledges - Array de conhecimentos.
 * @param {Array} abilities - Array de habilidades.
 * @param {number} radius - Raio do círculo.
 * @param {number} centerX - Coordenada x do centro do círculo.
 * @param {number} centerY - Coordenada y do centro do círculo.
 * @param {number} initialAngle - Ângulo inicial (padrão: 0).
 * @returns {Array} Um array contendo os conhecimentos e os ângulos das habilidades.
 */
function arrangeKnowledges(
  knowledges,
  abilities,
  radius,
  centerX,
  centerY,
  initialAngle = 0
) {
  const totalAbilities = abilities.length;
  const knowledgeCount = knowledges.length;
  const knowledgePositions = {};
  const abilitiesPositions = [];

  const pieGenerator = d3
    .pie()
    .value((d, i) => d)
    .sort(null);

  const qtdAbilities = [];

  for (let i = 0; i < knowledgeCount; i++) {
    const knowledge = knowledges[i];
    const abilitiesCount = abilities.filter(
      (ability) => ability.knowledge === knowledge.id
    ).length;
    qtdAbilities.push(abilitiesCount);
  }

  const arcData = pieGenerator(qtdAbilities, d3.range(qtdAbilities.length));
  for (let i = 0; i < knowledgeCount; i++) {
    const knowledge = knowledges[i];
    const startAngle = arcData[i].startAngle + (initialAngle * Math.PI) / 180;
    const endAngle = arcData[i].endAngle + (initialAngle * Math.PI) / 180;

    abilitiesPositions.push(
      calculateAbilityAngles(
        startAngle,
        endAngle,
        qtdAbilities[i],
        radius,
        centerX,
        centerY
      )
    );

    const x = centerX + radius * Math.cos(startAngle);
    const y = centerY + radius * Math.sin(startAngle);

    const position = {
      id: knowledge.id,
      type: "knowledge",
      angle: startAngle * (180 / Math.PI),
      endAngle: endAngle * (180 / Math.PI),
      x: abilitiesPositions[i][0].x,
      y: abilitiesPositions[i][0].y,
      abilities: qtdAbilities[i],
      name: knowledge.name,
      code: knowledge.code,
    };
    knowledgePositions[knowledge.id] = position;
  }

  abilitiesPositions.forEach((abilities) => {
    abilities.shift();
  });

  const abilitiesAngles = abilitiesPositions.flat();
  return [Object.values(knowledgePositions), abilitiesAngles];
}

/**
 * Calcula os ângulos das habilidades dentro de um saber.
 * @param {number} startAngle - Ângulo inicial do setor.
 * @param {number} endAngle - Ângulo final do setor.
 * @param {number} numAbilities - Número de habilidades no conhecimento.
 * @param {number} r - Raio do círculo.
 * @param {number} cx - Coordenada x do centro do círculo.
 * @param {number} cy - Coordenada y do centro do círculo.
 * @returns {Array} Array de objetos contendo ângulo e coordenadas (x, y) de cada habilidade.
 */
function calculateAbilityAngles(startAngle, endAngle, numAbilities, r, cx, cy) {
  const angleRange = endAngle - startAngle;
  const angleStep = angleRange / (numAbilities + 1);

  const abilityAngles = [];
  for (let i = 0; i <= numAbilities; i++) {
    const abilityAngle = startAngle + i * angleStep;
    abilityAngles.push({
      type: "ability",
      angle: abilityAngle,
      x: cx + r * Math.cos(abilityAngle),
      y: cy + r * Math.sin(abilityAngle),
    });
  }

  return abilityAngles;
}

/**
 * Obtém as habilidades a partir dos resultados fornecidos.
 * @param {Array} results - Array de resultados contendo from_ability e to_ability.
 * @returns {Array} Array de habilidades únicas.
 */
function getAbilities(results) {
  const abilitiesSet = new Set();

  for (const result of results) {
    const fromAbility = result.from_ability;
    const toAbility = result.to_ability;

    abilitiesSet.add(JSON.stringify(fromAbility));
    abilitiesSet.add(JSON.stringify(toAbility));
  }

  const abilitiesArray = Array.from(abilitiesSet, JSON.parse);
  return abilitiesArray;
}

/**
 * Filtra os saberes com base nas habilidades associadas.
 * @param {Object} dataKnowledges - Dados de conhecimentos.
 * @param {Array} abilities - Array de habilidades.
 * @returns {Array} Array de conhecimentos filtrados.
 */
function filterKnowledgesByAbilities(dataKnowledges, abilities) {
  const filteredKnowledges = [];

  dataKnowledges.results.forEach((knowledge) => {
    const knowledgeId = knowledge.id;
    const isAssociated = abilities.some(
      (ability) => ability.knowledge === knowledgeId
    );

    if (isAssociated) {
      filteredKnowledges.push(knowledge);
    }
  });
  return filteredKnowledges;
}

/**
 * Ajusta a posição X de um elemento com base na posição do centro.
 * @param {number} x - Coordenada x do elemento.
 * @param {number} y - Coordenada y do elemento.
 * @param {number} centerX - Coordenada x do centro.
 * @param {number} centerY - Coordenada y do centro.
 * @returns {number} Nova coordenada x do elemento.
 */
function fixPositionX(x, y, centerX, centerY) {
  if (x >= centerX && y == 2 * centerY) return x + 40;

  if (x < centerX && y == 2 * centerY) return x - 40;

  if (x >= centerX && y == 0) return x + 40;

  if (x < centerX && y == 0) return x - 40;

  if (x >= centerX) return x + 10;

  if (x < centerX) return x - 10;
}

/**
 * Arranja os saberes e habilidades em posições no gráfico.
 * @param {*} knowledges - Array de conhecimentos.
 * @param {*} abilities - Array de habilidades.
 * @param {*} radius - Raio do círculo.
 * @param {*} centerX - Coordenada x do centro do círculo.
 * @param {*} centerY - Coordenada y do centro do círculo.
 * @param {*} initialAngle - Ângulo inicial (padrão: 0).
 * @returns {*} Um array contendo os conhecimentos e os ângulos das habilidades.
 */
const Graph = ({
  dataKnowledges,
  dataAbilities_edge,
  width,
  height,
  clickFunction,
}) => {
  /**
   * dimensões do canvas
   * */
  const diameter = width * 0.75;
  const radius = diameter / 2;
  const centerX = diameter / 2;
  const centerY = diameter / 2;

  /**
   * dados base (saberes, abilidades e relações entre habilidades)
   * */
  const abilities_edge = dataAbilities_edge.results;

  /**
   * ABILIDADES - Ordenação das habilidades s1.h2 < s1.h3 < s2.h1
   */
  const abilities = getAbilities(abilities_edge).sort((a, b) => {
    const [aSaber, aHabilidade] = a.code.split(".");
    const [bSaber, bHabilidade] = b.code.split(".");

    const aHabilidadeNumber = Number(aHabilidade.replace(/\D/g, ""));
    const bHabilidadeNumber = Number(bHabilidade.replace(/\D/g, ""));

    if (aSaber !== bSaber) {
      return aSaber.localeCompare(bSaber);
    } else {
      return aHabilidadeNumber - bHabilidadeNumber;
    }
  });

  /**
   * SABERES - Ordenação dos saberes S1 < S2
   */
  const knowledges = filterKnowledgesByAbilities(
    dataKnowledges,
    abilities
  ).sort((a, b) => {
    const aSaberNumber = Number(a.code.replace(/\D/g, ""));
    const bSaberNumber = Number(b.code.replace(/\D/g, ""));

    return aSaberNumber - bSaberNumber;
  });

  /**
   * Estados de interação (arco clicado, linha clicada, nó clicado)
   * */
  const [arcClicked, setArcClicked] = useState(false);
  const [lineClicked, setLineClicked] = useState(false);
  const [nodeClicked, setNodeClicked] = useState(false);

  /**
   * Uso da função arrangedKnowledges para definir a posição dos
   * saberes e os angulos dos saberes e das abilidades
   */
  const [arrangedKnowledge, abilitiesAngles] = arrangeKnowledges(
    knowledges,
    abilities,
    radius,
    centerX,
    centerY,
    0
  );

  /**
   * Posicionamento das abilidades
   */
  const arrangedAbilities = abilities
    .sort((a, b) => a.knowledge - b.knowledge)
    .map((ability, i) => {
      return {
        ...ability,
        angle: abilitiesAngles[i].angle,
        x: abilitiesAngles[i].x,
        y: abilitiesAngles[i].y,
      };
    });

  // labels
  const labels = [...arrangedAbilities];
  // links
  let links = abilities_edge.map((link) => {
    const source = arrangedAbilities.filter(
      (ability) => ability.id === link.from_ability.id
    )[0];
    const target = arrangedAbilities.filter(
      (ability) => ability.id === link.to_ability.id
    )[0];
    return {
      id: link.id,
      from: source,
      from_id: link.from_ability.id,
      to: target,
      to_id: link.to_ability.id,
    };
  });

  /**
   * Filtragem dos links quando um arco é clicado
   */
  if (arcClicked != false) {
    links = links.filter(
      (link) =>
        link.from.knowledge == arcClicked.id ||
        link.to.knowledge == arcClicked.id
    );
  }

  // REFERÊNCIA DO SVG PRINCIPAL
  const svgRef = useRef();

  /**
   * Desenho do grafo a cada iteração do useEfect()
   */
  useEffect(() => {
    // SVG - Canvas
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);
    svg.selectAll("g").remove();

    // Grafo de relações entre habilidades
    const grafo = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(width - diameter) / 2}, ${(height - diameter) / 2})`
      );
    /**
     * Atualização dos nós e das linhas
     */
    grafo.selectAll("path").remove();
    grafo.selectAll("text").remove();

    // Nós
    const text = grafo.selectAll("text").data(labels).enter().append("g");
    /**
     * Desenho dos nós e interações (Hover and Click)
     */
    text
      .append("text")
      .text((d) => d.code)
      .attr("x", (d) => fixPositionX(d.x, d.y, centerX, centerY))
      .attr("y", (d) => d.y + (d.y > centerY ? 10 : -10))
      .attr("text-anchor", (d) => (d.x > centerX ? "start" : "end"))
      .attr("font-weight", (d) => {
        if (lineClicked) {
          if (lineClicked.to.id == d.id || lineClicked.from.id == d.id)
            return "600";
        }
        return "regular";
      })
      .style("alignment-baseline", "middle")
      .style("fill", (d) => {
        if (lineClicked) {
          if (lineClicked.from.id == d.id) return "#ef233c";
          if (lineClicked.to.id == d.id) return "#4361ee";
        }
        return "#000";
      })
      .style("font-size", "15px")
      .on("mouseover", (d) => {
        handleNodeMouseOver(d);
      })
      .on("mouseout", (d) => {
        handleNodeMouseOut(d);
      })
      .call((text) => text.append("title").text((d) => d.name));

    /**
     * Definição das curvas para as linhas
     */
    const curve = d3
      .line()
      .curve(d3.curveBundle)
      .x((d) => d.x)
      .y((d) => d.y);

    /**
     * Recorte das linhas
     */
    const line_cut = 10;

    /**
     * Filtro das linhas quando uma linha for clicado
     */
    if (lineClicked != false) {
      links = links.filter((link) => link.id == lineClicked.id);
    }

    /**
     * Desenho das linahs e interações (Hover and Click)
     */
    const lines = grafo
      .selectAll(".links")
      .data(links)
      .enter()
      .append("path")
      .attr("class", "links")
      .attr("fill", "none")
      .attr("stroke-opacity", 0.8)
      .attr("cursor", "pointer")
      .attr("stroke-width", (d) => {
        if (lineClicked) {
          if (d.id == lineClicked.id) return 4;
        }
        return 1;
      })
      .attr("stroke", (d) => {
        if (arcClicked) {
          if (d.from.knowledge == arcClicked.id) {
            return "#ef233c";
          } else if (d.to.knowledge == arcClicked.id) {
            return "#4361ee";
          }
        }
        return "#C5C5C5";
      })
      .attr("d", (d) => {
        const source = {
          x:
            fixPositionX(d.from.x, d.from.y, centerX, centerY) +
            (d.from.x > centerX ? -line_cut : line_cut),
          y: d.from.y + (d.from.y > centerY ? -line_cut : line_cut),
        };
        const mid = { x: centerX, y: centerY };
        const target = {
          x:
            fixPositionX(d.to.x, d.to.y, centerX, centerY) +
            (d.to.x > centerX ? -line_cut : line_cut),
          y: d.to.y + (d.to.y > centerY ? -line_cut : line_cut),
        };

        const path = [source, mid, target];
        return curve(path);
      })
      .on("mouseover", function (d) {
        if (!lineClicked) {
          var color = "#B5B5B5";
          if (arcClicked) {
            if (arcClicked.id == d.srcElement.__data__.from.id)
              color = "#ef233c";
            if (arcClicked.id == d.srcElement.__data__.to.id) color = "#4361ee";
          }
          d3.select(this)
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 4)
            .attr("stroke", color);
        }
      })
      .on("mouseout", function (d) {
        if (!lineClicked) {
          var color = "#B5B5B5";
          if (arcClicked) {
            if (arcClicked.id == d.srcElement.__data__.from.id)
              color = "#ef233c";
            if (arcClicked.id == d.srcElement.__data__.to.id) color = "#4361ee";
          }
          d3.select(this)
            .attr("stroke-opacity", 0.8)
            .attr("stroke-width", 1)
            .attr("stroke", color);
        }
      })
      .on("click", (d) => {
        setArcClicked(false);
        // console.log(d.srcElement.__data__);
        if (d.srcElement.__data__.id == lineClicked.id) {
          if (clickFunction) clickFunction(undefined);
          setLineClicked(false);
        } else {
          setLineClicked(d.srcElement.__data__);
          if (clickFunction) clickFunction(d.srcElement.__data__);
        }
      });

    /**
     * Função para criação de arcos
     * @param {Number} startAngle início do ângulo
     * @param {Number} endAngle fim do ângulo
     * @param {Number} radius raio
     * @returns
     */
    const createArcPath = (startAngle, endAngle, radius) => {
      const arcGenerator = d3
        .arc()
        .innerRadius(radius * 0.96)
        .outerRadius(radius * 1.01)
        .startAngle(startAngle + Math.PI / 2)
        .endAngle(endAngle + Math.PI / 2)
        .padAngle(0.02);
      return arcGenerator();
    };

    /**
     * Desenho dos arcos com interações (Hover and Click)
     */
    grafo
      .selectAll(".arcs")
      .data(arrangedKnowledge)
      .enter()
      .append("path")
      .attr("class", "arcs")
      .attr("fill", (d) => {
        if (arcClicked) {
          if (d.id != arcClicked.id) {
            return d3.hsl(d.angle, 0.9, 0.8);
          }
          return d3.hsl(d.angle, 1, 0.5);
        }
        return d3.hsl(d.angle, 1, 0.5);
      })
      .attr("cursor", "pointer")
      .attr("transform", `translate(${centerX}, ${centerY})`)
      .attr("d", (d) =>
        createArcPath(
          d.angle * (Math.PI / 180),
          d.endAngle * (Math.PI / 180),
          radius
        )
      )
      .on("click", (d) => {
        setLineClicked(false);
        if (d.srcElement.__data__.id != arcClicked.id) {
          setArcClicked(d.srcElement.__data__);
        } else {
          setArcClicked(false);
        }
      })
      .on("mouseover", (d) => {
        if (!lineClicked) if (!arcClicked) handleArcMouseOver(d);
      })
      .on("mouseout", (d) => {
        if (!lineClicked) if (!arcClicked) handleArcMouseOut(d);
      });

    /**
     * Função que destaca as linhas do saber em questão
     * @param {*} d event object
     */
    function handleArcMouseOver(d) {
      const knowledge = d.srcElement.__data__;
      const knowledgeId = knowledge.id;
      const associatedLinks = links.filter(
        (link) =>
          link.from.knowledge == knowledgeId || link.to.knowledge == knowledgeId
      );

      /**
       * Redesenha as linhas, filtrando apenas as linhas que
       * estão relacionadas com o saber em questão
       */
      lines
        .attr("stroke-opacity", 0.1)
        .attr("stroke", "#C5C5C5")
        .filter((link) => associatedLinks.includes(link))
        .attr("stroke-opacity", 0.8)
        .attr("stroke", (link) => {
          if (link.from.knowledge == knowledgeId) {
            return "#ef233c";
          } else if (link.to.knowledge == knowledgeId) {
            return "#4361ee";
          }
        });
    }

    /**
     * Retorna para o estado anterior ao do handleArcMouseOver()
     */
    function handleArcMouseOut() {
      lines.attr("stroke-opacity", 1);
      lines.attr("stroke", "#C5C5C5");
    }

    /**
     * Função que destaca as linhas da habilidade em questão
     * @param {*} d event object
     */
    function handleNodeMouseOver(d) {
      const ability = d.srcElement.__data__;
      const abilityID = ability.id;
      const associatedLinks = links.filter(
        (link) => link.from.id == abilityID || link.to.id == abilityID
      );

      /**
       * Redesenha as linhas, filtrando apenas as linhas que
       * estão relacionadas com a habilidade em questão
       */
      lines
        .attr("stroke-opacity", 0.1)
        .attr("stroke", "#C5C5C5")
        .filter((link) => associatedLinks.includes(link))
        .attr("stroke-opacity", 0.8)
        .attr("stroke", (link) => {
          if (link.from.id == abilityID) {
            return "#ef233c";
          } else if (link.to.id == abilityID) {
            return "#4361ee";
          }
        });
    }

    /**
     * Retorna para o estado anterior ao do handleNodeMouseOver()
     */
    function handleNodeMouseOut() {
      lines.attr("stroke-opacity", 1);
      lines.attr("stroke", "#C5C5C5");
    }
  }, [dataKnowledges, dataAbilities_edge, arcClicked, lineClicked]);

  return (
    <div style={{ display: "flex" }}>
      <svg ref={svgRef} />
      <Legend data={arrangedKnowledge} />
    </div>
  );
};

export default Graph;
