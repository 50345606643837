import { Button, Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertService from "services/admin/AlertService";
import {
  SelectedElement,
  Item,
  Task,
  Exam,
  Assessment,
  ElementTypes,
  TaskSubContent,
  Content,
} from "services/admin/Interfaces/Types";
import { resetClickedElements } from "store/reducers/Admin/Actions/treeView/actions";
import { processAssessmentDelete } from "store/reducers/Admin/AsyncActions/assessment";
import { processContentDelete } from "store/reducers/Admin/AsyncActions/content";
import { processExamDelete } from "store/reducers/Admin/AsyncActions/Exam";
import { processItemDelete } from "store/reducers/Admin/AsyncActions/Itens";
import { processTaskDelete } from "store/reducers/Admin/AsyncActions/Tasks";
import { removeSubContent } from "store/reducers/task_subcontent/AsyncActions";
import { validateDelete } from "../validators/taskValidator";

export default function DeleteDialog({ open, closeModalAction }) {
  const dispatch = useDispatch();

  const selectedElement: SelectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;

  const exams: Exam[] = useSelector((state: any) => state.admin).exams;

  const callBackError = (componente: string) => {
    AlertService.showAlert({
      message: `Erro ao excluir ${componente}.`,
      type: "erro",
      time: 5000,
    });
  };

  const callBackSuccess = (componente: string) => {
    AlertService.showAlert({
      message: `${componente} excluido com sucesso.`,
      type: "success",
      time: 5000,
    });
  };

  const processDelection = () => {
    if (selectedElement.elementType === ElementTypes.TASK)
      dispatch(
        processTaskDelete(
          selectedElement.element as Task,
          () => callBackSuccess("Task"),
          () => callBackError("task")
        )
      );
    if (selectedElement.elementType === ElementTypes.ITEM)
      dispatch(
        processItemDelete(
          selectedElement.element as Item,
          () => callBackSuccess("Item"),
          () => callBackError("item")
        )
      );
    if (selectedElement.elementType === ElementTypes.EXAM)
      dispatch(
        processExamDelete(
          selectedElement.element as Exam,
          () => callBackSuccess("Exame"),
          () => callBackError("exame")
        )
      );
    if (selectedElement.elementType === ElementTypes.ASSESSMENT)
      dispatch(
        processAssessmentDelete(
          selectedElement.element as Assessment,
          () => callBackSuccess("Avaliação"),
          () => callBackError("avaliação")
        )
      );
    if (selectedElement.elementType === ElementTypes.TASK_SUBCONTENT)
      dispatch(
        removeSubContent(
          selectedElement.element as TaskSubContent,
          () => callBackSuccess("Sub Conteúdo"),
          () => callBackError("sub conteúdo")
        )
      );
    if (selectedElement.elementType === ElementTypes.CONTENT)
      dispatch(
        processContentDelete(
          selectedElement.element as Content,
          () => callBackSuccess("Conteúdo"),
          () => callBackError("conteúdo")
        )
      );

    closeModalAction();
  };

  const errorDeleteValidator = (): string | undefined => {
    let erroMsg: string | undefined = undefined;
    if (selectedElement.element?.id && selectedElement.elementType === "TASK") {
      erroMsg = validateDelete(selectedElement.element as Task, exams);
    }
    return erroMsg;
  };
  const deleteError = errorDeleteValidator();

  return (
    <Dialog
      open={open}
      BackdropProps={{ invisible: true }}
      sx={{ marginLeft: "auto", marginRight: "auto", width: "28%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box p={1} sx={{ background: "#F3F7F9" }}>
          <Typography variant="h4" align="center" fontWeight="600">
            {deleteError === undefined
              ? "TEM CERTEZA?"
              : "Remoção Não Permitida!"}
          </Typography>
        </Box>
        <Box
          p={1}
          sx={{
            background: "#EAF2F7",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" align="center">
            {deleteError === undefined
              ? "Uma vez que o arquivo for excluído não terá como recuperar. Deseja Continuar?"
              : deleteError}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box p={2}>
              {deleteError === undefined ? (
                <Button onClick={(e) => processDelection()} variant="contained">
                  Excluir
                </Button>
              ) : null}
            </Box>
            <Box p={2}>
              <Button onClick={(e) => closeModalAction()} variant="contained">
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
