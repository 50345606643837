import {
  Assessment,
  AssessmentExam,
  Exam,
  Task,
} from "services/admin/Interfaces/Types";
import { orderArrayById } from "utils/orderArrayById";

export function getTasksFromExam(exam: Exam, tasks: Task[]): Task[] {
  let tasksFromExam: Task[] = [];
  let tasksOrdered = [...exam.tasks];
  tasksOrdered = tasksOrdered.sort((a, b) => a.number - b.number);
  for (let i = 0; i < exam.tasks.length; i++) {
    let task: Task | undefined = getTaskById(tasks, tasksOrdered[i].task);
    if (task !== undefined) {
      tasksFromExam.push(task);
    }
  }
  return tasksFromExam;
}

export function getExamsFromAssessment(
  assessment: Assessment,
  assessmentExams: AssessmentExam[],
  exams: Exam[]
): Exam[] {
  let examsOfAssessment: Exam[] = [];
  for (let i = 0; i < assessmentExams.length; i++) {
    let tempAssessmentId: number = assessmentExams[i].assessment as number;
    if (tempAssessmentId === assessment.id) {
      let exam: Exam | undefined = getExamById(exams, assessmentExams[i].exam);
      if (exam !== undefined) {
        examsOfAssessment.push(exam);
      }
    }
  }
  return examsOfAssessment;
}

export function getTaskById(tasks: Task[], idTask: number): Task | undefined {
  let task: Task | undefined;
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].id === idTask) {
      task = tasks[i];
      break;
    }
  }
  return task;
}

export function getExamById(exams: Exam[], idExam: number): Exam | undefined {
  let exam: Exam | undefined;
  for (let i = 0; i < exams.length; i++) {
    if (exams[i].id === idExam) {
      exam = exams[i];
      break;
    }
  }
  return exam;
}

export function getAssessmentById(
  assessments: Assessment[],
  idassessment: number
): Assessment | undefined {
  let assessment: Assessment | undefined;
  for (let i = 0; i < assessments.length; i++) {
    if (assessments[i].id === idassessment) {
      assessment = assessments[i];
      break;
    }
  }
  return assessment;
}

export function getAssessmentExamByAssessment(
  assessmentExams: AssessmentExam[],
  exams: Exam[]
): AssessmentExam[] {
  let values: AssessmentExam[] = [];
  for (let i = 0; i < assessmentExams.length; i++) {
    let temp: AssessmentExam = { ...assessmentExams[i] };
    let exam: Exam | undefined = getExamById(exams, assessmentExams[i].exam);
    if (exam !== undefined) {
      temp.examObj = exam;
    }
    values.push(temp);
  }
  return orderArrayById(values, "exam");
}
