function sliceSize(dataNum: number, dataTotal: number) {
    return (dataNum / dataTotal) * 360;
}

function iterateSlices(sliceSize: number, offset: number) {
    const maxSize = 179;

    let return_array: {
        get_offset: number,
        get_rotation_slice: number
    }[]= [];

    if( sliceSize <= maxSize ) {
        return_array.push({ get_offset: (offset - 1), get_rotation_slice: (-179 + sliceSize)});
    } else {
        return_array.push({ get_offset: (offset - 1), get_rotation_slice: (-179 + maxSize)});
        return_array.push(...iterateSlices(sliceSize-maxSize, offset + maxSize));
    }

    return return_array
}

export function returnSizeSliceAndOffset(valueItem: number, offset_store: number, valueTotal: number) {
    const offset = offset_store;

    const size = sliceSize(valueItem, valueTotal);

    if (valueItem === 0) {
        return { response: [], offset: (offset + size) }
    }

    return { response: iterateSlices(size, offset), offset: (offset + size) }
}