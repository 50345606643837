import { Button, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

import SearchIcon from "../../assets/logos/search.svg";

import { openMarkdown } from "../Admin/utils/newMarkdownUtils"; //../utils/newMarkdownUtils

import { saveEditor } from "store/reducers/Admin/Actions/Editor/actions";

import { MarkdownRender } from "lib_editor";
import {
  T1,
  T2,
  T3,
  T4,
  T5,
  Dica,
  Observacao,
  Audio,
  Atividade,
  Video,
  Explanation,
  Carrossel,
  Equacao,
  CodeHighlighter,
} from "lib_editor";
import "lib_editor/dist/style.css";

import "./styles.css";

interface IInputMarkdown {
  label: string;
  textValue: string;
  callBackValue: (textValue: string | any) => void;
  required?: boolean;
}

export function InputMarkdown({
  label,
  textValue,
  callBackValue,
  required = false,
}: IInputMarkdown) {
  const dispatch = useDispatch();

  const saveText: string | null = useSelector(
    (state: any) => state.admin.editorState.saveText
  );

  useEffect(() => {
    if (saveText) {
      //console.log("saveText ",saveText);
      callBackValue(saveText);
      dispatch(saveEditor(null));
    }
    //TODO: verificar se o eslint está correto
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveText]);

  function handleClick() {
    //openStackEditMarkdown(textValue, callBackValue)
    openMarkdown(textValue, callBackValue);
  }

  return (
    <>
      <div className="input_markdown_content">
        <div className="title-form-item">
          {label} {required && <span>*</span>}
        </div>
        <Divider style={{ color: "#393A3A" }} variant="fullWidth" />

        {textValue ? (
          <>
            <div className="render-overflow">
              <MarkdownRender
                markdownCode={textValue}
                fit={true}
                components={{
                  ex: Explanation,
                  dica: Dica,
                  obs: Observacao,
                  au: Audio,
                  atv: Atividade,
                  video: Video,
                  equacao: Equacao,
                  code: CodeHighlighter,
                  carrossel: Carrossel,
                  h1: T1,
                  h2: T2,
                  h3: T3,
                  h4: T4,
                  h5: T5,
                }}
              />
              {/* <MarkdownConvert textInMarkdown={textValue} /> */}
            </div>

            <div className="input_markdown_footer">
              <span>Conteúdo Markdown Ativo</span>

              <Button
                variant="contained"
                style={{ backgroundColor: "#EDF1FF", color: "#365BDC" }}
                onClick={handleClick}
              >
                EDITAR MARKDOWN
              </Button>
            </div>
          </>
        ) : (
          <div className="area_markdown_empty">
            <img
              src={SearchIcon}
              alt="Ícone com uma lupa, simbolizando a busca de texto markdown"
            />

            <h6>Nenhum Conteúdo Markdown</h6>

            <Button variant="contained" onClick={handleClick}>
              ABRIR EDITOR MARKDOWN
            </Button>
          </div>
        )}
      </div>

      <Divider
        style={{ color: "#393A3A", marginTop: "10px" }}
        variant="fullWidth"
      />
    </>
  );
}
