import { FormControl, Grid, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentFormData,
  SelectedElement,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processContentUpdate,
  registerContent,
} from "store/reducers/Admin/AsyncActions/content";

import { validateFormData } from "../validators/aulaSubcontentValidator";
import CardBase from "./CardBase";
import { Content } from "services/admin/Interfaces/Types";
import { InputMarkdown } from "components/InputMarkdown";

const CardRegisterAulaSubContent = () => {
  const dispatch = useDispatch();

  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  let dataForm: ContentFormData = {
    ...selectedElement.elementDataForm,
  } as ContentFormData;

  const handleDataForm = (key: string, value: unknown) => {
    dataForm = { ...dataForm };
    dataForm[key] = value;
    selectedElement = { ...selectedElement, elementDataForm: dataForm };
    dispatch(setSelectedElement(selectedElement));
  };

  const buildFormData = (dataForm: ContentFormData): Content => {
    return {
      id: dataForm.id,
      title: dataForm.title,
      subcontent_type: dataForm.subcontent_type,
      text: dataForm.text,
      parent: dataForm.parent,
      created_at: dataForm.created_at,
      created_by: dataForm.created_by,
      updated_at: dataForm.updated_at,
      subcontents: dataForm.subcontents,
    };
  };

  return (
    <CardBase
      title={dataForm.id ? "Conteúdo" : "Inserir Conteúdo"}
      actionRegister={() => registerContent(buildFormData(dataForm))}
      actionUpdate={() => processContentUpdate(buildFormData(dataForm))}
      validateMethod={() => validateFormData(buildFormData(dataForm))}
      dataForm={dataForm}
      postSubmitRedirectUrl="/editor"
      showActionButtons={true}
      editorContent={true}
    >
      <Grid container spacing={1} p={1}>
        <Grid item xs={12}>
          <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
            Título do Conteúdo
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              value={dataForm.title}
              onChange={(e) => handleDataForm("title", e.target.value)}
              sx={{ background: "white" }}
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InputMarkdown
            required
            label="Descrição do Conteúdo"
            textValue={dataForm.text as string}
            callBackValue={(e) => handleDataForm("text", e)}
          />
        </Grid>
      </Grid>
    </CardBase>
  );
};

export default CardRegisterAulaSubContent;
