import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Tab,
  TextField,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";

import {
  processAssessmentUpdate,
  registerAssessment,
  registerAssessmentAndContinueAddExam,
} from "store/reducers/Admin/AsyncActions/assessment";

import CardBindAssessmentExam from "./CardBindAssessmentExam";
import CardBase from "./CardBase";
import ErroDialog from "../dialogComponents/ErroDialog";
import { validateFormData } from "../validators/assessmentValidator";

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import {
  Assessment,
  AssessmentFormData,
  SelectedElement,
} from "services/admin/Interfaces/Types";
import AssessmentsExamServices from "store/reducers/Admin/AsyncActions/assessmentExam/assessment.exam.service";
import AlertService from "services/admin/AlertService";
import api from "services/api";
import React from "react";
import { Add, Remove } from "@mui/icons-material";
import InputFormIconButton from "../InputFormIconbutton/InputFormIconButton";
import InputMultiFieldDate from "../InputMultiFieldDate/InputMultiFieldDate";

export default function CardAssessmentRegister() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openErroModal, setOpenErroModal] = useState(false);
  const [errorMsg, setErroMsg] = useState("");
  const [tabControl, setTabControl] = useState("1");
  const [statusModal, setStatusModal] = useState(false);

  const selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  const dataForm: AssessmentFormData = {
    ...selectedElement.elementDataForm,
  } as AssessmentFormData;

  const user: IRegisterProps = useSelector(
    (state: any) => state.authentication
  ).userData;

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setTabControl(newValue + "");

  const handleDataForm = (key: string, value: unknown) => {
    dataForm[key] = value;
    selectedElement.elementDataForm = dataForm;
    dispatch(setSelectedElement(selectedElement));
  };

  const buildAssessmentFromForm = (): Assessment => {
    return {
      id: dataForm.id,
      code: dataForm.code,
      title: dataForm.title,
      description: dataForm.description,
      exam_time: dataForm.exam_time,
      starts_at: dataForm.starts_at,
      ends_at: dataForm.ends_at,
      exams: dataForm.exams,
      min_evaluators: dataForm.min_evaluators,
      min_transcribers: dataForm.min_transcribers,
      created_by:
        dataForm.created_by === undefined ? user.id : dataForm.created_by,
    };
  };

  const proccessDateChange = (field: string, value: Date) => {
    try {
      handleDataForm(field, value.toISOString());
    } catch {
      handleDataForm(field, "");
    }
  };

  const cadastrarEAdicionarExames = () => {
    let erros: string[] = validateFormData(dataForm);
    if (erros.length === 0) {
      dispatch(registerAssessmentAndContinueAddExam(buildAssessmentFromForm()));
    } else {
      setErroMsg(erros[0]);
      setOpenErroModal(true);
    }
  };

  const toFinishAssessment = () => {
    AssessmentsExamServices.finishAssessment(selectedElement.element?.id)
      .then(({ data }: any) => {
        setStatusModal(false);
        AlertService.showAlert({
          message: "Avaliações encerradas!",
          type: "success",
        });
      })
      .catch((error) => {
        // console.log(error);
        setStatusModal(false);
        AlertService.showAlert({
          message: "Erro ao encerrar as avaliações pendentes!",
          type: "erro",
        });
      });
  };

  const addValue = (fieldName, value) => {
    const number = value ? Number(value) + 1 : 1;
    handleDataForm(fieldName, number);
  };

  const subValue = (fieldName, value) => {
    const number = value ? Number(value) - 1 : 0;
    handleDataForm(fieldName, number);
  };

  const addictionalActionButtons = () => {
    return (
      <>
        {dataForm.id !== undefined ? (
          <>
            <Box p={1}>
              <Button
                variant="contained"
                onClick={() => setStatusModal(true)}
                sx={{
                  padding: 1,
                  background: "#EDF1FF",
                  color: "#365BDC",
                  textTransform: "none",
                }}
              >
                Encerrar
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                onClick={() => history.push("/admin/exam/print")}
                sx={{
                  padding: 1,
                  background: "#EDF1FF",
                  color: "#365BDC",
                  textTransform: "none",
                }}
              >
                Visualizar
              </Button>
            </Box>
          </>
        ) : null}

        {/** {dataForm.id === undefined ? (
          <Box p={1}>
            <Button
              onClick={(e) => cadastrarEAdicionarExames()}
              variant="contained"
              sx={{
                padding: 1,
                background: "#EDF1FF",
                color: "#365BDC",
                textTransform: "none",
              }}
            >
              Cadastrar Avaliação e Adicionar Exames
            </Button>
          </Box>
        ) : null}*/}
      </>
    );
  };

  return (
    <CardBase
      title={dataForm.id === undefined ? "Inserir Avaliação" : "Avaliação"}
      actionRegister={() => cadastrarEAdicionarExames}
      actionUpdate={() => processAssessmentUpdate(buildAssessmentFromForm())}
      validateMethod={() => validateFormData(dataForm)}
      postSubmitRedirectUrl={"/editor"}
      dataForm={dataForm}
      showActionButtons={true}
      editorContent={true}
      sendRedirectOnCreate={true}
      addictionalActionButtons={addictionalActionButtons()}
    >
      <Grid spacing={1.5} container>
        <Grid item xs={12}>
          <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
            Código
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              value={dataForm.code}
              onChange={(e) => handleDataForm("code", e.target.value)}
              sx={{ background: "white" }}
              placeholder="Digite o código da avaliação..."
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
            Título
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              value={dataForm.title}
              onChange={(e) => handleDataForm("title", e.target.value)}
              sx={{ background: "white" }}
              placeholder="Digite o título da avaliação..."
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
            Descrição
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              multiline
              minRows={4}
              value={dataForm.description}
              onChange={(e) => handleDataForm("description", e.target.value)}
              sx={{ background: "white" }}
              required
              placeholder="Digite a descrição da avaliação..."
            />
          </FormControl>
        </Grid>

        <Grid item xs={5}>
          <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
            Data de Início da Avaliação
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              ampm={false}
              renderInput={(props) => (
                <TextField {...props} sx={{ background: "white" }} />
              )}
              value={dataForm.starts_at}
              inputFormat="dd/MM/yyyy HH:mm"
              onChange={(e: Date | null) => {
                proccessDateChange("starts_at", e as Date);
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={5}>
          <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
            Data de Finalização da Avaliação
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              ampm={false}
              renderInput={(props) => (
                <TextField {...props} sx={{ background: "white" }} />
              )}
              inputFormat="dd/MM/yyyy HH:mm"
              value={dataForm.ends_at}
              onChange={(e: Date | null) => {
                proccessDateChange("ends_at", e as Date);
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2}>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
                Tempo de Prova
              </InputLabel>
            </Grid>
            <Grid item xs={12} sx={{ paddingRight: 1 }}>
              <FormControl sx={{ width: "40%" }}>
                <TextField
                  value={dataForm.exam_time}
                  // placeholder={"00"}
                  onChange={(e) => handleDataForm("exam_time", e.target.value)}
                  sx={{ background: "white" }}
                  placeholder="Digite o código da avaliação..."
                  required
                />
              </FormControl>
              <InputFormIconButton
                icon={<Remove></Remove>}
                action={() => subValue("exam_time", dataForm.exam_time)}
              ></InputFormIconButton>

              <InputFormIconButton
                icon={<Add></Add>}
                action={() => addValue("exam_time", dataForm.exam_time)}
              ></InputFormIconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
                Quantidade Mínima de Avaliadores
              </InputLabel>
            </Grid>
            <Grid item xs={12} sx={{ paddingRight: 1 }}>
              <FormControl sx={{ width: "70%" }}>
                <TextField
                  // placeholder={"00"}
                  value={dataForm.min_evaluators}
                  onChange={(e) =>
                    handleDataForm("min_evaluators", e.target.value)
                  }
                  sx={{ background: "white" }}
                  required
                  placeholder="Digite a descrição da avaliação..."
                />
              </FormControl>

              <InputFormIconButton
                icon={<Remove></Remove>}
                action={() =>
                  subValue("min_evaluators", dataForm.min_evaluators)
                }
              ></InputFormIconButton>

              <InputFormIconButton
                icon={<Add></Add>}
                action={() =>
                  addValue("min_evaluators", dataForm.min_evaluators)
                }
              ></InputFormIconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#121517", fontWeight: "600" }} required>
                Quantidade Mínima de Validadores
              </InputLabel>
            </Grid>
            <Grid item xs={12} sx={{ paddingRight: 1 }}>
              <FormControl sx={{ width: "70%" }}>
                <TextField
                  value={dataForm.min_transcribers}
                  onChange={(e) =>
                    handleDataForm("min_transcribers", e.target.value)
                  }
                  sx={{ background: "white" }}
                  required
                  placeholder={"00"}
                />
              </FormControl>
              <InputFormIconButton
                icon={<Remove></Remove>}
                action={() =>
                  subValue("min_transcribers", dataForm.min_transcribers)
                }
              ></InputFormIconButton>

              <InputFormIconButton
                icon={<Add></Add>}
                action={() =>
                  addValue("min_transcribers", dataForm.min_transcribers)
                }
              ></InputFormIconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/**  <TabContext value={tabControl}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label={"Dados Gerais"}
            value="1"
            className={tabControl === "1" ? "selectedTab" : ""}
          />
          <Tab
            label={"Exames"}
            value="2"
            disabled={dataForm.id === undefined}
            className={tabControl === "2" ? "selectedTab" : ""}
          />
        </TabList>

        <TabPanel value="1" sx={{ padding: 0 }}>
          
        </TabPanel>
        <TabPanel value="2">
          <CardBindAssessmentExam
            dataForm={dataForm}
            selectedElement={selectedElement}
          />
        </TabPanel>
      </TabContext>*/}

      <Dialog
        open={statusModal}
        sx={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box p={1} sx={{ background: "#F3F7F9" }}>
            <Typography variant="h5" align="center" fontWeight="600">
              Confirma encerramento de todas as avaliações que estão pendentes
              de finalização?
            </Typography>
          </Box>
          <Box
            p={1}
            sx={{
              background: "#EAF2F7",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" align="center">
              ATENÇÃO: Esta ação não pode ser desfeita!. Deseja Continuar?
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box p={2}>
                <Button
                  onClick={(e) => {
                    toFinishAssessment();
                  }}
                  variant="contained"
                  color="error"
                >
                  Encerrar
                </Button>
              </Box>
              <Box p={2}>
                <Button
                  onClick={(e) => setStatusModal(false)}
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <ErroDialog
        open={openErroModal}
        errorMsg={errorMsg}
        closeModalAction={() => setOpenErroModal(false)}
      />
    </CardBase>
  );
}
