import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { Box, Typography } from "@mui/material";

import { TreeView, TreeItem } from "@mui/x-tree-view";
import {
  Assessment,
  Content,
  Exam,
  Item,
  Task,
} from "services/admin/Interfaces/Types";

import { ReactComponent as IconTask } from "assets/treeViewIcons/menu_book.svg";
import { ReactComponent as IconItem } from "assets/treeViewIcons/pencil.svg";
import { ReactComponent as AvaliacaoItem } from "assets/treeViewIcons/avaliacao_icon.svg";
import { ReactComponent as Prancheta } from "assets/treeViewIcons/assignment.svg";
import { ReactComponent as Aplicacao } from "assets/treeViewIcons/bookmark.svg";
import { ReactComponent as IconAplicacao } from "assets/treeViewIcons/icon_item.svg";

import { ReactComponent as Aula } from "assets/treeViewIcons/aula.svg";
import { ReactComponent as Conteudo } from "assets/treeViewIcons/conteudo.svg";
import { ReactComponent as Clip } from "assets/treeViewIcons/clip.svg";

import { ReactComponent as MainAvaliacao } from "assets/treeViewIcons/main_avaliacao.svg";
import { ReactComponent as MainAula } from "assets/treeViewIcons/main_aula.svg";

import { ReactComponent as Enunciado } from "assets/treeViewIcons/enunciado.svg";

import TreeItemLabel from "./TreeItemLabel";

import { orderArrayById } from "utils/orderArrayById";

import {
  getExamsFromAssessment,
  getTasksFromExam,
} from "../utils/elementGetters";

import {
  generateTaskContent,
  proccesAssessmentSelectionUtil,
  proccesContentSelectionUtil,
  proccesExamSelectionUtil,
  proccesItemSelectionUtil,
  proccesRootElementSelectUtil,
  proccesTaskSelectionUtil,
  proccesTaskSubcontentSelectionUtil,
} from "../utils/treeUtils";
import { proccessDeleteAssessmentExam } from "store/reducers/Admin/AsyncActions/assessmentExam";
import ContentServices from "store/reducers/Admin/AsyncActions/content/contents.service";

interface ITreeView {
  treeFilterString: string;
}

export default function ColapsibleTreeNav(props: ITreeView) {
  const dispatch = useDispatch();
  const [lastTermSearch, setLastTermSearch] = useState(
    undefined as string | undefined
  );

  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;
  const exams: Exam[] = useSelector((state: any) => state.admin).exams;
  const assessments: Assessment[] = useSelector(
    (state: any) => state.admin
  ).assessments;

  const contents: Content[] = useSelector((state: any) => state.admin).contents;
  const [tasksOrdered, setTaskOrdered] = useState([] as Task[]);
  const [examsOrdered, setExamsOrdered] = useState([] as Exam[]);
  const [assessmentsOrdered, setAssessmentsOrdered] = useState(
    [] as Assessment[]
  );

  const [expanded, setExpanded] = useState([] as string[]);

  useEffect(() => {
    let copiaTasks: Task[] = [];
    tasks.forEach((t) => {
      const copia = { ...t };
      let itemsCopia: Item[] = [...t.items];
      itemsCopia.sort((a, b) => (a.number as number) - (b.number as number));
      copia.items = itemsCopia;
      copiaTasks.push(copia);
    });

    if (props.treeFilterString !== undefined && props.treeFilterString !== "") {
      setExpanded([
        "tasks_root",
        "provas_root",
        "aplicacao_root",
        "aula_root",
        "av_root",
      ]);
    } else {
      if (lastTermSearch !== props.treeFilterString) setExpanded([]);
    }
    setLastTermSearch(props.treeFilterString);
    setTaskOrdered(orderArrayById(copiaTasks));
    setExamsOrdered(orderArrayById(exams));
    setAssessmentsOrdered(orderArrayById(assessments));
  }, [tasks, exams, assessments, props.treeFilterString]);

  const proccesRootElementSelect = () => {
    proccesRootElementSelectUtil(dispatch);
  };

  const proccesTaskSelection = (task, examId) => {
    proccesTaskSelectionUtil(task, examId, dispatch);
  };

  const proccesTaskSubcontentSelection = (subcontent) => {
    proccesTaskSubcontentSelectionUtil(subcontent, dispatch);
  };

  const proccesItemSelection = (item) => {
    proccesItemSelectionUtil(item, dispatch);
  };

  const proccesExamSelection = (exam) => {
    proccesExamSelectionUtil(exam, tasks, dispatch);
  };

  const proccesAssessmentSelection = (assessment) => {
    proccesAssessmentSelectionUtil(assessment, dispatch);
  };

  const proccesContentSelection = (content) => {
    proccesContentSelectionUtil(content, dispatch);
  };

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  const generateTasksTree = (
    tasks: Task[],
    nodePrefix: string,
    searchString: string | undefined,
    examId: number
  ) => {
    const filteredTask = searchString
      ? tasks.filter((task) =>
          task.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : tasks;

    return filteredTask.map((task) => (
      <TreeItem
        key={task.id}
        nodeId={nodePrefix + "_task_" + task.id}
        label={
          <TreeItemLabel
            element={task}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {task.id + " - " + task.title}
              </Typography>
            }
            icon={<IconTask />}
            redirectLink="/editor/create/task/"
            proccesClickFunction={(e) => proccesTaskSelection(e, examId)}
          />
        }
      >
        {generateTaskContent(task).map((content) => (
          <TreeItem
            key={content.element.id}
            nodeId={
              nodePrefix + "_qts_" + content.type + "_" + content.element.id
            }
            label={
              <TreeItemLabel
                element={content.element}
                elemenLabel={
                  <Typography sx={{ color: "#365BDC" }}>
                    {(content.order != null ? content.order + " - " : "") +
                      content.description}
                  </Typography>
                }
                icon={content.type === "ITEM" ? <IconItem /> : <Enunciado />}
                redirectLink={content.link}
                proccesClickFunction={
                  content.type === "ITEM"
                    ? proccesItemSelection
                    : proccesTaskSubcontentSelection
                }
              />
            }
          ></TreeItem>
        ))}
      </TreeItem>
    ));
  };

  const generateExamTree = (
    exams: Exam[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredExams = searchString
      ? exams.filter((exam) =>
          exam.description
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : exams;

    return filteredExams.map((exam) => (
      <TreeItem
        key={exam.id}
        nodeId={nodePrefix + "_exam_" + exam.id}
        id={`${"exam_" + exam.id}`}
        label={
          <TreeItemLabel
            element={exam}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {exam.id + " - " + (exam.title !== null ? exam.title : "Exame")}
              </Typography>
            }
            icon={<Prancheta />}
            redirectLink="/editor/create/exam/"
            proccesClickFunction={proccesExamSelection}
          />
        }
      >
        {generateTasksTree(
          getTasksFromExam(exam, tasksOrdered),
          nodePrefix + exam.id,
          searchString,
          exam.id as number
        )}
      </TreeItem>
    ));
  };

  const generateContentTree = (
    contents: Content[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredContents = searchString
      ? contents.filter((content) =>
          content.title
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : contents;

    return filteredContents.map((content) => (
      <TreeItem
        key={content.id}
        nodeId={nodePrefix + "_content_" + content.id}
        label={
          <TreeItemLabel
            element={content}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {content.id +
                  " - " +
                  (content.title ?? (content.object as any).title!)}
              </Typography>
            }
            icon={getContentIcon(content)}
            redirectLink={getContentReturnURL(content)}
            proccesClickFunction={proccesContentSelection}
          />
        }
      >
        {content.subcontents && content.subcontents.length > 0
          ? generateContentTree(
              content.subcontents,
              nodePrefix + "_content_" + content.id,
              props.treeFilterString
            )
          : null}
      </TreeItem>
    ));
  };

  const generateAssessmentTree = (
    assesments: Assessment[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredAssessements = searchString
      ? assessments.filter((assessment) =>
          assessment.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : assesments;

    return filteredAssessements.map((assessment) => (
      <TreeItem
        key={assessment.id}
        nodeId={nodePrefix + "_assessment_" + assessment.id}
        label={
          <TreeItemLabel
            element={assessment}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {assessment.id + " - " + assessment.title}
              </Typography>
            }
            icon={<Aplicacao />}
            redirectLink="/editor/create/assessment/"
            proccesClickFunction={proccesAssessmentSelection}
          />
        }
      >
        {generateExamTree(
          getExamsFromAssessment(assessment, assessment.exams, examsOrdered),
          nodePrefix + assessment.id,
          searchString
        )}
      </TreeItem>
    ));
  };

  return (
    <Box maxWidth={"100%"} maxHeight={"100%"} paddingLeft={2} paddingRight={3}>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
        aria-label="rich object"
        defaultCollapseIcon={<ExpandLess />}
        defaultExpandIcon={<ExpandMore />}
        sx={{
          height: "72vh",
          overflowX: "auto",
          overflowy: "scroll",
        }}
      >
        <TreeItem
          key={"aula_root"}
          nodeId={"aula_root"}
          label={
            <TreeItemLabel
              element={undefined}
              elemenLabel={
                <Typography sx={{ color: "var(--primary-regular)" }}>
                  Aulas
                </Typography>
              }
              icon={<MainAula />}
              redirectLink="/editor"
              proccesClickFunction={() => proccesContentSelection(undefined)}
            />
          }
        >
          {contents
            ? generateContentTree(
                contents.filter((content) => content.parent === null),
                "aula_root",
                props.treeFilterString
              )
            : null}
        </TreeItem>

        <TreeItem
          key={"av_root"}
          nodeId={"av_root"}
          label={
            <TreeItemLabel
              element={undefined}
              elemenLabel={
                <Typography sx={{ color: "var(--primary-regular)" }}>
                  Avaliações
                </Typography>
              }
              icon={<MainAvaliacao />}
              redirectLink="/editor"
              proccesClickFunction={() => proccesAssessmentSelection(undefined)}
            />
          }
        >
          {generateAssessmentTree(
            assessmentsOrdered,
            "root_assessment",
            props.treeFilterString
          )}

          {/** <TreeItem
            key={"tasks_root"}
            nodeId={"tasks_root"}
            label={
              <TreeItemLabel
                element={undefined}
                elemenLabel={
                  <Typography sx={{ color: "#var(--primary-regular)" }}>Tarefas</Typography>
                }
                icon={<IconTask />}
                redirectLink="/editor"
                proccesClickFunction={() => proccesTaskSelection(undefined)}
              />
            }
          >
            {generateTasksTree(
              tasksOrdered,
              "root_tasks",
              props.treeFilterString
            )}
          </TreeItem>

          <TreeItem
            key={"provas_root"}
            nodeId={"provas_root"}
            label={
              <TreeItemLabel
                element={undefined}
                elemenLabel={
                  <Typography sx={{ color: "var(--primary-regular)" }}>Exames</Typography>
                }
                icon={<Prancheta />}
                redirectLink="/editor"
                proccesClickFunction={() => proccesExamSelection(undefined)}
              />
            }
          >
            {generateExamTree(
              examsOrdered,
              "root_exam",
              props.treeFilterString
            )}
          </TreeItem>

          <TreeItem
            key={"aplicacao_root"}
            nodeId={"aplicacao_root"}
            label={
              <TreeItemLabel
                element={undefined}
                elemenLabel={
                  <Typography sx={{ color: "var(--primary-regular)" }}>Avaliações</Typography>
                }
                icon={<Aplicacao />}
                redirectLink="/editor"
                proccesClickFunction={() =>
                  proccesAssessmentSelection(undefined)
                }
              />
            }
          >
            {generateAssessmentTree(
              assessmentsOrdered,
              "root_assessment",
              props.treeFilterString
            )}
          </TreeItem> */}
        </TreeItem>
      </TreeView>
    </Box>
  );
}

function getContentIcon(content: Content): any {
  if (content.subcontent_type === "content") return <Aula />;
  if (content.subcontent_type === "subcontent") return <Conteudo />;
  if (content.subcontent_type === "task") return <Prancheta />;
  if (content.subcontent_type === "item") return <Clip />;

  return null;
}

function getContentReturnURL(content: Content): string {
  if (content.subcontent_type === "content")
    return "/editor/create/class/content";
  if (content.subcontent_type === "subcontent")
    return "/editor/create/class/subcontent";
  if (content.subcontent_type === "task") return "/editor/create/class/task";
  if (content.subcontent_type === "item") return "/editor/create/item/";

  return "/editor";
}
