import { Ability } from "services/admin/Interfaces/Types";
import { FETCH_ABILITIES } from "../Actions";
import AbilitiesService from "./abilities.service";

import { toast } from "react-toastify";

export const createAbility = (
  ability: Ability,
  currentPage: number | undefined
) => {
  return async (dispatch: any) => {
    let response: { status: 'error' | 'success' } = { status: 'error' };

    await AbilitiesService.createAbility(ability)
      .then(() => {
        let offset = 0;
        if (currentPage) offset = 5 * currentPage;
        dispatch(fetchAbility(5, offset, undefined));

        response = { status: 'success' }
      })
      .catch((e) => {
        if (e.response.data.code) {
          if (String(e.response.data.code).includes('Code already exists')) {
            toast.error("Código informado já existe.");
            return
          }
        }
      });

    return response
  };
};

export const updateAbility = (
  ability: Ability,
  currentPage: number | undefined
) => {
  return async (dispatch: any) => {
    let response: { status: 'error' | 'success' } = { status: 'error' };

    await AbilitiesService.updateAbility(ability)
      .then(() => {
        let offset = 0;
        if (currentPage) offset = 5 * currentPage;
        dispatch(fetchAbility(5, offset, undefined));

        response = { status: 'success' }
      })
      .catch((e) => {
        if (e.response.data.code) {
          if (String(e.response.data.code).includes('Code already exists')) {
            toast.error("Código informado já existe.");
            return
          }
        }
      });

      return response
  };
};

export const fetchAbility = (
  size: number,
  offset: number,
  knowledge: number | undefined
) => {
  return (dispatch: any) => {
    AbilitiesService.fetchAbility(size, offset, knowledge).then((response) => {
      dispatch(FETCH_ABILITIES(response.data));
    });
  };
};

export const fetchAllAbility = () => {
  return AbilitiesService.fetchAbility(1000, 0, undefined);
};

export const removeAbility = (ability: Ability) => {
  return (dispatch: any) => {
    AbilitiesService.removeAbility(ability).then((response) => {
      dispatch(fetchAbility(5, 0, undefined));
    });
  };
};
