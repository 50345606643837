import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ErroDialog from "../dialogComponents/ErroDialog";

export interface ICardBaseParams {
  title: string;
  dataForm: any;
  actionRegister?: any;
  actionUpdate?: any;
  actionCancel?: () => void;
  validateMethod?: any;
  children: any;
  postSubmitRedirectUrl?: string;
  postSubmitAction?: any;
  withRedux?: boolean;
  showActionButtons?: boolean;
  editorContent?: boolean;
  addictionalActionButtons?: any;
  sendRedirectOnCreate?: boolean;
  sendRedirectOnUpdate?: boolean;
  successButtonText?: string;
}

export default function CardBase(props: ICardBaseParams) {
  const {
    title,
    dataForm,
    actionRegister = undefined,
    actionUpdate = undefined,
    actionCancel = () => {
      history.push("/editor");
    },
    validateMethod = () => [],
    children,
    postSubmitRedirectUrl,
    postSubmitAction,
    withRedux = true,
    showActionButtons,
    editorContent,
    addictionalActionButtons,
    sendRedirectOnCreate = true,
    sendRedirectOnUpdate = true,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [openErroModal, setOpenErroModal] = useState(false);
  const [errorMsg, setErroMsg] = useState("");

  const handleRegister = (e: React.FormEvent) => {
    if (withRedux) {
      handleSubmit(e, actionRegister, sendRedirectOnCreate);
      return;
    }
    actionRegister();
  };

  const handleUpdate = (e: React.FormEvent) => {
    if (withRedux) {
      handleSubmit(e, actionUpdate, sendRedirectOnUpdate);
      return;
    }
    actionUpdate();
  };

  const handleSubmit = async (
    e: React.FormEvent,
    action: any,
    sendRedirect: boolean
  ) => {
    const erros: string[] = validateMethod(dataForm);

    if (erros.length === 0) {
      e.preventDefault();

      if (actionUpdate) {
        const response = await dispatch(action());

        if (response && response.status === "success") {
          if (postSubmitAction) postSubmitAction();
        }
      }

      if (postSubmitRedirectUrl && sendRedirect)
        history.push(postSubmitRedirectUrl);
    } else {
      setErroMsg(erros[0]);
      setOpenErroModal(true);
    }
  };

  return (
    <Box
      height="100%"
      width="100%"
      p={1.5}
      paddingLeft={3}
      paddingRight={3}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        width="100%"
        textAlign="left"
        paddingBottom={editorContent ? 0 : 2}
        sx={{ borderBottom: editorContent ? null : "1px solid #E7E9EE" }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "700",
            fontSize: "22px",
            lineHeight: "50px",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        width="100%"
        height="90%"
        textAlign="left"
        sx={{ paddingTop: editorContent ? "0vh" : "3vh" }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem 0",
          }}
        >
          {Array.isArray(children) ? children.map((i, key) => i) : children}

          <Grid container style={{ borderTop: "2px solid var(--neutral-2)" }}>
            <Grid item xs={6}>
              {showActionButtons !== false ? (
                <Box display="flex" flexDirection="row" sx={{ width: "100%" }}>
                  {dataForm?.id !== undefined ? (
                    <Box p={1}>
                      <Button
                        variant="contained"
                        onClick={handleUpdate}
                        sx={{ textTransform: "none" }}
                      >
                        <Typography>Atualizar</Typography>
                      </Button>
                    </Box>
                  ) : (
                    <Box p={1}>
                      <Button
                        variant="contained"
                        onClick={handleRegister}
                        sx={{ textTransform: "none" }}
                      >
                        <Typography>
                          {props?.successButtonText || "Inserir"}
                        </Typography>
                      </Button>
                    </Box>
                  )}

                  <Box p={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={actionCancel}
                    >
                      <Typography>Cancelar</Typography>
                    </Button>
                  </Box>
                </Box>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="row-reverse"
                sx={{ width: "100%" }}
              >
                {addictionalActionButtons ? addictionalActionButtons : null}
              </Box>
            </Grid>
          </Grid>
        </form>
        <ErroDialog
          open={openErroModal}
          errorMsg={errorMsg}
          closeModalAction={() => setOpenErroModal(false)}
        />
      </Box>
    </Box>
  );
}
