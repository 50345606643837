import {
  gerarAssessmentFormData,
  gerarElementoSelecionavel,
} from "components/Admin/utils/elementBuilders";
import { Assessment, ElementTypes } from "services/admin/Interfaces/Types";
import { getAllAssessments } from "../../Actions/Assessment/action";
import {
  resetClickedElements,
  setSelectedElement,
} from "../../Actions/treeView/actions";
import AssessmentsServices from "./assessment.service";

export const registerAssessment = (props: Assessment) => {
  return (dispatch: any): any => {
    AssessmentsServices.registerAssessments(props).then(({ data }: any) => {
      dispatch(findAssessment());
    });
  };
};

export const registerAssessmentAndContinueAddExam = (props: Assessment) => {
  return (dispatch: any): any => {
    AssessmentsServices.registerAssessments(props).then(({ data }: any) => {
      let assessment: Assessment = data;
      dispatch(findAssessment());
      dispatch(
        setSelectedElement(
          gerarElementoSelecionavel(
            ElementTypes.ASSESSMENT,
            assessment,
            gerarAssessmentFormData(assessment),
            undefined
          )
        )
      );
    });
  };
};

export const findAssessment = () => {
  return (dispatch: any) => {
    AssessmentsServices.getAllAssessments(1000).then(({ data }: any) => {
      const assessment: Assessment[] = data.results;
      dispatch(getAllAssessments(assessment));
    });
  };
};

export const processAssessmentUpdate = (props: Assessment) => {
  return (dispatch: any) => {
    AssessmentsServices.updateAssessments(props).then(({ data }: any) => {
      dispatch(findAssessment());
    });
  };
};

export const processAssessmentDelete = (
  props: Assessment,
  successCallBack: any,
  errorCallBack: any
) => {
  return (dispatch: any) => {
    AssessmentsServices.deleteAssessments(props)
      .then(({ data: any }) => {
        dispatch(findAssessment());
        dispatch(resetClickedElements());
        successCallBack();
      })
      .catch(() => {
        console.log("deu erro");
        errorCallBack();
      });
  };
};
