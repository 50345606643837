import { Box, Grid, Typography } from "@mui/material";

interface ISumarioDoSistema {
  qtd_escolas: number;
  qtd_avaliacoes: number;
}

export function SumarioDoSistema({
  qtd_avaliacoes,
  qtd_escolas,
}: ISumarioDoSistema) {
  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Typography variant="h5">Sumário do sistema</Typography>
      </Grid>

      <Grid item md={6}>
        <Box
          p={2}
          style={{
            backgroundColor: "var(--neutral-1)",
            borderBottom: "solid 12px var(--warning-regular)",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Box>
            <Typography variant="h5" component="div">
              Escolas
            </Typography>

            <Typography variant="body1" component="div">
              Total de escolas
            </Typography>
          </Box>

          <Typography variant="h3" component="div">
            {qtd_escolas}
          </Typography>
        </Box>
      </Grid>

      <Grid item md={6}>
        <Box
          p={2}
          style={{
            backgroundColor: "var(--neutral-1)",
            borderBottom: "solid 12px var(--success-dark)",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Box>
            <Typography variant="h5" component="div">
              Avaliações
            </Typography>

            <Typography variant="body1" component="div">
              Totais sintetizados
            </Typography>
          </Box>

          <Typography variant="h3" component="div">
            {qtd_avaliacoes}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
