import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Assessment, Exam, Item, Task } from "services/admin/Interfaces/Types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import {
  IsearchData,
  IStateTeacherCorretion,
} from "store/reducers/UsuarioAplicacaoProva";
import { setSearchData } from "store/reducers/UsuarioAplicacaoProva/Actions";
import {
  IRegisterTeacherItemAssignment,
  TeacherItemAssignment,
} from "store/reducers/UsuarioAplicacaoProva/AsyncActions/types";
import {
  deleteAssessmentsTeacher,
  findAllTeachers,
  findAssessmentsTeacherByParam,
  registerAssessmentToTeacher,
} from "store/reducers/UsuarioAplicacaoProva/AsyncActions/usuarioAplicacaoProva.services";
import {
  getAssessmentById,
  getExamById,
  getExamsFromAssessment,
  getTaskById,
  getTasksFromExam,
} from "../utils/elementGetters";
import CardBase from "./CardBase";

const CardBindItemToTeacher: React.FC = () => {
  const dispatch = useDispatch();
  const [idSelectedTarefa, setIdSelectedTarefa] = useState(0);

  const state: IStateTeacherCorretion = useSelector(
    (state: any) => state.teacherAssessment
  );

  const filters = state.filters;
  const teachers: IRegisterProps[] = state.teachers;
  const binds: TeacherItemAssignment[] = state.teacherItemAssesments;
  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;
  const exams: Exam[] = useSelector((state: any) => state.admin).exams;
  const assessments: Assessment[] = useSelector(
    (state: any) => state.admin
  ).assessments;

  let dataForm: TeacherItemAssignment = state.dataForm;

  useEffect(() => {
    dispatch(findAllTeachers());
  }, [dispatch]);

  const handleFilterChange = (filtersUp: IsearchData) => {
    getBinds(filtersUp);
    dispatch(setSearchData(filtersUp));
  };

  const initiResgister = (idItem: number) => {
    let newBind: IRegisterTeacherItemAssignment = {
      assessment: filters.idAssessment,
      exam: filters.idExam,
      item: idItem,
      teacher: filters.idTeache,
    };
    registerBind(newBind);
  };

  const registerBind = (resource: IRegisterTeacherItemAssignment) => {
    dispatch(registerAssessmentToTeacher(resource));
  };

  const removeBind = (id: number) => {
    dispatch(deleteAssessmentsTeacher(id));
  };

  const getBinds = (filters: IsearchData) => {
    if (filters.idTeache) {
      dispatch(
        findAssessmentsTeacherByParam(
          filters.idTeache,
          filters.idAssessment,
          filters.idExam
        )
      );
    }
  };

  const getExamsFromAsessment = (): Exam[] => {
    let assessment: Assessment | undefined = getAssessmentById(
      assessments,
      filters.idAssessment
    );
    let retorno: Exam[] = [];
    if (assessment) {
      retorno = getExamsFromAssessment(
        assessment as Assessment,
        assessment.exams,
        exams
      );
    }
    return retorno;
  };

  const getTaskFromExam = (): Task[] => {
    let exam: Exam | undefined = getExamById(exams, filters.idExam);
    let retorno: Task[] = [];
    if (exam) {
      retorno = getTasksFromExam(exam, tasks);
    }
    return retorno;
  };

  const getItemsFromTask = () => {
    const task: Task | undefined = getTaskById(tasks, filters.idTask);
    let retorno: Item[] = [];
    if (task) {
      retorno = task.items;
    }
    return retorno;
  };

  const itemJaAlocado = (idItem: number): boolean => {
    let jaAlocado: boolean = false;
    for (const bind of binds) {
      if (idItem === bind.item) {
        jaAlocado = true;
        break;
      }
    }
    return jaAlocado;
  };

  const getTaskItemsAlocados = (vinculacao: TeacherItemAssignment): Task[] => {
    let tasksAlocacao: Task[] = [];
    const exam: Exam | undefined = getExamById(
      exams,
      vinculacao.exam.id as number
    );
    if (exam) {
      tasksAlocacao = getTasksFromExam(exam, tasks);
    }
    return tasksAlocacao;
  };

  const getDetalheVinculacao = (vinculacao: TeacherItemAssignment) => {
    let descricao: string | undefined = undefined;
    const tasks: Task[] = getTaskItemsAlocados(vinculacao);
    for (let task of tasks) {
      let prefix = task.title;
      let items: Item[] | undefined = task.items.filter((i) => {
        return i.id === vinculacao.item;
      });

      if (items !== undefined && items.length > 0) {
        descricao = prefix + " > " + items[0].title;
        break;
      }
    }

    return descricao;
  };

  const atualizarFiltroProfessor = (id: number) => {
    let filtersUp: IsearchData = {
      ...filters,
      idTeache: id,
    };
    handleFilterChange(filtersUp);
  };

  const atualizarFiltroAplicacao = (id: number) => {
    let filtersUp: IsearchData = {
      ...filters,
      idAssessment: id,
      idExam: 0,
      idTask: 0,
    };
    setIdSelectedTarefa(0);
    handleFilterChange(filtersUp);
  };

  const atualizarFiltroExame = (id: number) => {
    let filtersUp: IsearchData = { ...filters, idExam: id, idTask: 0 };
    setIdSelectedTarefa(0);
    handleFilterChange(filtersUp);
  };

  const atualizarFiltroTarefa = (id: number) => {
    let filtersUp: IsearchData = { ...filters, idTask: id };
    handleFilterChange(filtersUp);
  };
  return (
    <CardBase
      title={"Alocação para Correção"}
      actionRegister={undefined}
      actionUpdate={undefined}
      dataForm={dataForm}
      validateMethod={() => {
        return [];
      }}
      showActionButtons={false}
    >
      <Box key="root_content_card_exam" display="flex" flexDirection="column">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel>Professor</InputLabel>
            <Select
              value={filters.idTeache}
              label="Professor"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroProfessor(e.target.value as number);
              }}
              defaultValue={0}
              fullWidth
            >
              <MenuItem key={"list_knowledges_area_0"} value={0}>
                {"Selecione o Docente"}
              </MenuItem>
              {teachers.map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.first_name + " " + i.last_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Avaliação</InputLabel>

            <Select
              value={filters.idAssessment}
              label="Avaliação de Prova"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroAplicacao(e.target.value as number);
              }}
              defaultValue={0}
              fullWidth
            >
              <MenuItem key={"list_knowledges_aplicacao_0"} value={0}>
                {"Selecione a Avaliação da Prova"}
              </MenuItem>
              {assessments.map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Prova</InputLabel>

            <Select
              value={filters.idExam}
              label="Prova"
              sx={{ background: "white" }}
              onChange={(e) => {
                atualizarFiltroExame(e.target.value as number);
              }}
              defaultValue={0}
              fullWidth
            >
              <MenuItem key={"list_knowledges_aplicacao_0"} value={0}>
                {"Selecione a Prova"}
              </MenuItem>
              {getExamsFromAsessment().map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Tarefa</InputLabel>
            <Select
              value={idSelectedTarefa}
              label="Tarefa"
              sx={{ background: "white" }}
              onChange={(e) => {
                setIdSelectedTarefa(e.target.value as number);
              }}
              fullWidth
            >
              <MenuItem key={"list_knowledges_aplicacao_0"} value={0}>
                {"Selecione o Item"}
              </MenuItem>
              {getTaskFromExam().map((i, key) => (
                <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ p: 1, width: "100%" }}
              variant="contained"
              onClick={(e) => atualizarFiltroTarefa(idSelectedTarefa)}
            >
              <Typography>Pesquisar Item </Typography>
            </Button>
          </Grid>
        </Grid>

        <Box pt={3} sx={{ display: "flex", flexDirection: "row" }} gap={"1vw"}>
          <Box flex={1}>
            <Typography
              align="left"
              variant="h6"
              fontSize="600"
              p={1}
              paddingLeft={2}
              sx={{ background: "#F1F3F9" }}
            >
              Itens Presentes na Tarefa
            </Typography>
            <List
              sx={{
                background: "white",
                height: "30vh",
                border: "1px solid #E7E9EE",
              }}
            >
              {getItemsFromTask().map((item, index) =>
                !itemJaAlocado(item.id as number) ? (
                  <div key={"list_task_exam_item_content_" + index}>
                    <ListItem
                      key={"list_task_exam_item_" + index}
                      secondaryAction={
                        <Button
                          variant="outlined"
                          sx={{
                            background: "white",
                            color: "#1D2432",
                            borderColor: "#CACDD5",
                          }}
                          key={"list_task_exam_item_secondary_btn" + index}
                          onClick={(e) => {
                            initiResgister(item.id as number);
                          }}
                        >
                          <Add
                            key={"list_task_exam_item_remove_btn_" + index}
                          />
                        </Button>
                      }
                    >
                      <ListItemText
                        key={"list_task_exam_item_txt" + index}
                        primary={
                          <Typography
                            key={"list_task_exam_item_txt_label_" + index}
                          >
                            {item.title}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider
                      key={"list_task_exam_item_divider" + index}
                      variant="fullWidth"
                      component="li"
                    />
                  </div>
                ) : null
              )}
            </List>
          </Box>

          <Box flex={1}>
            <Typography
              align="left"
              variant="h6"
              fontSize="600"
              p={1}
              paddingLeft={2}
              sx={{ background: "#F1F3F9" }}
            >
              Itens Já Vinculados ao Docente
            </Typography>
            <List
              sx={{
                background: "white",
                height: "30vh",
                border: "1px solid #E7E9EE",
                overflowY: "auto"
              }}
            >
              {binds.map((vinvulacao, index) => (
                <div key={"list_task_exam_item_content_" + index}>
                  <ListItem
                    key={"list_task_exam_item_" + index}
                    secondaryAction={
                      <Button
                        variant="outlined"
                        sx={{
                          background: "white",
                          color: "#1D2432",
                          borderColor: "#CACDD5",
                        }}
                        key={"list_task_exam_item_secondary_btn" + index}
                        onClick={(e) => {
                          removeBind(vinvulacao.id as number);
                        }}
                      >
                        <Remove
                          key={"list_task_exam_item_remove_btn_" + index}
                        />
                      </Button>
                    }
                  >
                    <ListItemText
                      key={"list_task_exam_item_txt" + index}
                      primary={
                        <Typography
                          key={"list_task_exam_item_txt_label_" + index}
                          sx={{ maxHeight: "4rem", overflow: "hidden", textOverflow: "ellipsis" }} // Ajusta o estilo para controlar altura e overflow
                        >
                          {vinvulacao.assessment.description +
                            " > " +
                            getDetalheVinculacao(vinvulacao)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider
                    key={"list_task_exam_item_divider" + index}
                    variant="fullWidth"
                    component="li"
                  />
                </div>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </CardBase>
  );
};

export default CardBindItemToTeacher;
