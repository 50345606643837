import { Pagination } from "interfaces";
import { Ability } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class AbilitiesService {
  public static async createAbility(ability: Ability) {
    const fixedAbility = this.fixNullReferences(ability);
    return api.post("/matrices/abilities/", fixedAbility);
  }

  public static async updateAbility(ability: Ability) {
    const fixedAbility = this.fixNullReferences(ability);
    return api.put(`/matrices/abilities/${ability.id}/`, fixedAbility);
  }

  public static async updateAbilityPatch({ id, ...ability }: Partial<Ability>) {
    if (!id) throw new Error("Invalid id");
    return api.patch(`/matrices/abilities/${id}/`, ability);
  }

  public static async removeAbility(ability: Ability) {
    return api.delete(`/matrices/abilities/${ability.id}`);
  }

  public static async getAbilityById(ability: number) {
    return api.get(`/matrices/abilities/${ability}`);
  }

  public static async fetchAbility(
    limit: number,
    offset: number,
    knowledge: number | undefined
  ) {
    let URL = `/matrices/abilities?limit=${limit}&offset=${offset}`;
    if (knowledge) {
      URL = URL + `&knowledge=${knowledge}`;
    }
    return api.get(URL);
  }

  public static async getAbilityByFilter(params: { knowledge?: number }) {
    if (!params.knowledge) return { data: { results: [] } };
    return api.get<Pagination<any>>("/matrices/abilities", { params });
  }

  public static async getAll() {
    return api.get<Pagination<any[]>>("/matrices/abilities?limit=0");
  }

  private static fixNullReferences(ability: Ability): Ability {
    let abilityFixed: Ability = { ...ability };
    if (ability.parent === 0) abilityFixed.parent = undefined;
    if (ability.knowledge === 0) abilityFixed.knowledge = undefined;
    return abilityFixed;
  }
}
