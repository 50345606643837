import React, { useState } from "react";

import { Modal } from "@mui/material";

import { MarkdownEditor, TableModal, ImageModal, AudioModal,  VideoModal} from "lib_editor";
import "lib_editor/dist/style.css";

import {T1, T2, T3, T4, T5, Dica, Observacao, Audio, Atividade, Video, Explanation, Carrossel, Equacao, CodeHighlighter} from 'lib_editor';

interface IEditorModalWithoutRedux {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    value: string;
    setValue: (value: string) => void;
}

export function EditorModalWithoutRedux({
    isOpen,
    setIsOpen,
    setValue,
    value
}: IEditorModalWithoutRedux) {
  const [editorInputObject, setEditorInputObject] = useState<any>(null);

  function handleSave(markdownCode) {
    setValue(markdownCode);
    setIsOpen(false)
  }

  const sendCommand = (command, before, after) => {
    setEditorInputObject({command, before, after});
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div
        style={{
          position: "absolute",
          top: window.innerHeight * 0.5,
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          width: "80%",
          height: window.innerHeight * 0.8,
        }}
      >
        <MarkdownEditor
          includeToolbar={true}          
          commandInput={editorInputObject}
          onSaveDocument={handleSave}
          onChangeDocument={null}
          documentText={value}
          components={{
            'ex': Explanation,
            'dica': Dica,
            'obs': Observacao,
            'au': Audio,
            'atv': Atividade,
            'video': Video,
            'equacao': Equacao,
            'code': CodeHighlighter,
            'carrossel': Carrossel,
            h1:T1, 
            h2: T2, 
            h3: T3, 
            h4: T4, 
            h5: T5,
          }}
        >
          <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                className="px-4 py-2 text-sm font-medium text-gray-700"
                style={{ color: "#677080", fontSize: 14, fontWeight: 400 }}
              >
                Estrutura
              </div>
              <a
                href="#"
                onClick={() => {
                  setIsModalOpen(true);
                  setModalType("table");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667ZM3.33333 6H12.6667V3.33333H3.33333V6ZM6.88333 9.33333H9.11667V7.33333H6.88333V9.33333ZM6.88333 12.6667H9.11667V10.6667H6.88333V12.6667ZM3.33333 9.33333H5.55V7.33333H3.33333V9.33333ZM10.45 9.33333H12.6667V7.33333H10.45V9.33333ZM3.33333 12.6667H5.55V10.6667H3.33333V12.6667ZM10.45 12.6667H12.6667V10.6667H10.45V12.6667Z"
                    fill="#677080"
                  />
                </svg>
                <span>Tabela</span>
              </a>
              {modalType === "table" && (
                <TableModal
                  isModalOpen={isModalOpen}
                  onCloseModal={() => setIsModalOpen(false)}
                  /* onConfirm={() => setIsModalOpen(false)} */
                  onCommand={(command) => {
                    sendCommand("text", `${command}`, "");
                  }}
                />
              )}

              <a
                href="#"
                onClick={() => {
                  const texto = "- Item1\n- Item2\n- Item3";
                  sendCommand("text", texto, "");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667ZM3.33333 6H12.6667V3.33333H3.33333V6ZM6.88333 9.33333H9.11667V7.33333H6.88333V9.33333ZM6.88333 12.6667H9.11667V10.6667H6.88333V12.6667ZM3.33333 9.33333H5.55V7.33333H3.33333V9.33333ZM10.45 9.33333H12.6667V7.33333H10.45V9.33333ZM3.33333 12.6667H5.55V10.6667H3.33333V12.6667ZM10.45 12.6667H12.6667V10.6667H10.45V12.6667Z"
                    fill="#677080"
                  />
                </svg>
                <span>Lista não numerada</span>
              </a>
              <a
                href="#"
                onClick={() => {
                  const texto = "1. Item1\n2. Item2\n3. Item3";
                  sendCommand("text", texto, "");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M2 15.1668V14.1668H3.66667V13.6668H2.66667V12.6668H3.66667V12.1668H2V11.1668H4C4.18889 11.1668 4.34722 11.2307 4.475 11.3585C4.60278 11.4863 4.66667 11.6446 4.66667 11.8335V12.5002C4.66667 12.6891 4.60278 12.8474 4.475 12.9752C4.34722 13.1029 4.18889 13.1668 4 13.1668C4.18889 13.1668 4.34722 13.2307 4.475 13.3585C4.60278 13.4863 4.66667 13.6446 4.66667 13.8335V14.5002C4.66667 14.6891 4.60278 14.8474 4.475 14.9752C4.34722 15.1029 4.18889 15.1668 4 15.1668H2ZM2 10.5002V8.66683C2 8.47794 2.06389 8.31961 2.19167 8.19183C2.31944 8.06405 2.47778 8.00016 2.66667 8.00016H3.66667V7.50016H2V6.50016H4C4.18889 6.50016 4.34722 6.56405 4.475 6.69183C4.60278 6.81961 4.66667 6.97794 4.66667 7.16683V8.3335C4.66667 8.52239 4.60278 8.68072 4.475 8.8085C4.34722 8.93627 4.18889 9.00016 4 9.00016H3V9.50016H4.66667V10.5002H2ZM3 5.8335V2.8335H2V1.8335H4V5.8335H3ZM6 13.1668V11.8335H14V13.1668H6ZM6 9.16683V7.8335H14V9.16683H6ZM6 5.16683V3.8335H14V5.16683H6Z"
                    fill="#677080"
                  />
                </svg>
                <span>Lista numerada</span>
              </a>
              <hr
                className="border-gray-200"
                style={{ marginTop: "16px", marginBottom: "16px" }}
              />
              <div
                className="px-4 py-2 text-sm font-medium text-gray-700"
                style={{ color: "#677080", fontSize: 14, fontWeight: 400 }}
              >
                Mídia
              </div>
              <a
                href="#"
                onClick={() => {
                  setIsModalOpen(true);
                  setModalType("image");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H9.33333V3.33333H3.33333V12.6667H12.6667V6.66667H14V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM11.3333 6V4.66667H10V3.33333H11.3333V2H12.6667V3.33333H14V4.66667H12.6667V6H11.3333ZM4 11.3333H12L9.5 8L7.5 10.6667L6 8.66667L4 11.3333Z"
                    fill="#677080"
                  />
                </svg>
                <span>Imagem</span>
              </a>
              {modalType === "image" && (
                <ImageModal
                  isModalOpen={isModalOpen}
                  onCloseModal={() => setIsModalOpen(false)}
                  /* onConfirm={() => setIsModalOpen(false)} */
                  onCommand={(command) => {
                    sendCommand("text", `${command}`, "");
                  }}
                />
              )}
              <a
                href="#"
                onClick={() => {
                  setIsModalOpen(true);
                  setModalType("video");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M2.66668 2.6665L4.00001 5.33317H6.00001L4.66668 2.6665H6.00001L7.33334 5.33317H9.33334L8.00001 2.6665H9.33334L10.6667 5.33317H12.6667L11.3333 2.6665H13.3333C13.7 2.6665 14.0139 2.79706 14.275 3.05817C14.5361 3.31928 14.6667 3.63317 14.6667 3.99984V11.9998C14.6667 12.3665 14.5361 12.6804 14.275 12.9415C14.0139 13.2026 13.7 13.3332 13.3333 13.3332H2.66668C2.30001 13.3332 1.98612 13.2026 1.72501 12.9415C1.4639 12.6804 1.33334 12.3665 1.33334 11.9998V3.99984C1.33334 3.63317 1.4639 3.31928 1.72501 3.05817C1.98612 2.79706 2.30001 2.6665 2.66668 2.6665ZM2.66668 6.6665V11.9998H13.3333V6.6665H2.66668Z"
                    fill="#677080"
                  />
                </svg>
                <span>Vídeo</span>
              </a>
              {modalType === "video" && (
                <VideoModal
                  isModalOpen={isModalOpen}
                  onCloseModal={() => setIsModalOpen(false)}
                  onCommand={(command) => {
                    sendCommand("text", `${command}`, "");
                  }}
                />
              )}
              <a
                href="#"
                onClick={() => {
                  setModalType("audio");
                  setIsModalOpen(true);
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M2.66668 2.6665L4.00001 5.33317H6.00001L4.66668 2.6665H6.00001L7.33334 5.33317H9.33334L8.00001 2.6665H9.33334L10.6667 5.33317H12.6667L11.3333 2.6665H13.3333C13.7 2.6665 14.0139 2.79706 14.275 3.05817C14.5361 3.31928 14.6667 3.63317 14.6667 3.99984V11.9998C14.6667 12.3665 14.5361 12.6804 14.275 12.9415C14.0139 13.2026 13.7 13.3332 13.3333 13.3332H2.66668C2.30001 13.3332 1.98612 13.2026 1.72501 12.9415C1.4639 12.6804 1.33334 12.3665 1.33334 11.9998V3.99984C1.33334 3.63317 1.4639 3.31928 1.72501 3.05817C1.98612 2.79706 2.30001 2.6665 2.66668 2.6665ZM2.66668 6.6665V11.9998H13.3333V6.6665H2.66668Z"
                    fill="#677080"
                  />
                </svg>
                <span>Áudio</span>
              </a>
              {modalType === "audio" && (
                <AudioModal
                  isModalOpen={isModalOpen}
                  onCloseModal={() => setIsModalOpen(false)}
                  onCommand={(command) => {
                    sendCommand("text", `${command}`, "");
                  }}
                />
              )}
              <hr
                className="border-gray-200"
                style={{ marginTop: "16px", marginBottom: "16px" }}
              />
              <div
                className="px-4 py-2 text-sm font-medium text-gray-700"
                style={{ color: "#677080", fontSize: 14, fontWeight: 400 }}
              >
                Bloco de Informação
              </div>
              <a
                href="#"
                onClick={() => {
                  sendCommand("text", ":dica{text='texto de dica'}", "");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M2 14V2H3.33333V14H2ZM4.66667 14V12.6667H6V14H4.66667ZM4.66667 8.66667V7.33333H6V8.66667H4.66667ZM4.66667 3.33333V2H6V3.33333H4.66667ZM7.33333 14V12.6667H8.66667V14H7.33333ZM4.66667 11.3333V10H6V11.3333H4.66667ZM4.66667 6V4.66667H6V6H4.66667ZM7.33333 3.33333V2H8.66667V3.33333H7.33333ZM10 14V12.6667H11.3333V14H10ZM10 3.33333V2H11.3333V3.33333H10ZM12.6667 14V12.6667H14V14H12.6667ZM12.6667 11.3333V10H14V11.3333H12.6667ZM12.6667 8.66667V7.33333H14V8.66667H12.6667ZM12.6667 6V4.66667H14V6H12.6667ZM12.6667 3.33333V2H14V3.33333H12.6667Z"
                    fill="#677080"
                  />
                </svg>
                <span>Dica</span>
              </a>
              <a
                href="#"
                onClick={() => {
                  sendCommand("text", ":obs{text='texto de observação'}", "");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M4.66667 3.83333V2.5H6V3.83333H4.66667ZM4.66667 14.5V13.1667H6V14.5H4.66667ZM7.33333 3.83333V2.5H8.66667V3.83333H7.33333ZM7.33333 14.5V13.1667H8.66667V14.5H7.33333ZM10 3.83333V2.5H11.3333V3.83333H10ZM10 14.5V13.1667H11.3333V14.5H10ZM12.6667 3.83333V2.5H14V3.83333H12.6667ZM12.6667 14.5V13.1667H14V14.5H12.6667ZM2 3.83333V2.5H3.33333V3.83333H2ZM12.6667 6.5V5.16667H14V6.5H12.6667ZM2 6.5V5.16667H3.33333V6.5H2ZM12.6667 9.16667V7.83333H14V9.16667H12.6667ZM2 9.16667V7.83333H3.33333V9.16667H2ZM12.6667 11.8333V10.5H14V11.8333H12.6667ZM2 11.8333V10.5H3.33333V11.8333H2ZM2 14.5V13.1667H3.33333V14.5H2ZM8 9.63333C7.66667 9.63333 7.4 9.36667 7.4 9.1L7.13333 6.16667C7.06667 5.63333 7.46667 5.16667 8 5.16667C8.53333 5.16667 8.93333 5.63333 8.86667 6.1L8.6 9.03333C8.6 9.36667 8.33333 9.63333 8 9.63333ZM7.33333 11.1667C7.33333 10.7667 7.6 10.5 8 10.5C8.4 10.5 8.66667 10.7667 8.66667 11.1667C8.66667 11.5667 8.4 11.8333 8 11.8333C7.6 11.8333 7.33333 11.5667 7.33333 11.1667Z"
                    fill="#677080"
                  />
                </svg>
                <span>Observação</span>
              </a>
              <a
                href="#"
                onClick={() => {
                  sendCommand("text", ":ex{text='texto de explicação'}", "");
                }}
                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "start",
                  verticalAlign: "baseline",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{
                    marginRight: "8px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <path
                    d="M7.06668 11.0668L11.7667 6.36683L10.8333 5.4335L7.06668 9.20016L5.16668 7.30016L4.23334 8.2335L7.06668 11.0668ZM8.00001 14.6668C7.07779 14.6668 6.21112 14.4918 5.40001 14.1418C4.5889 13.7918 3.88334 13.3168 3.28334 12.7168C2.68334 12.1168 2.20834 11.4113 1.85834 10.6002C1.50834 9.78905 1.33334 8.92239 1.33334 8.00016C1.33334 7.07794 1.50834 6.21127 1.85834 5.40016C2.20834 4.58905 2.68334 3.8835 3.28334 3.2835C3.88334 2.6835 4.5889 2.2085 5.40001 1.8585C6.21112 1.5085 7.07779 1.3335 8.00001 1.3335C8.92223 1.3335 9.7889 1.5085 10.6 1.8585C11.4111 2.2085 12.1167 2.6835 12.7167 3.2835C13.3167 3.8835 13.7917 4.58905 14.1417 5.40016C14.4917 6.21127 14.6667 7.07794 14.6667 8.00016C14.6667 8.92239 14.4917 9.78905 14.1417 10.6002C13.7917 11.4113 13.3167 12.1168 12.7167 12.7168C12.1167 13.3168 11.4111 13.7918 10.6 14.1418C9.7889 14.4918 8.92223 14.6668 8.00001 14.6668ZM8.00001 13.3335C9.4889 13.3335 10.75 12.8168 11.7833 11.7835C12.8167 10.7502 13.3333 9.48905 13.3333 8.00016C13.3333 6.51127 12.8167 5.25016 11.7833 4.21683C10.75 3.1835 9.4889 2.66683 8.00001 2.66683C6.51112 2.66683 5.25001 3.1835 4.21668 4.21683C3.18334 5.25016 2.66668 6.51127 2.66668 8.00016C2.66668 9.48905 3.18334 10.7502 4.21668 11.7835C5.25001 12.8168 6.51112 13.3335 8.00001 13.3335Z"
                    fill="#677080"
                  />
                </svg>
                <span>Explicação</span>
              </a>
            </div>
          </div>
        </MarkdownEditor>
      </div>
    </Modal>
  );
};
