import api from "services/api";
import { IRegisterTranscriberItemAssignment } from "./types";

export class ServiceBindUserItemTranscriber {
  public static async create(data: IRegisterTranscriberItemAssignment) {
    return api.post("/assessments/transcriberitemassignments/", data);
  }

  public static async deleteBindUserTranscriber(id: number) {
    return api.delete("/assessments/transcriberitemassignments/" + id);
  }

  public static async getUserTranscribers() {
    return api.get("/assessments/transcriberitemassignments/");
  }

  public static async getUserTranscriberByParams(
    idTranscriber: number,
    idAssessment: number,
    idExam: number
  ) {
    return api.get(
      "/assessments/transcriberitemassignments/?transcriber=" +
        idTranscriber +
        (idAssessment ? "&assessment=" + idAssessment : "") +
        (idExam ? "&exam=" + idExam : "")
    );
  }
}
