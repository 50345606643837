import { Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Exam, SelectedElement } from "services/admin/Interfaces/Types";
import { resetClickedElements } from "store/reducers/Admin/Actions/treeView/actions";
import { registerAssessmentExamWithCallBack } from "store/reducers/Admin/AsyncActions/assessmentExam";
import ExamServices from "store/reducers/Admin/AsyncActions/Exam/exam.service";
import { Ids } from "./constants";
import { Header } from "./header";
import { SearchArea } from "./search-area";
import { SelectTask } from "./select-task";
import { Filters, Option } from "./types";
import { useSelects } from "./use-selects";

const CardLoadExam = (props) => {
  const {
    ability,
    knowledge,
    knowledgeArea,
    loadKnowledge,
    loadAbilities,
    loadKnowledgeArea,
  } = useSelects();

  const dispatch = useDispatch();
  const history = useHistory();

  const [filters, setFilter] = React.useState<Filters>({});
  const [examList, setExamList] = React.useState<Option[]>([]);
  const [loadingExams, setLoadingExams] = React.useState(false);
  const [selectedExams, setSelectedExams] = React.useState<number[]>([]);

  const selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  function loadData(value: string, name: string) {
    if (name === Ids.KNOWLEDGE_AREA) {
      loadKnowledge(value);
    }

    if (name === Ids.KNOWLEDGE) {
      loadAbilities(value);
    }
  }

  function loadExams() {
    setLoadingExams(true);
    setExamList([]);
    console.log(filters);

    ExamServices.getAllExamsWithParams({
      ...filters,
      limit: 10000,
    }).then(({ data }) => {
      const exams = data.results.map((exam: Exam) => ({
        id: exam.id!,
        name: exam.title ? exam.title : exam.description,
      }));
      setExamList(exams);
      setLoadingExams(false);
    });
  }

  const actions = {
    onChange(value: string, name: string) {
      setFilter((currentState) => ({ ...currentState, [name]: value }));
      loadData(value, name);
    },
    onFilter() {
      loadExams();
    },
    onReset() {
      setFilter({});
      loadExams();
    },
  };

  const selects = [knowledgeArea, knowledge, ability];

  return (
    <section className="card-load-task">
      <Header headerText="Carregar Exame" />
      <SearchArea
        filters={filters}
        loading={loadingExams}
        selects={selects}
        {...actions}
      />
      <Divider sx={{ mt: "1rem", ml: "2rem", mr: "2rem", mb: "1rem" }} />
      <SelectTask
        tasks={examList}
        onChange={(exams) => setSelectedExams(exams)}
      />

      <Divider sx={{ mt: "1rem", ml: "2rem", mr: "2rem", mb: "1rem" }} />
      <Button
        fullWidth={false}
        type="button"
        variant="contained"
        onClick={() =>
          dispatch(
            registerAssessmentExamWithCallBack(
              selectedExams[0],
              selectedElement.parentElement as number,
              () => history.push("/editor")
            )
          )
        }
        disabled={selectedExams.length < 1}
        sx={{ marginLeft: "35px" }}
      >
        Carregar
      </Button>

      <Button
        fullWidth={false}
        type="button"
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(resetClickedElements());
          history.push("/editor");
        }}
        sx={{ marginLeft: "5px" }}
      >
        <Typography>Cancelar</Typography>
      </Button>
    </section>
  );
};

export default CardLoadExam;
