import { MenuItemProps } from "@mui/material";
import { cloneDeep } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Exam } from "services/admin/Interfaces/Types";
import KnowledegeAreasService from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges-area.services";
import KnowledegesServices from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges.services";
import AbilitiesService from "store/reducers/abilities/AsyncActions/abilities.service";
import { getSelectConfig, Ids } from "./constants";
import { SelectOptionsProps } from "./types";

export function useSelects() {
  const [examSelect, setExamSelect] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.EXAM)
  );

  const [knowledgeArea, setKnowledgeArea] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.KNOWLEDGE_AREA)
  );

  const [knowledge, setKnowledge] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.KNOWLEDGE)
  );

  const [ability, setAbility] = React.useState<SelectOptionsProps>(
    getSelectConfig(Ids.ABILITY)
  );

  const exams: Exam[] = useSelector((state: any) => state.admin).exams;

  React.useEffect(() => {
    loadExam();
  }, [exams.length]);

  React.useEffect(() => {
    loadKnowledgeArea();
  }, []);

  function loadExam() {
    const config = cloneDeep(getSelectConfig(Ids.EXAM));
    config.options = exams.map<MenuItemProps>((exam) => ({
      key: exam.id,
      value: exam.id,
      content: exam.description,
    }));

    setExamSelect(config);
  }

  function loadKnowledgeArea() {
    KnowledegeAreasService.getAllKnowledgeArea().then((data) => {
      const config = cloneDeep(getSelectConfig(Ids.KNOWLEDGE_AREA));
      config.options = data.results.map<MenuItemProps>((knowledgeArea) => ({
        key: knowledgeArea.id,
        value: knowledgeArea.id,
        content: knowledgeArea.name,
      }));

      setKnowledgeArea(config);
      loadKnowledge();
    });
  }

  function loadKnowledge(knowledgeArea?: number | string) {
    if (!knowledgeArea) {
      setKnowledge(getSelectConfig(Ids.KNOWLEDGE));
      setAbility(getSelectConfig(Ids.ABILITY));
      return;
    }

    KnowledegesServices.getKnowledesByFilter({
      knowledge_area: +knowledgeArea,
    }).then(({ data }) => {
      const config = cloneDeep(getSelectConfig(Ids.KNOWLEDGE));
      console.log(data.results);
      config.options = (data.results as any[]).map<MenuItemProps>(
        (knowledge) => ({
          key: knowledge.id,
          value: knowledge.id,
          content: knowledge.name,
        })
      );

      setKnowledge(config);
      setAbility(getSelectConfig(Ids.ABILITY));
    });
  }

  function loadAbilities(knowledge?: number | string) {
    if (!knowledge) {
      setAbility(getSelectConfig(Ids.ABILITY));
      return;
    }

    AbilitiesService.getAbilityByFilter({
      knowledge: +knowledge,
    }).then(({ data }) => {
      const config = cloneDeep(getSelectConfig(Ids.ABILITY));
      config.options = (data.results as any[]).map<MenuItemProps>(
        (ability) => ({
          key: ability.id,
          value: ability.id,
          content: ability.name,
        })
      );

      setAbility(config);
    });
  }

  return {
    examSelect,
    knowledgeArea,
    knowledge,
    ability,
    loadKnowledge,
    loadAbilities,
    loadKnowledgeArea,
  };
}
