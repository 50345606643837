import { ContentFormData } from "services/admin/Interfaces/Types";

export const validateFormData = (form: ContentFormData): string[] => {
  var errors: string[] = [];
  if (!form.title)
    errors.push("Não é possivel cadastrar um conteúdo sem informar um Título.");

  if (!form.text) errors.push("A descrição do conteúdo não foi informado.");
  return errors;
};
