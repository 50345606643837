import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Menu, MenuItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { IItemsOfNavigation } from './navbar';
import { MessageEmpty } from 'components/MessageEmpty';

type TItemDropdownMenu = {
    titleButton: string;
    itemsMenu: Omit<IItemsOfNavigation, 'subMenu' | 'isSelected' | 'isExclusive'>[]
}


export function ItemDropdownMenu({ titleButton, itemsMenu }: TItemDropdownMenu) {
    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openMenuMatrixOfKnowledge = Boolean(anchorEl);

    function handleCloseMenuMatrixOfKnowledge(
        event: React.MouseEvent<HTMLLIElement, MouseEvent>
      ) {
        setAnchorEl(null);
    
        if (!event.currentTarget.id) return;
    
        let url = `${event.currentTarget.id}`;
        history.push(url);
    }

    return (
        <>
            <Button
                className="item_navigation_button"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {titleButton} <ArrowDropDownIcon />
            </Button>           

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenuMatrixOfKnowledge}
                onClose={handleCloseMenuMatrixOfKnowledge}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {
                    itemsMenu.length > 0 ?
                        itemsMenu.map((itemMenu, index) => (
                            <MenuItem
                                key={`${index}-${itemMenu.route}`}
                                id={itemMenu.route}
                                onClick={handleCloseMenuMatrixOfKnowledge}
                            >
                                {itemMenu.name}
                            </MenuItem>
                        ))
                    : <MessageEmpty />
                }
            </Menu>
        </>
    )
}