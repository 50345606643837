import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button, Divider, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { ButtonAccountMenu } from "../ButtonAccountMenu";
import { MenuNavigationApps } from "components/MenuNavigationApps";
import { ItemDropdownMenu } from "./ItemDropdownMenu";

import logo from "../../assets/logos/logo.svg";

import { login, saveUserdata } from "store/reducers/Authentication/Actions";
import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

import "./styles.css";

export interface IItemsOfNavigation {
  name: string;
  route: string;
  isSelected: boolean;
  isExclusive?: boolean;
  subMenu?: Omit<IItemsOfNavigation, 'subMenu' | 'isSelected' | 'isExclusive'>[];
}

interface INavbar {
  titleApp?: string;
  itemsOfNavigation?: IItemsOfNavigation[];
  additionalComponents?: any[];
}

export function Navbar({
  titleApp = "",
  itemsOfNavigation = [],
  additionalComponents,
}: INavbar) {
  const dispath = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useSelector((state: any) => state.authentication);

  const [openSideMenu, setOpenSideMenu] = useState(false)

  const [listItemsOfNavigation, setListItemsOfNavigation] = useState(itemsOfNavigation);

  function handleNavigation(route: string) {
    setListItemsOfNavigation((prevState) =>
      prevState.map((itemPrevState) => {
        const routeEquals = itemPrevState.route === route;
        const routeContains = itemPrevState.subMenu?.length ?
          itemPrevState.subMenu.filter(item => route.startsWith(item.route)).length > 0
          : route.startsWith(itemPrevState.route);

        if (itemPrevState.isExclusive) {
          return routeEquals
            ? { ...itemPrevState, isSelected: true }
            : { ...itemPrevState, isSelected: false };
        } else {
          return routeContains
            ? { ...itemPrevState, isSelected: true }
            : { ...itemPrevState, isSelected: false };
        }
      })
    );
  }

  function generateListMenu({ withDivider = false }: { withDivider?: boolean }) {
    return (
      <ul className="navigations">
        {listItemsOfNavigation.map((itemNavigation) => (
          <li
            key={itemNavigation.name}
            className={`${itemNavigation.isSelected && "selected"}`}
          >
            {
              itemNavigation?.subMenu && itemNavigation?.subMenu.length > 0 ?
              <ItemDropdownMenu
                titleButton={itemNavigation.name}
                itemsMenu={itemNavigation.subMenu}
              /> :
              <Button
                className="item_navigation_button"
                onClick={() => history.push(itemNavigation.route)}
              >
                {itemNavigation.name}
              </Button>
            }

            {withDivider && <Divider />}
          </li>
        ))}
        {additionalComponents
          ? additionalComponents.map((component) => <li>{component} {withDivider && <Divider />}</li>)
          : null}
      </ul>
    )
  }

  useEffect(() => {
    handleNavigation(pathname)
    setOpenSideMenu(false)
  }, [pathname]);

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    let dataUserSession: any = sessionStorage.getItem("@App:U");

    // console.log(appSession);
    // console.log(dataUserSession);

    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispath(login({ ...appSession } as ILoginProps));
    }

    if (dataUserSession) {
      dataUserSession = JSON.parse(window.atob(dataUserSession));
      dispath(saveUserdata(dataUserSession as IRegisterProps));
    }
  }, [
    dispath,
    sessionStorage.getItem("@App:S"),
    sessionStorage.getItem("@App:U"),
  ]);

  if (!user.signed) {
    return <></>;
  }

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="CEnPE" />
        {titleApp && <span>{titleApp}</span>}
      </div>

      <div className="menu_for_bigger_screen">
        {generateListMenu({})}
      </div>

      <div className="user">
        <div className="menu_for_smaller_screen">
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<MenuIcon />}
            style={{ height: 'fit-content' }}
            onClick={() => setOpenSideMenu(true)}
          >
            Navegação
          </Button>

          <Drawer
            open={openSideMenu}
            onClose={() => setOpenSideMenu(false)}
            className="drawer"
          >
            <div className="drawer__header">
              <MenuIcon /> <h4>Menu de Navegação</h4>
            </div>

            {generateListMenu({ withDivider: true })}
          </Drawer>
        </div>
        
        <MenuNavigationApps />

        <ButtonAccountMenu />
      </div>
    </nav>
  );
}

export default Navbar;
