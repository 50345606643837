import { Route, Switch } from "react-router-dom";

import NotFound from "../pages/404";
import Register from "../pages/Register";
import Await from "pages/Public/Pages/Await";
import CheckAuth from "pages/Public/check-out";

const SignRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={CheckAuth} />
      <Route path="/criar-conta" exact component={Register} />
      <Route path="/admin/" component={Await} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default SignRoutes;
