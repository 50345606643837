import { createReducer } from "@reduxjs/toolkit";
import { Ability, Page } from "services/admin/Interfaces/Types";
import { FETCH_ABILITIES, SET_DATA_FORM } from "./Actions";

const emptyPage: Page<Ability> = {
  count: 0,
  previous: "",
  next: "",
  results: [],
};

const emptyDataForm: Ability = {
  code: "",
  name: "",
  parent: 0,
  knowledge: 0,
  description: "",
};

const INITIAL_VALUES = {
  currentPage: emptyPage,
  dataForm: emptyDataForm,
};

export default createReducer(INITIAL_VALUES, {
  [FETCH_ABILITIES.type]: (state, action) => {
    state.currentPage = action.payload;
  },
  [SET_DATA_FORM.type]: (state, action) => {
    state.dataForm = action.payload;
  },
});
