import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
interface IContextualMenuListItem {
  text: string;
  redirectUrl?: string;
  icon?: any;
  action?: any;
  disabled?: boolean;
}

const ContextualMenuListItem = ({
  text,
  redirectUrl,
  action,
  icon,
  disabled = false,
}: IContextualMenuListItem) => {
  const history = useHistory();
  return (
    <MenuItem
      disabled={disabled}
      sx={{ color: "var(--primary-regular)", width: "300px" }}
      onClick={(e) => {
        if (action) action();
        if (redirectUrl) history.push(redirectUrl);
      }}
    >
      <ListItemIcon>{icon ? icon : null}</ListItemIcon>
      {text}
    </MenuItem>
  );
};

export default ContextualMenuListItem;
