import { Pagination } from "interfaces";
import api from "services/api";

export interface FilterOptionsKnowledge {
  knowledge_area?: number;
}

export default class KnowledegesServices {
  public static async getAllKnowledges(size: number, offset: number) {
    return api.get(`/matrices/knowledge_areas?size=${size}&offset=${offset}`);
  }

  public static async getAll(){
    return api.get<Pagination<any[]>>('matrices/knowledges/?limit=0')
  }

  public static async getAllKnowledgesGraph(size: number, offset: number) {
    return api.get(`/matrices/knowledges?size=${size}&offset=${offset}`);
  }

  public static async getKnowledesByFilter({
    knowledge_area,
  }: FilterOptionsKnowledge) {
    if (!knowledge_area) return { data: { results: [] } };
    return api.get<Pagination<any>>(`/matrices/knowledges`, {
      params: { knowledge_area },
    });
  }
}
