import React, { useState } from "react";
import { Check, Newspaper } from "@mui/icons-material";
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import { Option, TaskItemProps } from "./types";
import "./select-content.scss";
import { Loading } from "components/Loading";
import { Button } from "@mui/material";
import ContentServices from "store/reducers/Admin/AsyncActions/content/contents.service";

function TaskItem({ selected, title, id, onClick }: TaskItemProps) {
  return (
    <div
      className={`task-item ${selected && "selected"}`}
      onClick={() => {
        onClick && onClick(id);
      }}
    >
      {selected && <Check id="check-icon" />}
      <Newspaper
        sx={{ fontSize: "64px" }}
        htmlColor={selected ? "#ffffff" : "#365BDC"}
      />
      <span>{title}</span>
    </div>
  );
}

interface SelectContentProps {
  tasks: Option[];
  onChange?: (selecteds: number[]) => void;
  isLoading?: boolean;
  onSave: (object: number) => void;
}

export function SelectContent({ tasks, onChange, isLoading = false, onSave }: SelectContentProps) {
  const [selected, setSelected] = React.useState<number[]>([]);

  function onClick(id: number) {
    let selectedCopy = selected;
    const index = selectedCopy.findIndex((item) => item === id);

    if (index === -1) {
      selectedCopy = [id];
    } else {
      selectedCopy = [];
    }
    const newData = [...selectedCopy];
    setSelected(newData);
    onChange && onChange(newData);
  }

  return (
    <>
      <div className="select-task-list">
        {
          isLoading ?
          <Loading /> :
          <>
            {
              tasks.length > 0 ?
              <>
                {tasks.map(({ name, id }) => (
                  <TaskItem
                    id={id}
                    title={name}
                    selected={selected.includes(id)}
                    onClick={onClick}
                  />
                ))}
              </> :
              <div className="area_empty">
                <ManageSearchIcon className="icon_search"/>
                <h3>Nenhum resultado encontrado</h3>
                <h6>Utilize os filtros para buscar os resultados</h6>
              </div>
            }
          </>
        }
      </div>
      
      {
        tasks.length > 0 &&
        <div className="actions">
          <Button
            fullWidth={false}
            type="button"
            variant="contained"
            disabled={selected.length <= 0 || isLoading}
            onClick={() => onSave(selected[0])}
          >
            {isLoading ? "Carregando..." : "Carregar"}
          </Button>

          <Button
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
        </div>
      }
    </>
  );
}
