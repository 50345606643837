import React from "react";

/**
 * Converte para Hexadecimal
 * @param {*} h matiz
 * @param {*} s saturação
 * @param {*} l brilho
 * @returns
 */
function hslToHex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hueToRgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  const toHex = (c) => {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

/**
 * Renderiza subcomponente do grafo Legenda
 * @param {*} data Dados para a legenda
 * @returns componente Legenda
 */
const Legend = ({ data }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "100px",
        right: "20px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <div style={{ marginBottom: "10px", textAlign: "center" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              width: "20px",
              height: "5px",
              backgroundColor: "#C5C5C5", // Cinza
              marginRight: "5px",
              border: "1px solid transparent",
              borderRadius: "5px",
            }}
          ></div>
          <span>Linhas</span>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              width: "20px",
              height: "5px",
              backgroundColor: "#ef233c", // Vermelho
              marginRight: "5px",
              border: "1px solid transparent",
              borderRadius: "5px",
            }}
          ></div>
          <span>Relação Pai</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "5px",
              backgroundColor: "#4361ee", // Azul
              marginRight: "5px",
              border: "1px solid transparent",
              borderRadius: "5px",
            }}
          ></div>
          <span>Relação Filho</span>
        </div>
      </div>
      {data.map((item) => (
        <div
          key={item.id}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: hslToHex(item.angle, 100, 50),
              marginRight: "5px",
              border: "1px solid transparent",
            }}
          ></div>
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
