import { MessageEmpty } from "components/MessageEmpty";
import { LineGraph } from "./TabelaRespostasEscola/LineGraph";

import { quantityAndPercentage } from "./utils/quantityAndPercentage";

interface ITabelaRespostasGerais {
  items: any[];
}

export function TabelaRespostasGerais({ items }: ITabelaRespostasGerais) {
  return (
    <>
      {items.length > 0 ? (
        <>
          {items.map((item) => {
            const {
              porcentagemInsuficiente,
              porcentagemParcialmenteInsuficiente,
              porcentagemSuficiente,
              porcentagemSemRubrica,
              respostasEstudantesItem,
              porcentagemRespostasEstudantesItem,
              respostasTranscritorItem,
              porcentagemRespostasTranscritorItem,
              respostasOCRItem,
              porcentagemRespostasOCRItem,
              respostasNaoContabilizadasItem,
              porcentagemRespostasNaoContabilizadasItem,
            } = quantityAndPercentage({
              evaluations: item.evaluations,
              item_stats: {
                answers_from_student: item.answers_from_student,
                answers_from_transcriber: item.answers_from_transcriber,
                answers_from_transcriber_ocr: item.answers_from_transcriber_ocr,
                total_answers: item.total_answers,
              },
            });

            return (
              <LineGraph
                titlePrimary={item.task_title}
                titleSecondary={item.item_title[0]}
                answersTotal={item.total_answers}
                itemsLeft={[
                  {
                    legend: `"Insuficiente": ${porcentagemInsuficiente.toFixed(
                      2
                    )}%`,
                    percentageSlice: `${porcentagemInsuficiente}%`,
                    backgroundColor: "#f1530f",
                  },
                  {
                    legend: `"Parcialmente Insuficiente": ${porcentagemParcialmenteInsuficiente.toFixed(
                      2
                    )}%`,
                    percentageSlice: `${porcentagemParcialmenteInsuficiente}%`,
                    backgroundColor: "#f1c40f",
                  },
                  {
                    legend: `"Suficiente": ${porcentagemSuficiente.toFixed(
                      2
                    )}%`,
                    percentageSlice: `${porcentagemSuficiente}%`,
                    backgroundColor: "#00e676",
                  },
                  {
                    legend: `Sem Rubrica: ${porcentagemSemRubrica.toFixed(2)}%`,
                    percentageSlice: `${porcentagemSemRubrica}%`,
                    backgroundColor: "#333",
                  },
                ]}
                itemsRigth={[
                  {
                    legend: `Estudantes: ${respostasEstudantesItem}`,
                    percentageSlice: `${porcentagemRespostasEstudantesItem}%`,
                    backgroundColor: "#47b9ee",
                  },
                  {
                    legend: `Transcritores: ${respostasTranscritorItem}`,
                    percentageSlice: `${porcentagemRespostasTranscritorItem}%`,
                    backgroundColor: "#00a0ea",
                  },
                  {
                    legend: `OCR: ${respostasOCRItem}`,
                    percentageSlice: `${porcentagemRespostasOCRItem}%`,
                    backgroundColor: "#0072b9",
                  },
                  {
                    legend: `Não Contabilizado: ${respostasNaoContabilizadasItem}`,
                    percentageSlice: `${porcentagemRespostasNaoContabilizadasItem}%`,
                    backgroundColor: "#333",
                  },
                ]}
              />
            );
          })}
        </>
      ) : (
        <MessageEmpty />
      )}
    </>
  );
}
