import Tooltip from "@mui/material/Tooltip";

import "./styles.css";

interface ILineGraph {
  titlePrimary: string;
  titleSecondary?: string;
  answersTotal: number;
  itemsLeft: {
    legend: string;
    percentageSlice: string;
    backgroundColor: string;
  }[];
  itemsRigth: {
    legend: string;
    percentageSlice: string;
    backgroundColor: string;
  }[];
}

export function LineGraph({
  itemsLeft,
  itemsRigth,
  titlePrimary,
  answersTotal,
  titleSecondary,
}: ILineGraph) {
  return (
    <div className="container__line_graph">
      <div className="header__line_graph">
        <div className="header__titles__line_graph">
          <span>{titlePrimary}</span>
          {titleSecondary && <span>{titleSecondary}</span>}
        </div>

        <span>
          Respostas totais:{" "}
          <span className="emphasis_text">{answersTotal}</span>
        </span>
      </div>

      <div className="body__line_graph">
        <div className="line_graph">
          {itemsLeft.length > 0 && (
            <>
              {itemsLeft.map((item) => (
                <Tooltip
                  title={<h5>{item.legend}</h5>}
                  placement="bottom"
                  arrow
                >
                  <span
                    style={{
                      height: 16,
                      cursor: "pointer",
                      backgroundColor: item.backgroundColor,
                      width: item.percentageSlice,
                    }}
                  />
                </Tooltip>
              ))}
            </>
          )}
        </div>

        <div className="line_graph">
          {itemsRigth.length > 0 && (
            <>
              {itemsRigth.map((item) => (
                <Tooltip
                  title={<h5>{item.legend}</h5>}
                  placement="bottom"
                  arrow
                >
                  <span
                    style={{
                      height: 16,
                      cursor: "pointer",
                      backgroundColor: item.backgroundColor,
                      width: item.percentageSlice,
                    }}
                  />
                </Tooltip>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
