import { callback } from "chart.js/dist/helpers/helpers.core";
import { AbilityEdge } from "services/admin/Interfaces/Types";
import { FETCH_ABILITIES } from "store/reducers/abilities/Actions";
import { fetchAbilityEdge } from "../actions";
import AbilityEdgeService from "./ability_edge.service";

export const createAbilityEdge = (ab: {
  from_ability: number;
  to_ability: number;
}) => {
  return (dispatch) => {
    AbilityEdgeService.registerAbilitEdge(ab).then((data: any) => {
      dispatch(getAbilityEdge(undefined));
    });
  };
};

export const updateAbilityEdge = (ab: AbilityEdge) => {
  return (dispatch) => {
    AbilityEdgeService.updateAbilitEdge(ab).then((data: any) => {
      getAbilityEdge(undefined);
    });
  };
};

export const deleteAbilityEdge = (
  ab: number,
  params: { limit: number; offset: number },
  callback: any | undefined
) => {
  return (dispatch) => {
    AbilityEdgeService.removeAbilitEdge(ab).then((data: any) => {
      dispatch(getAbilityEdge(params));
      if (callback) callback();
    });
  };
};

export const getAbilityEdge = (
  params: { limit: number; offset: number } | undefined
) => {
  return (dispatch) => {
    AbilityEdgeService.fetchAbilitEdge(params).then((response: any) => {
      dispatch(fetchAbilityEdge(response.data));
    });
  };
};
