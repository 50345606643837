import { createAction } from "@reduxjs/toolkit";
import {
  AbilitySerie,
  Page,
  Serie,
  SimpleAbilitySerie,
} from "services/admin/Interfaces/Types";

export const FETCH_SERIE_ABILITY = createAction<Page<AbilitySerie>>(
  "FETCH_ABILITY_SERIE"
);

export const FETCH_SERIE = createAction<Serie>("FETCH_SERIE");

export const SET_MODAL_SIMPLE_ABILITY_SERIE = createAction<
  SimpleAbilitySerie[]
>("SET_MODAL_SIMPLE_ABILITY_SERIE");
export const RESET_MODAL_SIMPLE_ABILITY_SERIE = createAction(
  "RESET_MODAL_SIMPLE_ABILITY_SERIE"
);
