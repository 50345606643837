import api from "services/api";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import {
  bindUserTranscribers,
  fetchUserTranscriber,
  getUsersTranscribers,
  removeUserTranscribers,
  resetBindsTranscribers,
  resetUsersTranscribers,
} from "../Actions";
import {
  IRegisterTranscriberItemAssignment,
  TranscriberItemAssignment,
} from "./types";
import { ServiceBindUserItemTranscriber } from "./usuarioTranscriber.service";

export const registerTranscriber = (
  resource: IRegisterTranscriberItemAssignment
) => {
  return (dispatch) => {
    ServiceBindUserItemTranscriber.create(resource).then(({ data }: any) => {
      const bind: TranscriberItemAssignment = data;
      dispatch(bindUserTranscribers(bind));
    });
  };
};

export const findTranscriberByParam = (
  idTranscriber: number,
  idAssessment: number,
  idExam: number
) => {
  return (dispatch) => {
    dispatch(resetBindsTranscribers());
    ServiceBindUserItemTranscriber.getUserTranscriberByParams(
      idTranscriber,
      idAssessment,
      idExam
    ).then(({ data }: any) => {
      const binds: TranscriberItemAssignment[] = data.results;
      dispatch(fetchUserTranscriber(binds));
      if (data.next) {
        recursiveBindFetch(data.next, dispatch);
      }
    });
  };
};

export const findTranscribersItem = () => {
  return (dispatch) => {
    dispatch(resetBindsTranscribers());
    ServiceBindUserItemTranscriber.getUserTranscribers().then(
      ({ data }: any) => {
        const binds: TranscriberItemAssignment[] = data.results;
        dispatch(fetchUserTranscriber(binds));
        if (data.next) {
          recursiveBindFetch(data.next, dispatch);
        }
      }
    );
  };
};

function recursiveBindFetch(url: string, dispatch) {
  api.get(url).then(({ data }: any) => {
    dispatch(fetchUserTranscriber(data.results));
    if (data.next) {
      recursiveBindFetch(data.next, dispatch);
    }
  });
}

export const deleteTranscriberUserItem = (id: number) => {
  return (dispatch) => {
    ServiceBindUserItemTranscriber.deleteBindUserTranscriber(id).then(() => {
      dispatch(removeUserTranscribers(id));
    });
  };
};

export const findAllTranscribers = () => {
  return (dispatch) => {
    dispatch(resetUsersTranscribers());
    AuthenticationServices.fetchUsersByRole("transcriber").then(
      ({ data }: any) => {
        const professores: IRegisterProps[] = data.results;
        dispatch(getUsersTranscribers(professores));
        if (data.next) recursiveTranscriberFetch(data.next, dispatch);
      }
    );
  };
};

function recursiveTranscriberFetch(url: string, dispatch) {
  api.get(url).then(({ data }: any) => {
    const transcriber: IRegisterProps[] = data.results;
    dispatch(getUsersTranscribers(transcriber));
    if (data.next) {
      recursiveTranscriberFetch(data.next, dispatch);
    }
  });
}
