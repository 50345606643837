import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Box, Button, Menu, MenuItem } from "@mui/material";

import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";

import SelectionButtonPerfil from "components/Admin/SelectionButtonPerfil";

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { ClosedCaption } from "@mui/icons-material";
import { ProfileMenu } from "components/ProfileMenu";

export enum MenuIds {
  KNOWLEDGE_AREA = "knowledge-area",
  ABILITY = "abilities",
  ABILITY_RELATIONSHIP = "ability-relationship",
  KNOWLEDGE = "knowledge",
}

export default function Header() {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenuMatrixOfKnowledge = Boolean(anchorEl);

  function handleCloseMenuMatrixOfKnowledge(
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) {
    setAnchorEl(null);

    if (!event.currentTarget.id) return;

    let url = `/admin/${event.currentTarget.id}`;
    history.push(url);
  }

  const userData: IRegisterProps = useSelector(
    (state: any) => state.authentication
  ).userData;

  return (
    <Box className="admin-full-screen">
      <Box className="container-lateral" sx={{ p: 1 }}>
        <ProfileMenu
          first_name={userData.first_name}
          last_name={userData.last_name}
        />
      </Box>

      <Box className="container-menu-header">
        <SelectionButtonPerfil perfil={userData.type} />

        <Button
          variant="contained"
          sx={{
            background: "var(--neutral-1)",
            color: "var(--neutral-5)",
            "&:hover": { color: "var(--neutral-1)" },
          }}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={<DashboardIcon fontSize="large" />}
        >
          Matriz de Saberes
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "var(--neutral-1)",
            color: "var(--neutral-5)",
            "&:hover": { color: "var(--neutral-1)" },
          }}
          // onClick={() => window.location.href="/admin/dashboard"}
          onClick={() => {
            history.push("/admin/dashboard");
          }}
          startIcon={<DashboardIcon fontSize="large" />}
        >
          Dashboard
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenuMatrixOfKnowledge}
          onClose={handleCloseMenuMatrixOfKnowledge}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={handleCloseMenuMatrixOfKnowledge}
            id={MenuIds.KNOWLEDGE_AREA}
          >
            Áreas de Conhecimento
          </MenuItem>

          <MenuItem
            onClick={handleCloseMenuMatrixOfKnowledge}
            id={MenuIds.KNOWLEDGE}
          >
            Conhecimentos
          </MenuItem>
          <MenuItem
            onClick={handleCloseMenuMatrixOfKnowledge}
            id={MenuIds.ABILITY}
          >
            Habilidades
          </MenuItem>
          <MenuItem
            id={MenuIds.ABILITY_RELATIONSHIP}
            onClick={handleCloseMenuMatrixOfKnowledge}
          >
            Relações entre Habilidades
          </MenuItem>
        </Menu>

        <Button
          variant="contained"
          color="primary"
          sx={{
            background: "var(--neutral-1)",
            color: "var(--neutral-5)",
            "&:hover": { color: "var(--neutral-1)" },
          }}
          onClick={() => history.push("/admin/bind/teacher/assessment")}
          startIcon={<AssignmentIndOutlinedIcon fontSize="large" />}
        >
          Alocação para Correção
        </Button>

        <Button
          variant="contained"
          sx={{
            background: "var(--neutral-1)",
            color: "var(--neutral-5)",
            "&:hover": { color: "var(--neutral-1)" },
          }}
          onClick={() => history.push("/admin/transcribers")}
          startIcon={<ClosedCaption fontSize="large" />}
        >
          Alocação para Validação
        </Button>
      </Box>
    </Box>
  );
}
